import {
GET_CUSTOMERS,
GET_CUSTOMERS_SUCCESS,
GET_CUSTOMERS_FAIL,
GET_CUSTOMER_DETAIL,
GET_CUSTOMER_DETAIL_SUCCESS,
GET_CUSTOMER_DETAIL_FAIL,
GET_CUSTOMER_OFFICES,
GET_CUSTOMER_OFFICES_SUCCESS,
GET_CUSTOMER_OFFICES_FAIL
} from "./actionTypes"

export const getCustomers = () => ({
  type: GET_CUSTOMERS,
})

export const getCustomersSuccess = customers => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: customers,
})

export const getCustomersFail = error => ({
  type: GET_CUSTOMERS_FAIL,
  payload: error,
})

export const getCustomerOffices = id => ({
  type: GET_CUSTOMER_OFFICES,
  id,
})

export const getCustomerOfficesSuccess = offices => ({
  type: GET_CUSTOMER_OFFICES_SUCCESS,
  payload: offices,
})

export const getCustomerOfficesFail = error => ({
  type: GET_CUSTOMER_OFFICES_FAIL,
  payload: error,
})


export const getCustomerDetail = id => ({
  type: GET_CUSTOMER_DETAIL,
  id,
})

export const getCustomerDetailSuccess = customerdetail => ({
  type: GET_CUSTOMER_DETAIL_SUCCESS,
  payload: customerdetail,
})

export const getCustomerDetailFail = error => ({
  type: GET_CUSTOMER_DETAIL_FAIL,
  payload: error,
})
