import React from 'react'
import { Spinner } from "reactstrap"

export const LoadingBorderSpinner=()=> {

    return (
        <div className="mt-4 d-flex justify-content-center " > 
        <Spinner className="ms-2" color="dark" />
        </div>
    )
}


export const LoadingBorderSpinner3DotBlue=()=> {

    return (
        <div className="mt-4 d-flex justify-content-center " > 
        <Spinner type="grow" className="ms-2 mr-4" color="primary" />
        <Spinner type="grow" className="ms-2 mr-4" color="primary" />
        <Spinner type="grow" className="ms-2 mr-4 " color="primary" />
        </div>
    )
}


export const LoadingBorderSpinnerDotBlue_Big=()=> {

    return (
        <div className="mt-4 d-flex justify-content-center " > 
        <Spinner type="grow" className="ms-2 mr-4" color="danger"  size="lg" />
        <Spinner type="grow" className="ms-2 mr-4" color="danger"  size="lg" />
        <Spinner type="grow" className="ms-2 mr-4" color="danger"  size="lg" />
        </div>
    )
}
  



