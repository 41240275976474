import React from "react"
import { connect } from "react-redux"
import { Modal, Table } from "reactstrap"
import {dateFormatDDMMMYYYY} from "../../../helpers/commonhelpers/dateFormat"

export const FiscalYearsModal = props => {
  const { isOpen, toggle, setModal, fiscalYears } = props


  return (
    <>
      <div>
        <Modal size="md" isOpen={isOpen} toggle={toggle}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Fiscal Years
            </h5>
            <button
              onClick={() => {
                toggle(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Table>
              <tr>
                <th>Year #</th>
                <th>From</th>
                <th>To</th>
              </tr>

              {fiscalYears?.map((year, index) => (
                <tr>
                  <td> {index+1}</td>
                  <td>{dateFormatDDMMMYYYY(year.From)}</td>
                  <td>{dateFormatDDMMMYYYY(year.To)}</td>
                </tr>
              ))}
            </Table>
           
          </div>
        </Modal>
      </div>
    </>
  )
}
