const AllowedUsers = [
  "justin.balano@amadeus.qa",
  "bless.largosa@amadeus.qa",
  "ameena.chua@amadeus.qa",
  "marilou.diaz@amadeus.qa",
  "robin.cruz@amadeus.qa",
]
export const AllowedUsersAddTA = [
  "justin.balano@amadeus.qa",
  "bless.largosa@amadeus.qa",
  "ameena.chua@amadeus.qa",
]
export default AllowedUsers
