import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Label,
  Spinner,
  Modal,
  ModalBody,
  Button,
  Form,
} from "reactstrap"

import CarouselPage from "./CarouselPage"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

//import "react-codes-input/lib/react-codes-input.min.css"
// actions
import {
  loginUser,
  apiError,
  socialLogin,
  sessionTimeouFail,
  otp,
  sessionTimeout,
} from "store/actions"

import { useDispatch } from "react-redux"
// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

//Import config
import { facebook, google } from "../../config"

//Import Amadeus PaymentLink Logo
import Logo from "../../assets/images/images/logo.png"
import LogoSM from "../../assets/images/images/logosm.png"
import By from "../../assets/images/images/BY.png"
import AmadeusLogo from "../../assets/images/images/amadeuslogo.png"
import ReactCodeInput from "react-code-input"
const Login = props => {
  const propsInput = {
    inputStyle: {
      fontFamily: "monospace",
      MozAppearance: "textfield",
      borderRadius: "6px",
      border: "1px solid",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,.10)",
      margin: "4px",
      paddingLeft: "8px",
      width: "50px",
      height: "42px",
      fontSize: "32px",
      boxSizing: "border-box",
      color: "black",
      backgroundColor: "white",
      borderColor: "lightgrey",
    },
    inputStyleInvalid: {
      fontFamily: "monospace",
      margin: "4px",
      MozAppearance: "textfield",
      width: "50px",
      borderRadius: "6px",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,.10)",
      fontSize: "32px",
      height: "42px",
      paddingLeft: "8px",
      backgroundColor: "white",
      boxSizing: "border-box",
      color: "black",
      border: "1px solid red",
    },
  }
  const dispatch = useDispatch()
  const [userLoginLoading, setUserLoginLoading] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [modal_large, setModal_Large] = useState(false)
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [otpInput, setOTPInput] = useState("")
  const [isPinCodeValid, setIsPinCodeValid] = useState(true)
  const $pinWrapperRef = useRef(null)
  const [userValues, setUserValues] = useState("")
  const [error, setError] = useState(undefined)
  let form = ""
  useEffect(() => {
    handleSessionTimeout()
  }, [])

  useEffect(() => {}, [step])
  useEffect(() => {}, [otpInput])
  useEffect(() => {}, [userValues])
  useEffect(() => {
    setUserLoginLoading(props.loading)
  }, [props.loading])

  // useEffect(() => {
  //   console.log(error)
  //   if (
  //     error !== undefined &&
  //     props.error !== "Username or password is incorrect"
  //   ) {
  //     setModal_Large(true)
  //   }
  // }, [error])

  const handleValidSubmit = (event, values) => {
    //console.log(values)
    if (values.email.includes("amadeus.qa")) {
      props.loginUser(values, props.history)
    } else {
      setModal_Large(true)
      setUserValues(values)
    }
  }

  const handleSumbit = () => {
    props.loginUser(userValues, props.history)
    setStep(1)
  }
  const signIn = (res, type) => {
    const { socialLogin } = props
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      socialLogin(postData, props.history, type)
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      socialLogin(postData, props.history, type)
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  const handleSessionTimeout = () => {
    const timeOutValue = JSON.parse(localStorage.getItem("sessionTimeout"))
    if (timeOutValue === true) {
      setconfirm_alert(true)
    }
  }

  const handleOTPChange = otpInputValid => {
    const isPinCodeValid = otpInputValid.length === 6
    if (otpInputValid !== undefined && otpInputValid.length === 6) {
      setIsPinCodeValid(isPinCodeValid)
      setOTPInput(otpInputValid)
      if (!isPinCodeValid) setOTPInput("")
    }
  }

  const handleEnterOTP = () => {
    if (otpInput !== "") {
      dispatch(otp(otpInput, props.history))
      setOTPInput("")
    }
  }

  switch (step) {
    case 0:
      form = (
        <Form>
          <Row>
            <Col lg={12}>
              <Row>
                <Col>
                  <Label>Click to send OTP.</Label>
                </Col>
              </Row>
              <Row>
                <Col className="item-center">
                  <Button
                    color="primary"
                    type="submit"
                    className=" waves-effect waves-light mt-3 btn-lg"
                    onClick={() => handleSumbit()}
                    onEnter
                  >
                    Send OTP
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )
      break
    case 1:
      form = (
        <Form onSubmit={handleEnterOTP()}>
          <Row>
            <Col lg={12}>
              <Row>
                <Col className="item-center">
                  <Label>
                    Please enter the OTP.
                    <div className="color-danger">
                      Do not send OTP to other people.
                    </div>
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col className="item-center">
                  {/* <input
                          id="otpInput"
                          name="otpInput"
                          value={otpInput}
                          type="number"
                          //ref={register({ required: true })}
                          className="form-control form-control-lg input-text-size"
                          onChange={event => setOTPInput(event.target.value)}
                        /> */}
                  <ReactCodeInput
                    initialFocus={true}
                    wrapperRef={$pinWrapperRef}
                    id="otpInput"
                    name="otpInput"
                    isValid={isPinCodeValid}
                    value={otpInput}
                    type="text"
                    fields={6}
                    {...propsInput}
                    onChange={handleOTPChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="item-center">
                  {userLoginLoading ? (
                    <Row className="spinner-custom mx-auto my-auto">
                      <Spinner type="border" className="mr-1" color="primary" />
                    </Row>
                  ) : (
                    <Button
                      color="primary"
                      type="submit"
                      className=" waves-effect waves-light mt-3 btn-lg"
                      //onClick={() => handleEnterOTP()}
                      onEnter
                    >
                      Enter OTP
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )

      break
    default:
      break
  }

  return (
    <React.Fragment>
      <Modal size="md" isOpen={modal_large} centered={true}>
        <div className="modal-header">
          <Label className="my-auto">OTP Authentication</Label>
          <h5
            className="modal-title mt-0 color-danger"
            id="myLargeModalLabel"
          ></h5>
          <button
            onClick={() => {
              setModal_Large(false)
            }}
            type="button"
            color="danger"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="fas fa-window-close " style={{ color: "red" }} />
          </button>
        </div>
        <ModalBody>
          {loading ? (
            <Row className="spinner-custom mx-auto my-auto ">
              <LoadingBorderSpinner />
            </Row>
          ) : (
            <React.Fragment>{form}</React.Fragment>
          )}
        </ModalBody>
      </Modal>
      {confirm_alert ? (
        <SweetAlert
          warning
          confirmBtnText="Close"
          confirmBtnBsStyle="danger"
          title="Sorry, your session has expired."
          onConfirm={() => {
            setconfirm_alert(false)
            dispatch(sessionTimeout(false))
          }}
          closeOnClickOutside={() => {
            setconfirm_alert(false)
            dispatch(sessionTimeout(false))
          }}
        >
          Please login again to continue.
        </SweetAlert>
      ) : null}

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-color-secondary">
                  <Row>
                    <Col lg={12}>
                      <div className="color-primary p-4">
                        <h5 className="color-primary">Welcome</h5>
                        <p>Sign in to continue to MorningStars DEV.</p>
                      </div>
                    </Col>
                    {/* <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col> */}
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2 my-auto">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={handleValidSubmit}
                    >
                      {props.error ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email "
                          type="text"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customControlInline"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3">
                        {userLoginLoading ? (
                          <Row className="spinner-custom mx-auto my-auto">
                            <Spinner
                              type="border"
                              className="mr-1"
                              color="primary"
                            />
                          </Row>
                        ) : (
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button>
                        )}
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/password/forgot" className="text-muted">
                          <i className="mdi mdi-lock mr-1" /> Forgot your
                          password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 mt-md-5 text-center">
                <p className="mb-0">
                  © {new Date().getFullYear()} MorningStars by Amadeus Qatar
                  W.L.L.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  const { loading } = state.Login
  const { sessionTimeout } = state.Login
  const { login } = state.Login
  return {
    error,
    loading,
    sessionTimeout,
    login,
  }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
