import React, { useState, useEffect } from 'react'
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Table,
} from "reactstrap"
import { map, isEmpty } from 'lodash'
import { dateFormat } from '../../helpers/commonhelpers/dateFormat'
// import TicketedSegments from './MonthlyDetail/TicketedSegments2'
// import UnpaidAirlines from './MonthlyDetail/UnpaidAirlines'
// import AtcRefund from './MonthlyDetail/AtcRefund'
// import AtcReissue from './MonthlyDetail/AtcReissue'
// import Productivity from './MonthlyDetail/Segments'
import { productCodes } from '../../helpers/commonhelpers/productCodes'
import Products from './MonthlyDetail/Products'
import Segments from './MonthlyDetail/Segments'


const Detailed = (props) => {

  const { 
    custConfig,
    ticketed,
    unpaidAir,
    showData,
    uidUsage,
    productDetails,
    isCustomer } = props

  const [ticketedSegments, setticketedSegments] = useState(ticketed)
  const [unpaidAirlines, setUnpaidAirlines] = useState(unpaidAir)
  const [show, setShow] = useState(showData)
  const [uidGraphicUsage, setUidGraphicUsage] = useState(uidUsage)
  const [products, setProducts] = useState(productDetails)

console.log(productDetails)

  useEffect(() => {
    setticketedSegments(ticketed)
  }, [ticketed])

  useEffect(() => {
    setUnpaidAirlines(unpaidAir)
  }, [unpaidAir])

  useEffect(() => {

    setProducts(productDetails)


  }, [productDetails])

  useEffect(() => {
    setUidGraphicUsage(uidUsage)
  }, [uidUsage])

  useEffect(() => {
    setShow(showData)
  }, [showData])



  return (
    <React.Fragment>


      {ticketedSegments &&
        <>
          {show === "ticketedSegments" &&
            <Segments custConfig ={custConfig} isCustomer={isCustomer} products={ticketedSegments} />
          }
        </>
      }


      {unpaidAir &&
        <>
          {show === "unpaidAirlines" &&
            <Segments custConfig={custConfig} isCustomer={isCustomer} products={unpaidAir} />
          }
        </>
      }


      {uidGraphicUsage &&
        <>
          {show === "UidUsage" &&
            <Segments custConfig={custConfig} isCustomer={isCustomer} products={uidGraphicUsage} />
            // <UidUsage uidGraphicUsage={uidGraphicUsage} />
          }
        </>
      }


      {products &&
        <>
          
         <Products custConfig={custConfig} isCustomer={isCustomer} products={products} />

        </>
      }


    </React.Fragment >


  )
}

export default Detailed
