import { parse } from "dotenv"
import React from "react"
import { connect } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap"

export const IncentiveSlab = props => {
  const {
    agreementIndex,
    index,
    incentive,
    handleIncentiveChange,
    handleIncentiveDelete,
    handleSubmit,
    onSubmit,
    register,
    errors,
  } = props

  const inputMinimumBooking = "MinimumBooking" + index + agreementIndex
  const inputMaximumBooking = "MaximumBooking" + index + agreementIndex
  const inputAmountRate = "AmountRate" + index + agreementIndex
  const inputCurrency = "Currency" + index + agreementIndex

  function handleChange(changes) {
    handleIncentiveChange(incentive.id, { ...incentive, ...changes })
  }

  return (
    <React.Fragment>
      <div>
        <Row>
          <Col lg="6"></Col>
        </Row>
        <Row>
          <Col lg="3">
            <FormGroup>
              <input
                value={incentive.MinimumBooking}
                onChange={e => handleChange({ MinimumBooking: e.target.value })}
                type="number"
                min="0"
                step="1000"
                name={inputMinimumBooking}
                className="form-control"
                placeholder="Minimum Booking"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />
              <small className="color-danger">
                {errors[inputMinimumBooking] &&
                  errors[inputMinimumBooking].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="3">
            <FormGroup>
              <input
                value={incentive.MaximumBooking}
                onChange={e => handleChange({ MaximumBooking: e.target.value })}
                type="number"
                min="0"
                step="1000"
                name={inputMaximumBooking}
                className="form-control"
                placeholder="Maximum Booking"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />
              <small className="color-danger">
                {errors[inputMaximumBooking] &&
                  errors[inputMaximumBooking].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="2">
            <FormGroup>
              <input
                value={incentive.AmountRate}
                onChange={e =>
                  handleChange({ AmountRate: parseFloat(e.target.value) })
                }
                type="number"
                min="0"
                step="0.5"
                name={inputAmountRate}
                className="form-control"
                placeholder="Rate"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />

              <small className="color-danger">
                {errors[inputAmountRate] && errors[inputAmountRate].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="2">
            <FormGroup>
              <select
                className="custom-select"
                name={inputCurrency}
                value={incentive.Currency}
                onChange={e => handleChange({ Currency: e.target.value })}
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              >
                <option value="QAR">QAR</option>
              </select>

              <small className="color-danger">
                {errors[inputCurrency] && errors[inputCurrency].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="2">
            <FormGroup>
              <Button
                onClick={e => handleIncentiveDelete(incentive.id)}
                color="danger"
                className="btn-block inner"
                style={{ width: "100%" }}
              >
                {" "}
                Delete Incentive Slab{" "}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(IncentiveSlab)
