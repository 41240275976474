import React from "react"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button,
  UncontrolledDropdown,
} from "reactstrap"
import dateFormat from '../../helpers/commonhelpers/dateFormat'




const CustomerConfigurationColumn = () => {


  return (
    [

      {
        dataField: "customerId",
        text: "Customer Id",
        searchable: true,
        sort: true,
        
      },

      {
        dataField: "businessName",
        text: "Business Name",
        searchable: true,
        sort: true,
        formatter: (cellContent, row, rowIndex) => {
         
            return cellContent?.toUpperCase()
         
        }
        
      },

      {
        dataField: "segment",
        text: "Segment",
        searchable: false,
        sort: true,
        
      },

      {
        text: "Category",
        dataField: "category",
        sort: true,
        searchable: true,
     
      },
      {
        text: "Key Account Manager",
        dataField: "keyAccountManager",
        sort: true,
        
      },



    ]
  )
}





export default CustomerConfigurationColumn
