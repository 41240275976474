import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from 'react-redux'
import PropTypes from "prop-types"
import {
    getCustomerConfigurationDetail,
    getIncentiveDueCalCulationSummary,
    getTicketedSegments,
    getUnpaidAirlines,
    getUidGraphicUsage,
    getProducts
} from 'store/actions'
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    // Offcanvas,
    // OffcanvasHeader,
    // OffcanvasBody
} from "reactstrap"

// import {Offcanvas, OffcanvasHeader, OffcanvasBody} from 'react-bootstrap'


import { map, isEmpty } from 'lodash'
import Detailed from './Detailed'
import Summary from './Summary'
import Filters from './Filters';
import { productCodes } from '../../helpers/commonhelpers/productCodes'
import { LoadingBorderSpinner, LoadingBorderSpinner3DotBlue, LoadingBorderSpinnerDotBlue_Big } from '../../helpers/commonhelpers/loadingSpinner'
import AuthUser from "components/AuthUser/AuthUser"
//import UnpaidAirlines from './MonthlyDetail/UnpaidAirlines';

const CustomerConfigurationDetail = (props) => {

    const {
        customerConfigurationDetail,
        dueIncentiveCalculation,
        ticketedSegments,
        unpaidAirlines,
        uidGraphicUsage,
        products,
        onGetCustomerConfigurationDetail,
        onGetIncentiveDueCalculation,
        onGetTicketedSegments,
        onGetUnpaidAirlines,
        onGetUidUsage,
        onGetProducts,
        match: { params },
        loading,
        loadingSummary,
        loadingDetailed,
        error
    } = props


    console.log(dueIncentiveCalculation)

    const [custConfig, setCustConfig] = useState()
    const [coveredPeriod, setCoveredPeriod] = useState()
    const [agreementId, setAgreementId] = useState()
    const [showData, setShowData] = useState("")
    const [incentiveDue, setIncentiveDue] = useState()
    const [ticketed, setTicketed] = useState()
    const [unpaidAir, setUnpaidAir] = useState()
    const [user, setUser] = useState(AuthUser())
    const [userRoleId, setUserRoleId] = useState(1)
    //PRODUCTS
    const [uidUsage, setUidUsage] = useState()
    const [productDetails, setProductDetails] = useState()
    const [loadPage, setLoadPage] = useState(loading)
    const [isCustomer, setIsCustomer] = useState(false)
    const [isBottom, setIsBottom] = useState(false)
    const [loadSummaryCalculation, setLoadSummaryCalculation] = useState(undefined)
    const [loadDetailedCalculation, setLoadDetailedCalculation] = useState(undefined)

    useEffect(() => {
        // setUserRoleId(user.roleID)
    }, [user])

    useEffect(() => {
        // setUserRoleId(user.roleID)
    }, [isCustomer])

    useEffect(() => {
        setLoadPage(loading)
    }, [loading])

    useEffect(() => {
        setLoadSummaryCalculation(loadingSummary)
    }, [loadingSummary])

    useEffect(() => {
        setLoadDetailedCalculation(loadingDetailed)
    }, [loadingDetailed])

    useEffect(() => {
        if (params && typeof params.id === "string") {
            onGetCustomerConfigurationDetail(params.id)
        }

    }, [params, onGetCustomerConfigurationDetail])


    useEffect(() => {
        if (customerConfigurationDetail) {
            setCustConfig(customerConfigurationDetail)

        }
    }, [customerConfigurationDetail])



    useEffect(() => {
        if (dueIncentiveCalculation) {
            setIncentiveDue(dueIncentiveCalculation)
            setLoadSummaryCalculation(false)
        }
    }, [dueIncentiveCalculation])


    useEffect(() => {
        if (ticketedSegments) {
            setTicketed(ticketedSegments)
            setLoadDetailedCalculation(false)
        }
    }, [ticketedSegments])

    useEffect(() => {
        if (unpaidAirlines) {
            setUnpaidAir(unpaidAirlines)
            setLoadDetailedCalculation(false)
        }
    }, [unpaidAirlines])

    useEffect(() => {

    }, [showData])

    //PRODUCTS
    useEffect(() => {
        if (uidGraphicUsage) {
            setUidUsage(uidGraphicUsage)
            setLoadDetailedCalculation(false)
        }
    }, [uidGraphicUsage])

    useEffect(() => {
        if (products) {

            setProductDetails(products)
            setLoadDetailedCalculation(false)
        }
    }, [products])



    useEffect(() => {

    }, [coveredPeriod])



    const handleViewAsCustomer = (isCustomer) => {

        setIsCustomer(isCustomer)
    }

    // const handleShowIncetiveDueCalculation = (coveredPeriod, agreementId, fiscalYear) => {

    //     setLoadSummaryCalculation(true)

    //     if (!isEmpty(coveredPeriod) && coveredPeriod != null) {
    //         const dateFrom = Date.parse(coveredPeriod[0])
    //         const dateFromNextDay = dateFrom + 86400000
    //         coveredPeriod[0] = new Date(dateFromNextDay)

    //         //if two dates
    //         if (coveredPeriod[1]) {

    //             const dateTo = Date.parse(coveredPeriod[1])
    //             const dateToNextDay = dateTo + 86400000
    //             coveredPeriod[1] = new Date(dateToNextDay)
    //         }

    //     } else {
    //         var agreement = custConfig.agreements.filter(x => x.agreementId === agreementId)[0]
    //         var fiscalYearSelected = agreement?.fiscalYearsDates[fiscalYear]
    //         var dates = new Array();

    //         if (agreement != null && fiscalYearSelected != null) {

    //             dates.push(fiscalYearSelected.from)
    //             dates.push(fiscalYearSelected.to)

    //             coveredPeriod = dates
    //         }
    //     }


    //     setCoveredPeriod(coveredPeriod)
    //     setAgreementId(agreementId)

    //     var productCode = ""
    //     var customerId = params.id
    //     var parameters = {
    //         customerId,
    //         coveredPeriod,
    //         agreementId,
    //         productCode
    //     }
    //     setUidUsage(null)
    //     setTicketed(null)
    //     setProductDetails(null)
    //     onGetIncentiveDueCalculation(parameters)
    // }


    const handleShowIncetiveDueCalculation = (coveredPeriod, agreementId, fiscalYear) => {

        setLoadSummaryCalculation(true)

        setCoveredPeriod(coveredPeriod)
        setAgreementId(agreementId)

        var productCode = ""
        var customerId = params.id
        var parameters = {
            customerId,
            coveredPeriod,
            agreementId,
            productCode
        }
        setUidUsage(null)
        setTicketed(null)
        setProductDetails(null)
        onGetIncentiveDueCalculation(parameters)
    }

    const handleShowTicketedSegments = () => {

        setLoadDetailedCalculation(true)

        var customerId = params.id
        var parameters = {
            customerId,
            coveredPeriod,
            agreementId
        }
        onGetTicketedSegments(parameters)
        setShowData("ticketedSegments")
    }
    const handleShowUnpaidAirlines = () => {

        setLoadDetailedCalculation(true)

        var customerId = params.id
        var parameters = {
            customerId,
            coveredPeriod,
            agreementId
        }
        onGetUnpaidAirlines(parameters)
        setShowData("unpaidAirlines")
    }

    const handleShowUidUsage = () => {

        setLoadDetailedCalculation(true)
        var customerId = params.id
        var productCode = "SELCO"
        var parameters = {
            customerId,
            coveredPeriod,
            agreementId,
            productCode
        }
        onGetUidUsage(parameters)
        setShowData("UidUsage")
    }

    const handleProductsDetailedView = (productCode) => {

        setLoadDetailedCalculation(true)

        var customerId = params.id
        var parameters = {
            customerId,
            coveredPeriod,
            agreementId,
            productCode
        }
        onGetProducts(parameters)
        setShowData(productCode)

        console.log(productCode)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Customer | Incentive Calculation</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Incentive Calculation" breadcrumbItem={custConfig?.businessName} />

                    {loadPage ?

                        <LoadingBorderSpinner />

                        :

                        <>
                            <Row>
                                <Col lg={12}>
                                    <Filters custConfig={custConfig}
                                        handleShowIncetiveDueCalculation={handleShowIncetiveDueCalculation}
                                        handleViewAsCustomer={handleViewAsCustomer}
                                        isCustomer={isCustomer}

                                    />
                                </Col>
                            </Row>

                            {loadSummaryCalculation ?
                                <LoadingBorderSpinner3DotBlue />
                                :
                                <>

                                    {!isEmpty(dueIncentiveCalculation) &&
                                        <Row>
                                            <Col lg={3}>


                                                <Summary custConfig={custConfig}
                                                    userRoleId={userRoleId}
                                                    isCustomer={isCustomer}
                                                    incentiveDue={incentiveDue}
                                                    coveredPeriod={coveredPeriod}
                                                    handleShowTicketedSegments={handleShowTicketedSegments}
                                                    handleShowUnpaidAirlines={handleShowUnpaidAirlines}
                                                    handleShowUidUsage={handleShowUidUsage}
                                                    handleProductsDetailedView={handleProductsDetailedView}

                                                />

                                            </Col>


                                            {loadDetailedCalculation ?
                                                <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "200px", width: "300px" }}>

                                                    <LoadingBorderSpinner3DotBlue />
                                                </div>

                                                :
                                                <Col lg={9}>
                                                   

                                                        <Detailed 
                                                            custConfig={custConfig}
                                                            ticketed={ticketed}
                                                            unpaidAir={unpaidAir}
                                                            isCustomer={isCustomer}
                                                            showData={showData}
                                                            uidUsage={uidUsage}
                                                            productDetails={productDetails} />

                                             
                                                </Col>
                                            }
                                        </Row>
                                    }
                                </>

                            }

                        </>
                    }

                </Container>
            </div>
        </React.Fragment>
    )
}

CustomerConfigurationDetail.propTypes = {
    onGetCustomerConfigurationDetail: PropTypes.func,
    onGetIncentiveDueCalculation: PropTypes.func,
    onGetUidUsage: PropTypes.func,
    onGetTicketedSegments: PropTypes.func,
    onGetUnpaidAirlines: PropTypes.func,
    customerConfigurationDetail: PropTypes.object,
    loading: PropTypes.bool,
    dueIncentiveCalculation: PropTypes.object,
    onGetUidUsage: PropTypes.func,
    onGetProducts: PropTypes.func,
}

const mapStateToProps = state => (

    {
        customerConfigurationDetail: state.customerconfigurations.customerConfigurationDetail,
        dueIncentiveCalculation: state.incentiveDueCalculation.dueIncentiveCalculation,
        ticketedSegments: state.incentiveDueCalculation.ticketedSegments,
        unpaidAirlines: state.incentiveDueCalculation.unpaidAirlines,
        uidGraphicUsage: state.incentiveDueCalculation.uidGraphicUsage,
        products: state.incentiveDueCalculation.products,
        loading: state.customerconfigurations.loading,
        error: state.customerconfigurations.error,
        loadingSummary: state.incentiveDueCalculation.loadingSummary,
        loadingDetailed: state.incentiveDueCalculation.loadingDetailed,
    })

const mapDispatchToProps = dispatch => (
    {
        onGetCustomerConfigurationDetail: (id) => dispatch(getCustomerConfigurationDetail(id)),
        onGetIncentiveDueCalculation: (data) => dispatch(getIncentiveDueCalCulationSummary(data)),
        onGetTicketedSegments: (data) => dispatch(getTicketedSegments(data)),
        onGetUnpaidAirlines: (data) => dispatch(getUnpaidAirlines(data)),
        onGetUidUsage: (data) => dispatch(getUidGraphicUsage(data)),
        onGetProducts: (data) => dispatch(getProducts(data))
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(CustomerConfigurationDetail)

