import React, { useState, useEffect } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { dateFormatDDMMMYYYY } from "../../helpers/commonhelpers/dateFormat"
import ReactTooltip from "react-tooltip"
import {
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Container,
  Row,
  CardTitle,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux"
import { getOtherChargesDetail } from "store/actions"
import SweetAlert from "react-bootstrap-sweetalert"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory from "react-bootstrap-table2-filter"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import OverviewColumn from "./OverviewCol"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import AuthUser from "components/AuthUser/AuthUser"
import AllowedUsers from "components/AuthUser/AllowedUserList"
const Overview = props => {
  const { onGetOtherChargesDetails, onDeleteOtherCharges, otherCharge } = props
  const [{ modal_large, updatePopUp, deletePopUp }, setPopUpChoice] = useState({
    modal_large: false,
    updatePopUp: "",
    deletePopUp: "",
  })
  const { SearchBar } = Search
  const [list, setList] = useState([])
  const pageOptions = {
    sizePerPage: 10,
    totalSize: list?.length,
    custom: true,
    withFirstAndLast: true,
  }
  const user = AuthUser()
  const allowedUsers = AllowedUsers
  const [allowUser, setAllowUser] = useState(false)
  useEffect(() => {
    //console.log(allowedUsers)
    allowedUsers.map((users, i) => {
      if (user.email === users) {
        setAllowUser(true)
      }
    })
  }, [user, allowedUsers])

  useEffect(() => {}, [allowUser])
  useEffect(() => {
    if (otherCharge.monthlyCharge !== null) {
      setList(otherCharge.monthlyCharge)
    } else {
      setList([otherCharge.annualCharge])
    }
  }, [otherCharge])
  useEffect(() => {}, [list])

  const handleActionUpdate = () => {
    props.onChange(popup => ({
      ...popup,
      updatePopUp: "update",
    }))
  }

  const handleActionDelete = () => {
    props.onChange(popup => ({
      ...popup,
      deletePopUp: "delete",
    }))
  }

  const handleTableChange = searchText => {
    if (searchText !== "" && searchText !== undefined) {
      setList(
        list.filter(lst =>
          Object.keys(lst).some(key =>
            lst[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    }
  }

  return (
    <React.Fragment>
      <ReactTooltip place="bottom" type="dark" effect="solid" />
      <Row className="mx-2">
        <Col lg={12}>
          <Row>
            <Col md={6}>
              <p className="chargeDetail-text-size bold">Charge Details</p>
            </Col>
            {allowUser ? (
              <Col md={6} className="item-right">
                <i
                  data-tip="<div><small>Edit Other Charge</small></div> "
                  data-html={true}
                  onClick={() => handleActionUpdate()}
                  className="mdi mdi-pencil mr-2 fa-2x"
                  style={{ color: "blue" }}
                />
                <i
                  onClick={() => handleActionDelete()}
                  data-tip="<div><small>Delete Other Charge</small></div> "
                  data-html={true}
                  className=" fas fa-trash-alt fa-2x"
                  style={{ color: "red" }}
                />
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <CardTitle></CardTitle>
                  {/* <Label className="color-primary small">Charge Name</Label> */}
                  <div className=" bold otherCharges-text-size">
                    {otherCharge.chargeName}
                  </div>

                  {/* <div className=" otherCharges-text-size">
                    {otherCharge.billingOption}
                  </div> */}
                  <div className=" small mb-2">
                    {otherCharge.otherChargesId}
                  </div>
                  <div className=" otherCharges-text-size">
                    Date : {dateFormatDDMMMYYYY(otherCharge.dateCreated)}
                  </div>
                  <div className=" otherCharges-text-size">
                    External Reference : {otherCharge.externalReference}
                  </div>
                  <div className=" otherCharges-text-size">
                    Remarks : {otherCharge.remarks}
                  </div>
                  {/* <div className="  otherCharges-text-size">
                    Date Updated:{" "}
                    {otherCharge.dateUpdated !== null ? (
                      dateFormatDDMMMYYYY(otherCharge.dateUpdated)
                    ) : (
                      <>N/A</>
                    )}
                  </div> */}
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="small ">Customer Info</div>
                  <div className=" bold otherCharges-text-size">
                    {otherCharge.customerName}
                  </div>
                  <div className=" small">{otherCharge.customerId}</div>
                  <div className="small mt-2">Agreement Info</div>
                  <div className=" otherCharges-text-size">
                    {otherCharge.agreementId}
                  </div>
                </CardBody>
              </Card>
              {/* <Card>
                <CardBody>
                  <div className=" small mt-1">Fiscal Year</div>
                  <div className="  otherCharges-text-size">
                    From: {dateFormatDDMMMYYYY(otherCharge.fiscalYear?.from)}
                  </div>
                  <div className="  otherCharges-text-size">
                    To: {dateFormatDDMMMYYYY(otherCharge.fiscalYear?.to)}
                  </div>
                </CardBody>
              </Card> */}
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <Row className="my-2">
                    <Col sm="6" className="small">
                      {otherCharge.billingOption} Charges
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Label>Date</Label>
                    </Col>
                    <Col md={4}>
                      <Label>Amount</Label>
                    </Col>
                    <Col md={4}>
                      <Label>Currency</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>{dateFormatDDMMMYYYY(otherCharge.posting)}</Col>
                    <Col md={4}>{otherCharge.amount}</Col>
                    <Col md={4}>{otherCharge.currency}</Col>
                  </Row>
                  {/* <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={list || []}
                        columns={OverviewColumn()}
                        loading={true}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                             
                              <Col sm="6" className="item-right">
                                <div className="search-box mr-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder="Enter Keyword"
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    loading={true}
                                    bordered={false}
                                    hover={true}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    keyField="_id"
                                    headerWrapperClasses={"thead-light"}
                                    onTableChange={handleTableChange}
                                    filter={filterFactory()}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col xs="6">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </Col>

                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col md={6}></Col>
            <Col md={6}></Col>
          </Row> */}
          {/* <Row>
            <Col md={4}>
              <Label className="color-primary small">Customer Id</Label>
              <p className="bold otherCharges-text-size">
                {otherCharge.customerId}
              </p>
            </Col>
            <Col md={4}>
              <Label className="color-primary small">Customer Name</Label>
              <p className="bold otherCharges-text-size">
                {otherCharge.customerName}
              </p>
            </Col>
            <Col md={4}>
              <Label className="color-primary small">Agreement Id</Label>
              <p className="bold otherCharges-text-size">
                {otherCharge.agreementId}
              </p>
            </Col>
          </Row> */}

          {/* <Row>
            <Col md={12}>
              <Label className="color-primary bold">Fiscal Year</Label>
            </Col>
          </Row> */}
          {/* <Row>
            <Col md={3}>
              <Label className="color-primary small">From</Label>
              <p className="bold  otherCharges-text-size">
                {dateFormatDDMMMYYYY(otherCharge.fiscalYear?.from)}
              </p>
            </Col>
            <Col md={3}>
              <Label className="color-primary small">To</Label>
              <p className="bold  otherCharges-text-size">
                {dateFormatDDMMMYYYY(otherCharge.fiscalYear?.to)}
              </p>
            </Col>
            <Col md={3}>
              <Label className="color-primary small">Billing Option</Label>
              <p className="bold  otherCharges-text-size">
                {otherCharge.billingOption}
              </p>
            </Col>
            <Col md={3}>
              <Label className="color-primary small">Charge Name</Label>
              <p className="bold  otherCharges-text-size">
                {otherCharge.chargeName}
              </p>
            </Col>
          </Row> */}

          {/* <Row>
            <Col md={3}>
              <Label className="color-primary small">Date Created</Label>
              <p className="bold">
                {" "}
                {dateFormatDDMMMYYYY(otherCharge.dateCreated)}
              </p>
            </Col>
            <Col md={3}>
              <Label className="color-primary small">Date Updated</Label>
              <p className="bold">
                {" "}
                {otherCharge.dateUpdated !== null ? (
                  dateFormatDDMMMYYYY(otherCharge.dateUpdated)
                ) : (
                  <div>N/A</div>
                )}
              </p>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </React.Fragment>
  )
}
const mapStateToProps = state => {
  return {
    otherChargesDetail: state.OtherCharges.otherChargesDetail,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetOtherChargesDetails: id => dispatch(getOtherChargesDetail(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Overview)
