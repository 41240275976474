import {
GET_OPTION_DETAIL,
GET_OPTION_DETAIL_SUCCESS,
GET_OPTION_DETAIL_FAIL,
GET_OPTIONS ,
GET_OPTIONS_SUCCESS,
GET_OPTIONS_FAIL,
} from "./actionTypes"

export const getOptionDetail = () => ({
  type: GET_OPTION_DETAIL,
})

export const getOptionDetailSuccess = option => ({
  type: GET_OPTION_DETAIL_SUCCESS,
  payload: option,
})

export const getOptionDetailFail = error => ({
  type: GET_OPTION_DETAIL_FAIL,
  payload: error,
})

export const getOptions= () => ({
  type: GET_OPTIONS,
})

export const getOptionsSuccess = options => ({
  type: GET_OPTIONS_SUCCESS,
  payload: options,
})

export const getOptionsFail = error => ({
  type: GET_OPTIONS_FAIL,
  payload: error,
})

