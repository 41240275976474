import {
  GET_OPTION_DETAIL,
  GET_OPTION_DETAIL_SUCCESS,
  GET_OPTION_DETAIL_FAIL,
  GET_OPTIONS,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  optionList: [],
  option: {},
  error: {},
  loading: true,
}

const Options = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_OPTION_DETAIL_SUCCESS:
      return {
        ...state,
        option: action.payload,
      }

    case GET_OPTION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_OPTIONS_SUCCESS:
      return {
        ...state,
        optionList: action.payload,
      }

    case GET_OPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Options
