import {

  GET_CUSTOMER_CONFIGURATIONS_SUCCESS,
  GET_CUSTOMER_CONFIGURATIONS_FAIL,
  GET_CUSTOMER_CONFIGURATION_DETAIL_SUCCESS,
  GET_CUSTOMER_CONFIGURATION_DETAIL_FAIL,

  GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID_SUCCESS,
  GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID_FAIL,
  GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID,

  CREATE_CUSTOMER_CONFIGURATION,
  CREATE_CUSTOMER_CONFIGURATION_SUCCESS,
  CREATE_CUSTOMER_CONFIGURATION_FAIL,
  RESTART_STATE,

} from "./actionTypes"

const INIT_STATE = {
  customerConfigurations: [],
  customerConfigurationDetail: {},
  error: {},
  loading: true,
  loadingCreate: undefined,
  isCreated: undefined,
}

const CustomerConfigurations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMER_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        customerConfigurations: action.payload,
      }

    case GET_CUSTOMER_CONFIGURATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CUSTOMER_CONFIGURATION_DETAIL_SUCCESS:
      return {
        ...state,
        customerConfigurationDetail: action.payload,
        loading: false,
      }

    case GET_CUSTOMER_CONFIGURATION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }


    case GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID:
      return {
        ...state,
        customerConfigurationDetailByCustomerId: action.payload,
        loading: true
      }
    case GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID_SUCCESS:
      return {
        ...state,
        customerConfigurationDetailByCustomerId: action.payload,
        loading: false
      }
    case GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case CREATE_CUSTOMER_CONFIGURATION_SUCCESS:
      return {
        ...state,
        customerConfigurationDetail: action.payload,
        isCreated: true,
        loadingCreate: false,
      }

    case CREATE_CUSTOMER_CONFIGURATION_FAIL:
      return {
        ...state,
        error: action.payload,
        isCreated: false,
        loadingCreate: false,
      }

    case RESTART_STATE:
      return (state = {
        ...state,
        customerConfigurations: [],
        customerConfigurationDetail: {},
        error: {},
        loading: true,
        loadingCreate: undefined,
        isCreated: undefined      })


    default:
      return state
  }
}

export default CustomerConfigurations
