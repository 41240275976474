import classnames from "classnames"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"
import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap"
import "toastr/build/toastr.min.css"
import { v4 as uuidv4 } from "uuid"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ConfirmDetails_3 from "./Steps/ConfirmDetails_3"
import { CustomerDetails_1 } from "./Steps/CustomerDetails_1"
import nextId from "react-id-generator"
import {
  getCustomers,
  getCustomerOffices,
  getOptionDetail,
  getOptions,
  getProductList,
  createCustomerConfiguration,
  restartState,
} from "store/actions"
import { Agreement_2 } from "./Steps/Agreement_2"
import SweetAlert from "react-bootstrap-sweetalert"
import { LoadingBorderSpinner3DotBlue } from "../../helpers/commonhelpers/loadingSpinner"
import AuthUser from "components/AuthUser/AuthUser"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const initialState = {
  CustomerId: "00000",
  BusinessName: "",
  IsActive: true,
  DateJoined: new Date().toISOString(),
  RegisteredAddress: "",
  Telephone: "+974 ",
  Email: "",
  BusinessTradingName: "",
  Segment: "",
  Category: "",
  Company: "",
  KeyAccountManager: "",
  Agreements: [
    {
      id: uuidv4(),
      AgreementId: nextId("00"),
      SignedDate: new Date().toISOString(),
      EffectiveDate: new Date().toISOString(),
      Months: 0,
      EndDate: new Date().toISOString(),
      FiscalYearsDates: [
        {
          id: uuidv4(),
          From: new Date().toISOString(),
          To: new Date().toISOString(),
        },
      ],
      IsActive: true,
      ContentConfiguration: [
        {
          id: uuidv4(),
          ContentType: "",
          Airlines: [],
          IsExcluded: true,
        },
      ],
      IncentiveConfiguration: [
        {
          id: uuidv4(),
          MinimumBooking: 0,
          MaximumBooking: 100,
          AmountRate: 1,
          Currency: "QAR",
        },
      ],
      ProductsSolutionsConfiguration: [
        {
          id: uuidv4(),
          ProductName: "",
          ProductCode: "",
          Location: "QATAR",
          BillingOptions: [],
        },
      ],
    },
  ],
  Offices: [
    {
      id: uuidv4(),
      OfficeId: "DOHQR0000",
      OfficeName: "",
      Date: new Date().toISOString(),
      Users: [
        {
          id: uuidv4(),
          SignIn: "",
          Username: "",
          DateCreated: new Date().toISOString(),
          IsActive: false,
        },
      ],
    },
  ],
  LastUpdate: new Date().toISOString(),
}

const initialState_OtherCharges = {
  OtherCharges: [
    // {
    //   id: uuidv4(),
    //   CustomerId: "",
    //   AgreementId: "",
    //   FiscalYear: {
    //     From: new Date().toISOString(),
    //     To: new Date().toISOString(),
    //   },
    //   ChargeName: "",
    //   BillingOption: "",
    //   MonthlyCharge: [
    //     {
    //       id: uuidv4(),
    //       Date: new Date().toISOString(),
    //       Amount: 0.0,
    //       Currency: "QAR",
    //     },
    //   ],
    //   AnnualCharge: {
    //     Date: new Date().toISOString(),
    //     Amount: 0.0,
    //     DiscountedAmount: 0.0,
    //     Currency: "QAR",
    //   },
    //   DateCreated: new Date().toISOString(),
    //   DateUpdated: new Date().toISOString(),
    //   Remarks: "",
    //   IsWaived: false,
    // },
  ],
}

const CutomerConfigurationCreate = props => {
  const {
    onGetCustomers,
    onGetCustomerOffices,
    onGetOptions,
    onGetProducts,
    onCreateCustomerConfiguration,
    onReturnState,
    options,
    customers,
    products,
    customerOffices,
    loading,
    loadingCreateCustomer,
    isCreated,
    errorDescription,
  } = props

  const [user, setUser] = useState(AuthUser())
  const [activeTab, setactiveTab] = useState(1)
  const [customerConfigDetails, setCustomerConfigDetails] =
    useState(initialState)
  const [otherCharges, setOtherCharges] = useState(initialState_OtherCharges)
  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])
  const [customerList, setCustomerList] = useState([])
  const [customerOfficesList, setCustomerOfficesList] = useState()
  const [optionsList, setOptionsList] = useState()
  const [productList, setProductList] = useState()
  const [loadingCreate, setLoadingCreate] = useState(loadingCreateCustomer)
  const [isSuccessfullyCreated, setIsSuccessfullyCreated] = useState(isCreated)
  const [errorSaving, setErrorSaving] = useState(errorDescription)
  const [isOpenToastError, setIsOpenToastError] = useState(false)
  const [selectedBusinessFromDropdown, setSelectedBusinessFromDropdown] =
    useState(undefined)
  useEffect(() => {
    if (user.roleID === 1) {
      onGetCustomers()
      onGetOptions()
      onGetProducts()
    }
  }, [])

  useEffect(() => {
    setErrorSaving(errorDescription)
  }, [errorDescription])

  useEffect(() => {
    setLoadingCreate(loadingCreateCustomer)
  }, [loadingCreateCustomer])

  useEffect(() => {
    setIsSuccessfullyCreated(isCreated)
  }, [isCreated])

  useEffect(() => {
    setCustomerList(customers)
  }, [customers])

  useEffect(() => {
    if (options) {
      setOptionsList(options)
    }
  }, [options])

  useEffect(() => {
    if (products) {
      setProductList(products)
    }
  }, [products])

  useEffect(() => {
    if (customerOffices) {
      setCustomerOfficesList(customerOffices)
    }
  }, [customerOffices])

  useEffect(() => {}, [customerConfigDetails, otherCharges])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function handleCustomerDetailsChange(changes) {
    setCustomerConfigDetails(changes)
  }

  function handleOtherChargesListChange(changes) {
    setOtherCharges(changes)
  }

  function handleChange(changes) {
    handleCustomerDetailsChange({ ...customerConfigDetails, ...changes })
  }

  function handleAgreementChange(id, agreement) {
    const newAgreements = [...customerConfigDetails.Agreements]
    const index = newAgreements.findIndex(i => i.id === id)
    newAgreements[index] = agreement
    handleChange({ Agreements: newAgreements })
  }

  function handleAgreementAdd() {
    const newAgreement = {
      id: uuidv4(),
      AgreementId: nextId("00"),
      SignedDate: new Date().toISOString(),
      EffectiveDate: new Date().toISOString(),
      Months: 36,
      EndDate: new Date().toISOString(),
      FiscalYearsDates: [
        {
          id: uuidv4(),
          From: new Date().toISOString(),
          To: new Date().toISOString(),
        },
      ],
      IsActive: true,
      ContentConfiguration: [
        {
          id: uuidv4(),
          ContentType: "",
          Airlines: [],
          IsExcluded: true,
        },
      ],
      IncentiveConfiguration: [
        {
          id: uuidv4(),
          MinimumBooking: 0,
          MaximumBooking: 100,
          AmountRate: 1,
          Currency: "QAR",
        },
      ],
      ProductsSolutionsConfiguration: [
        {
          id: uuidv4(),
          ProductName: "",
          ProductCode: "",
          Location: "",
          BillingOptions: [
            {
              id: uuidv4(),
              UnitMeasure: "",
              UnitMeasureRemarks: "",
              Price: 0.0,
              DiscountedPrice: 0,
              Currency: "EUR",
              Frequency: "",
              Remarks: "",
              IsWaived: false,
              IsDiscounted: false,
              IsPricedBySlab: false,
              SlabRate: [
                {
                  id: uuidv4(),
                  Minimum: 0,
                  Maximum: 0,
                  AmountRate: 0,
                  Currency: "",
                },
              ],
            },
          ],
        },
      ],
    }

    handleChange({
      Agreements: [...customerConfigDetails.Agreements, newAgreement],
    })
  }

  function handleAgreementDelete(id) {
    handleChange({
      Agreements: customerConfigDetails.Agreements.filter(i => i.id !== id),
    })
  }

  function handleCloseSweetAlert() {
    resetState()
    location.reload()
  }

  function resetState() {
    setactiveTab(1)
    setLoadingCreate(undefined)
    setIsSuccessfullyCreated(undefined)
    setCustomerConfigDetails(initialState)
    onReturnState()
  }



  const insertCustomerConfiguration = () => {
    if (selectedBusinessFromDropdown === "selected") {
      onCreateCustomerConfiguration(customerConfigDetails)
    } else {
      toast.error('Select a customer', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {user.roleID === 1 ? (
          <>
            <Container fluid={true}>
              <Breadcrumbs
                title="Forms"
                breadcrumbItem="ADD NEW CUSTOMER CONFIG"
              />

              {isSuccessfullyCreated ? (
                <SweetAlert
                  title="Successfully Saved"
                  success
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => handleCloseSweetAlert()}
                ></SweetAlert>
              ) : null}

              {isSuccessfullyCreated === false ? (
                <SweetAlert
                  title="Something is wrong. Contact IT team"
                  error
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => setIsSuccessfullyCreated(undefined)}
                >
                  Show this error : {errorSaving}
                </SweetAlert>
              ) : null}

              {loadingCreate ? (
                <>
                  <LoadingBorderSpinner3DotBlue />
                </>
              ) : (
                <>
                  <Row>
                    <Col lg="12">
                      <Card>
                        <CardBody>
                          <h4 className="card-title mb-4"></h4>
                          <div className="wizard clearfix">
                            <div className="steps clearfix">
                              <ul>
                                <NavItem
                                  className={classnames({
                                    current: activeTab === 1,
                                  })}
                                >
                                  <NavLink
                                    className={classnames({
                                      current: activeTab === 1,
                                    })}
                                    onClick={() => {
                                      setactiveTab(1)
                                    }}
                                   disabled={!(passedSteps || []).includes(1)}
                                  >
                                    <span className="number">1.</span> Customer
                                    Details
                                  </NavLink>
                                </NavItem>
                                <NavItem
                                  className={classnames({
                                    current: activeTab === 2,
                                  })}
                                >
                                  <NavLink
                                    className={classnames({
                                      active: activeTab === 2,
                                    })}
                                    onClick={() => {
                                      setactiveTab(2)
                                    }}
                                   disabled={!(passedSteps || []).includes(2)}
                                  >
                                    <span className="number ms-2">02</span>{" "}
                                    Agreements
                                  </NavLink>
                                </NavItem>
                                <NavItem
                                  className={classnames({
                                    current: activeTab === 3,
                                  })}
                                >
                                  <NavLink
                                    className={classnames({
                                      active: activeTab === 3,
                                    })}
                                    onClick={() => {
                                      setactiveTab(3)
                                    }}
                                   disabled={!(passedSteps || []).includes(3)}
                                  >
                                    <span className="number">03</span> Confirm
                                    Details
                                  </NavLink>
                                </NavItem>
                                {/* <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(4)
                            }}
                            disabled={!(passedSteps || []).includes(4)}
                          >
                            <span className="number">04</span> Confirm Detail
                          </NavLink>
                        </NavItem> */}
                              </ul>
                            </div>

                            <div className="content clearfix mt-4">
                              <TabContent activeTab={activeTab}>
                                <TabPane tabId={1}>
                                  <CustomerDetails_1
                                    toggleTab={toggleTab}
                                    activeTab={activeTab}
                                    onGetCustomerOffices={onGetCustomerOffices}
                                    customerOfficesList={customerOfficesList}
                                    customerList={customerList}
                                    customerConfigDetails={
                                      customerConfigDetails
                                    }
                                    handleCustomerDetailsChange={
                                      handleCustomerDetailsChange
                                    }
                                    setSelectedBusinessFromDropdown={
                                      setSelectedBusinessFromDropdown
                                    }
                                  />
                                </TabPane>

                                <TabPane tabId={2}>
                                  <Row>
                                    <Col lg="3">
                                      <Button
                                        onClick={() => handleAgreementAdd()}
                                        color="success"
                                        className="btn-block inner"
                                        style={{ width: "100%" }}
                                      >
                                        + Add New Agreement
                                      </Button>
                                    </Col>
                                  </Row>
                                  <br></br>

                                  <Agreement_2
                                    customerConfigDetails={
                                      customerConfigDetails
                                    }
                                    optionsList={optionsList}
                                    productList={productList}
                                    otherCharges={otherCharges}
                                    handleOtherChargesListChange={
                                      handleOtherChargesListChange
                                    }
                                    handleAgreementChange={
                                      handleAgreementChange
                                    }
                                    handleAgreementDelete={
                                      handleAgreementDelete
                                    }
                                    toggleTab={toggleTab}
                                    activeTab={activeTab}
                                  />
                                </TabPane>
                                <TabPane tabId={3}>
                                  <Row>
                                    <ConfirmDetails_3
                                      otherCharges={otherCharges}
                                      customerConfigDetails={
                                        customerConfigDetails
                                      }
                                      insertCustomerConfiguration={
                                        insertCustomerConfiguration
                                      }
                                      toggleTab={toggleTab}
                                      activeTab={activeTab}
                                    />
                                  </Row>
                                </TabPane>
                                <TabPane tabId={4}>
                                  <div className="row justify-content-center">
                                    <Col lg="6">
                                      <div className="text-center">
                                        <div className="mb-4">
                                          <i className="mdi mdi-check-circle-outline text-success display-4" />
                                        </div>
                                        <div>
                                          <h5>Confirm Detail</h5>
                                          <p className="text-muted">
                                            If several languages coalesce, the
                                            grammar of the resulting
                                          </p>
                                        </div>
                                      </div>
                                    </Col>
                                  </div>
                                </TabPane>
                              </TabContent>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          </>
        ) : (
          <Redirect to="/page-404" />
        )}
      </div>
    </React.Fragment>
  )
}
CutomerConfigurationCreate.propTypes = {
  merchantDetail: PropTypes.object,
}

const mapStateToProps = state => ({
  customers: state.Customers.customersList,
  customerOffices: state.Customers.officeList,
  options: state.Options.optionList,
  products: state.ProductList.productList,
  loadingCustomers: state.Customers.loading,
  loadingCreateCustomer: state.customerconfigurations.loadingCreate,
  customerConfigurationDetail:
    state.customerconfigurations.customerConfigurationDetail,
  errorDescription: state.customerconfigurations.error,
  isCreated: state.customerconfigurations.isCreated,
})

const mapDispatchToProps = dispatch => ({
  onGetCustomers: () => dispatch(getCustomers()),
  onGetProducts: () => dispatch(getProductList()),
  onGetOptions: () => dispatch(getOptions()),
  onReturnState: () => dispatch(restartState()),
  onGetCustomerOffices: id => dispatch(getCustomerOffices(id)),
  onCreateCustomerConfiguration: data =>
    dispatch(createCustomerConfiguration(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CutomerConfigurationCreate))
