import React from "react"
import {
  Col,
  FormGroup,
  Input,
  Row,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap"

export default function OfficeUsers(props) {
  const { index, user, handleUserChange, handleUserDelete } = props

  function handleChange(changes) {
    handleUserChange(user.id, { ...user, ...changes })
  }
  return (
    <React.Fragment>
      <Form>
        <Row key={index}>
          <Col lg="3">
            <FormGroup key={index}>
              <input
                key={index}
                value={user.SignIn}
                onChange={e => handleChange({ SignIn: e.target.value })}
                type="text"
                name="SignIn"
                className="form-control"
                placeholder="Enter Sign In"
              />
            </FormGroup>
          </Col>

          <Col lg="3">
            <FormGroup>
              <input
                value={user.Username}
                onChange={e => handleChange({ Username: e.target.value })}
                type="text"
                name="Username"
                className="form-control"
                placeholder="Enter UserName"
              />
            </FormGroup>
          </Col>

          <Col lg="3">
            <FormGroup>
              <select
                className="custom-select"
                name="IsActive"
                value={user.IsActive}
                onChange={e =>
                  handleChange({
                    IsActive: e.target.value === "true" ? true : false,
                  })
                }
              >
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>

              <Input
                hidden
                disabled
                value={user.DateCreated}
                name="DateCreated"
                type="text"
                className="form-control"
                placeholder={" "}
              />
            </FormGroup>
          </Col>

          <Col lg="3">
            <Button
              onClick={e => handleUserDelete(user.id)}
              color="danger"
              className="btn-block inner"
              style={{ width: "100%" }}
            >
              {" "}
              Delete{" "}
            </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}
