import {
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAIL,
} from "./actionTypes"

export const getProductList = () => ({
  type: GET_PRODUCT_LIST,
})

export const getProductListSuccess = products => ({
  type: GET_PRODUCT_LIST_SUCCESS,
  payload: products,
})

export const getProductListFail = error => ({
  type: GET_PRODUCT_LIST_FAIL,
  payload: error,
})

