import { LoadingBorderSpinner } from "helpers/commonhelpers/loadingSpinner"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory from "react-bootstrap-table2-filter"
import ReactTooltip from "react-tooltip"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { connect } from "react-redux"
import {
  getOtherChargesByCustomerIdList,
  getOtherChargesList,
  deleteOtherCharges,
} from "store/actions"
import {
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import OtherChargesCol from "./Col"
import Overview from "../Overview"
import Add from "../AddOtherCharges/Add"
import Update from "../UpdateOtherCharges/Update"
import AuthUser from "components/AuthUser/AuthUser"
import AllowedUsers from "components/AuthUser/AllowedUserList"
function List(props) {
  const [list, setList] = useState([])
  const [{ modal_large, updatePopUp, deletePopUp }, setPopUpChoice] = useState({
    modal_large: false,
    updatePopUp: "",
    deletePopUp: "",
  })
  const [step, setStep] = useState(0)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [selectRow, setSelectRow] = useState({})
  const user = AuthUser()
  const allowedUsers = AllowedUsers
  const [allowUser, setAllowUser] = useState(false)

  const { SearchBar } = Search
  let form = ""
  let modalHeader = ""
  const pageOptions = {
    sizePerPage: 50,
    totalSize: list?.length,
    custom: true,
    withFirstAndLast: true,
  }
  const {
    loading,
    onGetOtherChargesList,
    otherChargesList,
    otherCharge,
    onDeleteOtherCharges,
  } = props
  useEffect(() => {
    onGetOtherChargesList()
  }, [])

  useEffect(() => {}, [selectRow])
  useEffect(() => {
    //console.log(allowedUsers)
    allowedUsers.map((users, i) => {
      if (user.email === users) {
        setAllowUser(true)
      }
    })
  }, [user, allowedUsers])

  useEffect(() => {}, [allowUser])

  useEffect(() => {
    if (otherCharge !== undefined) {
      onGetOtherChargesList()
    }
  }, [otherCharge])
  useEffect(() => {
    if (otherChargesList !== undefined) {
      setList(otherChargesList)
    }
  }, [otherChargesList])

  useEffect(() => {}, [list])
  useEffect(() => {}, [step])
  useEffect(() => {}, [selectRow])
  useEffect(() => {
    if (updatePopUp === "update") {
      setStep(1)
      openModal()
    }
    if (updatePopUp === "cancel") {
      setStep(2)
    }
  }, [updatePopUp])
  useEffect(() => {
    if (deletePopUp === "delete") {
      setconfirm_alert(true)
    }
  }, [deletePopUp])
  //////////////////////////////////////////////////////////////////////////////
  const handleTableChange = searchText => {
    if (searchText !== "" && searchText !== undefined) {
      setList(
        list.filter(lst =>
          Object.keys(lst).some(key =>
            lst[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    }
  }

  const openModal = () => {
    setPopUpChoice(popup => ({
      ...popup,
      modal_large: true,
    }))
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (row) {
        setSelectRow(row)
        setStep(2)
        openModal()
        // const link = "/othercharges/overview/" + row._id
        // window.open(link, "_blank")
      }
    },
  }

  const handleDeleteOtherCharge = () => {
    onDeleteOtherCharges(selectRow)
    setconfirm_alert(false)
    setPopUpChoice(popup => ({
      ...popup,
      modal_large: false,
    }))
  }
  //////////////////////////////////////////////////////////////////////////////////
  switch (step) {
    case 0:
      modalHeader = "ADD NEW CHARGES"
      form = <Add onChange={value => setPopUpChoice(value)} />
      break
    case 1:
      modalHeader = "UPDATE CHARGES"
      form = (
        <Update
          otherChargeDetails={selectRow}
          onChange={value => setPopUpChoice(value)}
        />
      )

      break
    case 2:
      modalHeader = "Overview"
      form = (
        <Overview
          // id={settlementId}
          otherCharge={selectRow}
          onChange={value => setPopUpChoice(value)}
        />
      )

      break
    default:
      break
  }
  return (
    <React.Fragment>
      <ReactTooltip place="bottom" type="dark" effect="solid" />
      <Row>
        <Col xs="12">
          <Modal
            size="xl"
            isOpen={modal_large}
            centered={true}
            className="modal-fullscreen"
          >
            <div className="modal-header">
              <Label className="my-auto">{modalHeader}</Label>
              <h5
                className="modal-title mt-0 color-danger"
                id="myLargeModalLabel"
              ></h5>
              <button
                onClick={() => {
                  setPopUpChoice(popup => ({
                    ...popup,
                    modal_large: false,
                    updatePopUp: "",
                    deletePopUp: "",
                  }))
                }}
                type="button"
                color="danger"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-window-close " style={{ color: "red" }} />
              </button>
            </div>
            <ModalBody>
              {loading ? (
                <Row className="spinner-custom mx-auto my-auto ">
                  <LoadingBorderSpinner />
                </Row>
              ) : (
                form
              )}
            </ModalBody>
          </Modal>
          {confirm_alert ? (
            <SweetAlert
              title="Delete Other Charge"
              warning
              showCancel
              confirmButtonText="Confirm"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                handleDeleteOtherCharge()
              }}
              onCancel={() => {
                setconfirm_alert(false)
                setPopUpChoice(popUp => ({
                  ...popUp,
                  deletePopUp: "",
                }))
              }}
            >
              Delete the selected other charge.
            </SweetAlert>
          ) : null}
          <Card>
            <CardBody>
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={list || []}
                    columns={OtherChargesCol()}
                    loading={true}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="8">
                            <div className="search-box mr-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar
                                  {...toolkitProps.searchProps}
                                  style={{ width: "30rem" }}
                                  placeholder="Enter Keyword"
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          {allowUser ? (
                            <Col sm="4">
                              <div className="text-sm-right">
                                <Button
                                  type="button"
                                  color="success"
                                  onClick={() => {
                                    openModal()
                                    setStep(0)
                                  }}
                                  className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                  <i className="mdi mdi-plus mr-1" />
                                  New Other Charges
                                </Button>
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                responsive
                                // remote={ {
                                //   filter: true, // not working
                                //   pagination: true, //not working
                                //   sort: true, // not working
                                //   cellEdit: false
                                // } }
                                loading={true}
                                bordered={false}
                                hover={true}
                                striped={false}
                                classes={"table table-centered table-nowrap"}
                                keyField="_id"
                                headerWrapperClasses={"thead-light"}
                                onTableChange={handleTableChange}
                                rowEvents={rowEvents}
                                // selectRow={selectRow}
                                filter={filterFactory()}
                                // defaultSorted={defaultSorted}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
                      <Col xs="12">
                        <div className="text-center my-3">
                          <Link to="#" className="text-success">
                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2" />
                                Load more
                              </Link>
                        </div>
                      </Col>
                    </Row> */}

                        <Row className="align-items-md-center mt-30">
                          <Col xs="6">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </Col>

                          <Col className="pagination pagination-rounded justify-content-end mb-5 inner-custom-pagination">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
const mapStateToProps = state => {
  return {
    loading: state.OtherCharges.loading,
    otherChargesList: state.OtherCharges.otherChargesList,
    otherCharge: state.OtherCharges.otherCharge,
  }
}

const mapDispatchToProps = dispatch => ({
  //   onGetCustomerConfigurations :()=> dispatch(getCustomerConfigurations())
  onGetOtherChargesByCustomerId: id =>
    dispatch(getOtherChargesByCustomerIdList(id)),
  onGetOtherChargesList: () => dispatch(getOtherChargesList()),
  onDeleteOtherCharges: data => dispatch(deleteOtherCharges(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(List)
