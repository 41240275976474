import React, { useState } from 'react'
import {
    Col,
    Row,
    Button,
    Table
} from "reactstrap"
import { map, isEmpty } from 'lodash'
import AgreementModal from './AgreementModal'
import { dateFormatDDMMMYYYY } from '../../helpers/commonhelpers/dateFormat'

export default function ConfigurationDetails(props) {

   

    const { customerConfigurationDetail } = props

    const [agreementSelected, setAgreementSelected] = useState()
    const [modal, setModal] = useState(false)
    const toggleModal = () => {
        setModal(!modal)
    }

    const handleAgreementOverview = (index) => {
        var agreement = customerConfigurationDetail?.agreements[index]
        setAgreementSelected(agreement)
        toggleModal()
    }

    return (
        <React.Fragment>
            <AgreementModal isOpen={modal} toggle={toggleModal} agreementSelected={agreementSelected} />
            <hr></hr>
            <Row> <span style={{ color: "blue" }}>Business Information</span></Row>
            <br></br>
            <Row>
                <Col lg={3}>
                    <small> Business Name </small> <br></br>
                    <b>{customerConfigurationDetail.businessName}</b>
                </Col>
                <Col lg={3}>
                    <small> Segment </small> <br></br>
                    <b>{customerConfigurationDetail.segment}</b>
                </Col>
                
                <Col lg={3}>
                    <small>Category</small> <br></br>
                    <b>{customerConfigurationDetail.category}</b>
                </Col>
                <Col lg={3}>
                    <small> Customer Id</small> <br></br>
                    <b>{customerConfigurationDetail.customerId}</b>
                </Col>
                
            </Row>
            <br></br>
            <Row>
            <Col lg={3}>
                    <small> Business Trading Name </small> <br></br>
                    <b>{customerConfigurationDetail.businessTradingName}</b>
                </Col>
                <Col lg={3}>
                    <small> Company Registration </small> <br></br>
                    <b>{customerConfigurationDetail.company}</b>
                </Col>
               
                <Col lg={3}>
                    <small> Contact Details </small> <br></br>
                    <b>{customerConfigurationDetail.email} {" "} {customerConfigurationDetail.telephone}</b>
                </Col>
                <Col lg={3}>
                    <small>Key Account Manager  </small> <br></br>
                    <b>{customerConfigurationDetail.keyAccountManager}</b>
                </Col>
            </Row>
            <br></br>
            <Row> <span style={{ color: "blue" }}>Agreement Details</span></Row>
            <br></br>

            <Table>
                <thead>
                    <tr>
                        <th>

                            <small>Agreement Id</small>
                        </th>

                        <th>

                            <small>Effective Date</small>
                        </th>

                        <th>

                            <small>Months Ending </small>
                        </th>
                        <th>

                            <small>End Date</small>
                        </th>
                        <th>

                            <small>Status</small>
                        </th>
                        <th>

                            <small>Action</small>
                        </th>


                    </tr>
                </thead>
                <tbody>

                    {map(customerConfigurationDetail.agreements, (a, index) =>

                        <>
                            <tr key={index}>
                                <td>

                                    <b>{a.agreementId}</b>
                                </td>

                                <td>

                                    <b>{dateFormatDDMMMYYYY(a.effectiveDate)}</b>
                                </td>
                                <td>

                                    <b>{a.months}</b>
                                </td>
                                <td>

                                    <b>{dateFormatDDMMMYYYY(a.endDate)}</b>
                                </td>
                                <td>

                                    <b>{a.isActive === true ? "Active" : "InActive"}</b>
                                </td>
                                <td>

                                    <Button
                                        color="primary"
                                        className="btn btn-primary btn-sm btn-block "
                                        onClick={() => handleAgreementOverview(index)}
                                    >
                                        <i className="fas fa-search mr-1" /> View
                                    </Button>

                                </td>

                            </tr>


                        </>
                    )}

                </tbody>
            </Table>
            <br></br>
            <Row> <span style={{ color: "blue" }}>Office List</span></Row>
            <br></br>
            <Row>
                <Col lg={3}>
                    <small>Office Id </small> <br></br>

                </Col>
                {/* <Col lg={3}>
                    <small>Added Date </small> <br></br>

                </Col> */}

                <Col lg={3}>
                    <small> Office Name</small> <br></br>

                </Col>


            </Row>

            {map(customerConfigurationDetail.offices, (a, index) =>

                <>
                    <Row>
                        <Col lg={3}>

                            <span>{a.officeId}</span>
                        </Col>

                        {/* <Col lg={3}>

                            <span>{dateFormatDDMMMYYYY(a.date)}</span>
                        </Col> */}

                        <Col lg={4}>

                            <span>{a.officeName} </span>
                        </Col>



                    </Row>


                </>
            )}

        </React.Fragment>
    )
}

