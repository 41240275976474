import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_OTHERCHARGES_LIST,
  GET_OTHERCHARGES_BY_CUSTOMERID_LIST,
  GET_OTHERCHARGES_DETAIL,
  SET_OTHERCHARGES,
  UPDATE_OTHERCHARGES,
  DELETE_OTHERCHARGES,
} from "./actionTypes"

import {
  getOtherChargesListSuccess,
  getOtherChargesListFail,
  getOtherChargesByCustomerIdListSuccess,
  getOtherChargesByCustomerIdListFail,
  getOtherChargesDetailSuccess,
  getOtherChargesDetailFail,
  setOtherChargesSuccess,
  setOtherChargesFail,
  updateOtherChargesSuccess,
  updateOtherChargesFail,
  deleteOtherChargesSuccess,
  deleteOtherChargesFail,
  restartState,
} from "./actions"

import {
  getOtherChargesList,
  getOtherChargesListByCustomerId,
  getOtherChargesDetail,
  setOtherCharges,
  updateOtherCharges,
  deleteOtherCharges,
} from "helpers/fakebackend_helper"

function* _getOtherChargeList() {
  try {
    const response = yield call(getOtherChargesList)
    if (response) {
      yield put(getOtherChargesListSuccess(response))
    } else {
      yield put(getOtherChargesListFail(response))
    }
  } catch (error) {
    yield put(getOtherChargesListFail(error))
  }
}

function* _getOtherChargesListByCustomerId({ payload: data }) {
  try {
    const response = yield call(getOtherChargesListByCustomerId, data)
    if (response) {
      yield put(getOtherChargesByCustomerIdListSuccess(response))
    } else {
      yield put(getOtherChargesByCustomerIdListFail(response))
    }
  } catch (error) {
    yield put(getOtherChargesByCustomerIdListFail(error))
  }
}

function* _getOtherChargesDetail({ payload: data }) {
  try {
    const response = yield call(getOtherChargesDetail, data)
    if (response) {
      yield put(getOtherChargesDetailSuccess(response))
    } else {
      yield put(getOtherChargesDetailFail(response))
    }
  } catch (error) {
    yield put(getOtherChargesDetailFail(error))
  }
}

function* _setOtherCharges({ payload: data }) {
  try {
    const response = yield call(setOtherCharges, data)
    if (response) {
      yield put(setOtherChargesSuccess(response))
    } else {
      yield put(setOtherChargesFail(response))
    }
    yield put(restartState())
  } catch (error) {
    yield put(setOtherChargesFail(error))
  }
}

function* _updateOtherCharges({ payload: data }) {
  try {
    const response = yield call(updateOtherCharges, data)
    if (response) {
      yield put(updateOtherChargesSuccess(response))
    } else {
      yield put(updateOtherChargesFail(response))
    }
    yield put(restartState())
  } catch (error) {
    yield put(updateOtherChargesFail(error))
  }
}

function* _deleteOtherCharges({ payload: data }) {
  try {
    const response = yield call(deleteOtherCharges, data)
    if (response) {
      yield put(deleteOtherChargesSuccess(response))
    } else {
      yield put(deleteOtherChargesFail(response))
    }
    yield put(restartState())
  } catch (error) {
    yield put(deleteOtherChargesFail(error))
  }
}

function* OtherChargesSaga() {
  yield takeEvery(GET_OTHERCHARGES_LIST, _getOtherChargeList)
  yield takeEvery(
    GET_OTHERCHARGES_BY_CUSTOMERID_LIST,
    _getOtherChargesListByCustomerId
  )
  yield takeEvery(GET_OTHERCHARGES_DETAIL, _getOtherChargesDetail)
  yield takeEvery(SET_OTHERCHARGES, _setOtherCharges)
  yield takeEvery(UPDATE_OTHERCHARGES, _updateOtherCharges)
  yield takeEvery(DELETE_OTHERCHARGES, _deleteOtherCharges)
}
export default OtherChargesSaga
