import axios from "axios"
import { del, get, post, put, patch, getAssets } from "./api_helper"
import * as url from "./url_helper"

////Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

////is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// export const uploadFileAttachment = file => post(url.UPLOAD_FILE_ATTACHMENT, file)

// get customer configurations
export const getCustomerConfigurations = () =>
  get(url.GET_CUSTOMER_CONFIGURATIONS)

// get customer configuration detail
export const getCustomerConfigurationDetail = id =>
  get(`${url.GET_CUSTOMER_CONFIGURATION_DETAIL}/${id}`, { params: { id } })

export const getCustomerConfigurationDetailByCustomerId = customerId =>
  get(`${url.GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID}/${customerId}`, {
    params: { customerId },
  })

// get incentivedue calculations
// export const getIncentiveDueCalculation = id =>
//   get(`${url.GET_INCENTIVE_DUE_CALCULATION}/${id}`, { params: { id } })

//createCustomerConfiguration

export const createCustomerConfiguration = data =>
  post(url.CREATE_CUSTOMER_CONFIGURATION, data)

export const getIncentiveDueCalculation = data =>
  post(url.GET_INCENTIVE_DUE_CALCULATION, data)

//getOptionDetail
export const getOptionDetail = option =>
  get(`${url.GET_OPTION_DETAIL}/${option}`, { params: { option } })

//getOptions
export const getOptions = () => get(url.GET_OPTIONS)

// getCustomers
export const getCustomers = () => get(url.GET_CUSTOMERS)

//getProducts
export const getProductList = () => get(url.GET_PRODUCT_LIST)

// getCustomerDetail
export const getCustomerDetail = id =>
  get(`${url.GET_CUSTOMER_DETAIL}/${id}`, { params: { id } })

//getCustomerOffices
export const getCustomerOffices = id =>
  get(`${url.GET_CUSTOMER_OFFICES}/${id}`, { params: { id } })

// get ticketed segments
export const getTicketedSegments = data => post(url.GET_TICKETED_SEGMENTS, data)

export const getUnpaidAirlines = data => post(url.GET_UNPAID_AIRLINES, data)

// getUidGraphics
export const getUidGraphics = data => post(url.GET_UID_GRAPHIC_COUNT, data)

// getAtcRefund
export const getAtcRefund = data => post(url.GET_ATC_REFUND, data)

// getAtcReissue
export const getAtcReissue = data => post(url.GET_ATC_REISSUE, data)

// getWebUettr
export const getWebUettr = data => post(url.GET_WEBUETTR, data)

// getOffers
export const getOffers = data => post(url.GET_OFFERS, data)

//getAir
export const getAir = data => post(url.GET_AIR, data)

//getImr
export const getImr = data => post(url.GET_IMR, data)

//getAllFaresPlus
export const getAllFaresPlus = data => post(url.GET_ALL_FARES_PLUS, data)

//getTouchlessHx
export const getTouchlessHx = data => post(url.GET_TOUCHLESS_HX, data)

//getAtan
export const getAtan = data => post(url.GET_ATAN, data)

//getRts
export const getRts = data => post(url.GET_RTS, data)

//getAgencyInsight
export const getAgencyInsight = data => post(url.GET_AGENCY_INSIGHT, data)

//getPnrRecall
export const getPnrRecall = data => post(url.GET_PNR_RECALL, data)

//  getFileFinishing

export const getFileFinishing = data => post(url.GET_FILE_FINISHING, data)

//getQualityMonitor
export const getQualityMonitor = data => post(url.GET_QUALITY_MONITOR, data)

//getAoct
export const getAoct = data => post(url.GET_AOCT, data)
// NO REPORT

// getAdtd
export const getAdtd = data => post(url.GET_ADTD, data)

//getDsr
export const getDsr = data => post(url.GET_DSR, data)

//getClc
export const getClc = data => post(url.GET_CLC, data)

//getB2bWallet
export const getB2bWallet = data => post(url.GET_B2B_WALLET, data)

//getMiniRules
export const getMiniRules = data => post(url.GET_MINIRULES, data)

//getPayAllLink
export const getPayAllLink = data => post(url.GET_PAYALLLINK, data)

//getMobileTraveller
export const getMobileTraveller = data => post(url.GET_MOBILETRAVELLER, data)

//getEpower
export const getEpower = data => post(url.GET_EPOWER, data)

// USER
const postLogin = data => post(url.POST_LOGIN, data)

export const otpLogin = data => post(url.OTP_LOGIN + "/" + data)

//SETTLEMENT
export const getSettlementList = () => get(url.GET_SETTLEMENT_LIST)
export const getSettlementByCustomerId = customerId =>
  get(`${url.GET_SETTLEMENT_BY_CUSTOMERID}/${customerId}`)
export const getSettlementId = id => get(`${url.GET_SETTLEMENT_ID}/${id}`)
export const setSettlement = data => post(url.SET_SETTLEMENT, data)
export const setSettlementExcel = data => post(url.SET_SETTLEMENT_EXCEL, data)
export const updateSettlement = data => post(url.UPDATE_SETTLEMENT, data)
export const deleteSettlement = id =>
  del(url.DELETE_SETTLEMENT, { headers: { id: id } })
export const fileUpload = data => post(url.FILE_UPLOAD, data)
export { getLoggedInUser, isUserAuthenticated, postLogin }

//Other Charges
export const getOtherChargesList = () => get(url.GET_OTHERCHARGES_LIST)
export const getOtherChargesListByCustomerId = customerId =>
  get(`${url.GET_OTHERCHARGES_LIST_BY_CUSTOMERID}/${customerId}`)
export const getOtherChargesDetail = id =>
  get(`${url.GET_OTHERCHARGES_DETAIL}/${id}`)
export const setOtherCharges = data => post(url.SET_OTHERCHARGES, data)
export const updateOtherCharges = data => post(url.UPDATE_OTHERCHARGES, data)
export const deleteOtherCharges = data => post(url.DELETE_OTHERCHARGES, data)
