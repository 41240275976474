import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Button, Col, Form, FormGroup, Label, Row } from "reactstrap"

export const BillingSlab = props => {
  const {
    index,
    key,
    slabrate,
    handleBillingSlabChange,
    handleBillingSlabDelete,
    handleSubmit,
    onSubmit,
    register,
    errors,
  } = props

  const inputMinimum= "Minimum" + index
  const inputMaximum = "Maximum" + index
  const inputAmountRate = "AmaountRate" + index
  const inputCurrency = "Currency" + index


  function handleChange(changes) {
    handleBillingSlabChange(slabrate.id, { ...slabrate, ...changes })
  }

  return (
    <React.Fragment>
     <Form onSubmit={handleSubmit(onSubmit)} >
        <Row>
          <Col lg="3">
            <FormGroup>
              <input
                value={slabrate.Minimum}
                onChange={e => handleChange({ Minimum: e.target.value })}
                type="number"
                min="0"
                step="1000"
                className="form-control"
                name={inputMinimum}
                placeholder="Minimum Booking"
              />
            </FormGroup>
          </Col>

          <Col lg="3">
            <FormGroup>
              <input
                value={slabrate.Maximum}
                onChange={e => handleChange({ Maximum: e.target.value })}
                type="number"
                min="0"
                step="1000"
                className="form-control"
                name={inputMaximum}
                placeholder="Maximum Booking"
              />
            </FormGroup>
          </Col>

          <Col lg="3">
            <FormGroup>
              <input
                type="number"
                min="0"
                step="0.5"
                value={slabrate.AmountRate}
                onChange={e =>
                  handleChange({ AmountRate: parseFloat(e.target.value) })
                }
                name={inputAmountRate}
                className="form-control"
                placeholder="Rate"
              />
            </FormGroup>
          </Col>

          <Col lg="2">
            <FormGroup>
              <select
                className="custom-select"
                name={inputCurrency}
                value={slabrate.Currency}
                onChange={e => handleChange({ Currency: e.target.value })}
              >
                <option value="EUR">EUR</option>
              </select>
            </FormGroup>
          </Col>
          <div class="d-flex justify-content-end">
            <Col>
              <Link to="#" onClick={() => handleBillingSlabDelete(slabrate.id)}>
                <i
                  className="fas fa-window-close"
                  style={{ color: "red" }}
                />
              </Link>
            </Col>
          </div>
        </Row>
      </Form>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BillingSlab)
