import React, { useEffect, useState } from "react"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
  Button,
  Form,
  Col,
  Label,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  Card,
  CardBody,
} from "reactstrap"
import { v4 as uuidv4 } from "uuid"
import { useForm } from "react-hook-form"
import { connect } from "react-redux"
import Select from "react-select"
import {
  getCustomerConfigurations,
  getCustomerConfigurationDetailByCustomerId,
  updateOtherCharges,
} from "store/actions"
import { dateFormatDDMMMYYYY } from "../../../helpers/commonhelpers/dateFormat"
import Charge from "./Charge"

function Update(props) {
  const [otherChargeDetails, setOtherChargesDetail] = useState({})
  const [cusId, setCustId] = useState("")
  const [cusAgreementId, setCusAgreementId] = useState("")
  const [cusAgreementIds, setCusAgreementIds] = useState([])
  const [selectBillingOption, setSelectBillingOption] = useState()
  const { register, handleSubmit, errors } = useForm()
  const [cusNameError, setCustNameError] = useState(false)
  const [cusAgreementError, setCustAgreementError] = useState(false)
  const [cusIdError, setCustIdError] = useState(false)
  const [selectCus, setSelectCus] = useState({})
  const [selectFiscalYears, setSelectFiscalYears] = useState([])
  const [selectFiscalYear, setSelectFiscalYear] = useState("")
  const [selectChargeName, setSelectChargeName] = useState("")
  const [amount, setSelectAmount] = useState([])
  const [currency, setSelectCurrency] = useState([])
  const [remarks, setSelectRemarks] = useState([])
  const [chargeNameError, setChargeNameError] = useState(false)
  const [selectExternalReference, setSelectExternalReference] = useState("")
  const {
    onGetCustomerConfigurationsList,
    customerList,
    onUpdateOtherCharges,
  } = props
  let optionsCustomerId = customerList.map((cus, i) => {
    return { value: cus.customerId, label: cus.customerId }
  })

  let optionsCustomerAgreements = cusAgreementIds?.map((ag, i) => {
    return { value: ag.agreementId, label: ag.agreementId }
  })

  let optionsBillingOptions = [
    { label: "Monthly", value: "Monthly" },
    { label: "Annually", value: "Annually" },
  ]

  let optionsChargeDetails = [
    { label: "ADSL", value: "ADSL" },
    { label: "HARDWARE", value: "HARDWARE" },
  ]

  // let optionsCustomerAgreementsFiscalYear = selectFiscalYears?.map((fy, i) => {
  //   return {
  //     value: fy.from + " to " + fy.to,
  //     label:
  //       dateFormatDDMMMYYYY(fy.from).props.children[0] +
  //       " to " +
  //       dateFormatDDMMMYYYY(fy.to).props.children[0],
  //   }
  // })

  useEffect(() => {
    if (props.otherChargeDetails) {
      setOtherChargesDetail(props.otherChargeDetails)
    }
  }, [props.otherChargeDetails])

  useEffect(() => {
    if (otherChargeDetails) {
      setCustId({
        value: otherChargeDetails.customerId,
        label: otherChargeDetails.customerId,
      })
      setCusAgreementId({
        value: otherChargeDetails.agreementId,
        label: otherChargeDetails.agreementId,
      })
      // setSelectFiscalYear({
      //   value:
      //     otherChargeDetails.fiscalYear?.from +
      //     " to " +
      //     otherChargeDetails.fiscalYear?.to,
      //   label:
      //     dateFormatDDMMMYYYY(otherChargeDetails.fiscalYear?.from).props
      //       .children[0] +
      //     " to " +
      //     dateFormatDDMMMYYYY(otherChargeDetails.fiscalYear?.to).props
      //       .children[0],
      // })
      if (selectChargeName.value === undefined) {
        setSelectChargeName({
          label: otherChargeDetails.chargeName,
          value: otherChargeDetails.chargeName,
        })
      }
      setSelectExternalReference(otherChargeDetails.externalReference)
      setSelectBillingOption({
        value: otherChargeDetails.billingOption,
        label: otherChargeDetails.billingOption,
      })
    }
  }, [otherChargeDetails])
  useEffect(() => {
    onGetCustomerConfigurationsList()
  }, [])
  useEffect(() => {}, [customerList])
  useEffect(() => {}, [cusAgreementIds])
  useEffect(() => {
    // if (cusAgreementId !== undefined) {
    //   cusAgreementIds?.map((ag, i) => {
    //     if (ag.agreementId == cusAgreementId.value) {
    //       setSelectFiscalYears(ag.fiscalYearsDates)
    //     }
    //   })
    // }
  }, [cusAgreementId])
  useEffect(() => {
    // console.log(selectChargeName)
  }, [selectChargeName])
  useEffect(() => {}, [cusId])
  useEffect(() => {}, [cusNameError])
  useEffect(() => {}, [cusAgreementError])
  useEffect(() => {}, [cusIdError])
  useEffect(() => {}, [chargeNameError])
  // useEffect(() => {
  //   if (selectFiscalYear.value) {
  //     var dates = selectFiscalYear.value.split(" to ")
  //     setSelectFiscalYearFrom(dates[0])
  //     setSelectFiscalYearTo(dates[1])
  //   }
  // }, [selectFiscalYear])
  // useEffect(() => {}, [selectFiscalYears])
  //   useEffect(() => {
  //     //console.log(selectFiscalYearFrom, selectFiscalYearTo)
  //     if (selectFiscalYearFrom && selectFiscalYearTo) {
  //       setOtherChargesDetail(otherCharge => ({
  //         ...otherCharge,
  //         FiscalYear: {
  //           From: new Date(selectFiscalYearFrom).toISOString(),
  //           To: new Date(selectFiscalYearTo).toISOString(),
  //         },
  //       }))
  //     }
  //   }, [selectFiscalYearFrom, selectFiscalYearTo])

  useEffect(() => {}, [selectExternalReference])

  useEffect(() => {
    if (otherChargeDetails.customerId !== "") {
      hadnleGetCustomerDetails("id", otherChargeDetails.customerId)
    }
  }, [otherChargeDetails.customerId])

  useEffect(() => {
    if (otherChargeDetails.customerId !== "") {
      setCustIdError(false)
    }
  }, [otherChargeDetails.customerId])

  useEffect(() => {
    if (otherChargeDetails.agreementId !== "") {
      setCustAgreementError(false)
    }
  }, [otherChargeDetails.agreementId])

  const handleUpdateSettlement = () => {
    //data.preventDefault()

    if (otherChargeDetails.externalReference === "") {
      setCustIdError(true)
    } else {
      setCustIdError(false)
    }
    if (otherChargeDetails.agreementId === "") {
      setCustAgreementError(true)
    } else {
      setCustAgreementError(false)
    }
    if (
      otherChargeDetails.externalReference !== "" ||
      otherChargeDetails.amount !== 0
    ) {
      //console.log(otherChargeDetails)
      onUpdateOtherCharges(otherChargeDetails)
      props.onChange(false)
    }
  }
  const handleSaveOtherCharges = () => {
    //console.log(settlementDetail)

    props.onChange(false)
    //window.location.reload()
  }
  const storeDataOnForm = event => {
    const { name, value } = event.target
    setOtherChargesDetail(otherCharge => ({ ...otherCharge, [name]: value }))
  }
  const handleChargeNameEvent = event => {
    // console.log(event.value)
    setSelectChargeName(event)
    setOtherChargesDetail(otherCharge => ({
      ...otherCharge,
      ChargeName: event.value,
    }))
  }

  const handleExternalReferenceEvent = event => {
    setSelectExternalReference(event.target.value)
    setOtherChargesDetail(otherCharge => ({
      ...otherCharge,
      externalReference: event.target.value,
    }))
  }
  const handleAmountEvent = event => {
    setSelectAmount(event.target.value)
    setOtherChargesDetail(otherCharge => ({
      ...otherCharge,
      amount: event.target.value,
    }))
  }
  const handleRemarksEvent = event => {
    setSelectRemarks(event.target.value)
    setOtherChargesDetail(otherCharge => ({
      ...otherCharge,
      remarks: event.target.value,
    }))
  }
  const handleCurrencyEvent = event => {
    setSelectCurrency(event.target.value)
    setOtherChargesDetail(otherCharge => ({
      ...otherCharge,
      currency: event.target.value,
    }))
  }

  const customerIDEvent = event => {
    setCustId(event)
    setOtherChargesDetail(otherCharge => ({
      ...otherCharge,
      customerId: event.value,
    }))
  }

  const customerAgreementEvent = event => {
    setCusAgreementId(event)
    setOtherChargesDetail(otherCharge => ({
      ...otherCharge,
      agreementId: event.value,
    }))
  }
  const customerBillingOptionEvent = event => {
    setSelectBillingOption(event)
    setOtherChargesDetail(otherCharge => ({
      ...otherCharge,
      billingOption: event.value,
    }))
  }

  const customerAgreementFiscalYearEvent = event => {
    setSelectFiscalYear(event)
    // console.log(event)
  }

  const hadnleGetCustomerDetails = (type, data) => {
    if (type === "id") {
      customerList.map((cus, i) => {
        if (data === cus.customerId) {
          setSelectCus(cus)
        }
      })
    }
  }
  const handlePostingDate = event => {
    //setSelectExternalReference(event.target.value)
    console.log(event)
    setOtherChargesDetail(otherCharge => ({
      ...otherCharge,
      postingDate: event,
    }))
  }
  // function handleAddMonths() {
  //   const newMonth = {
  //     id: uuidv4(),
  //     date: "",
  //     amount: 0,
  //     currency: "QAR",
  //   }
  //   handleChangeMonths({
  //     Months: [...otherChargeDetails.charge, newMonth],
  //   })
  // }
  // function handleChangeMonths(changes) {
  //   handleMonthsChange(changes)
  // }
  // function handleMonthsChange(changes) {
  //   //console.log(changes)
  //   setOtherChargesDetail(otherCharges => ({
  //     ...otherCharges,
  //     charge: changes.Months,
  //   }))
  // }
  // function handleChangeSingleMonth(id, month) {
  //   // console.log(id, month)
  //   const newMonth = [...otherChargeDetails.charge]
  //   const index = newMonth.findIndex(i => i.id === id)
  //   newMonth[index] = month
  //   handleChangeMonths({ Months: newMonth })
  // }

  // function handleDeleteMonth(id) {
  //   handleChangeMonths({
  //     Months: otherChargeDetails.charge.filter(i => i.id !== id),
  //   })
  // }

  // function handleMonthAnnualChange(changes) {
  //   setOtherChargesDetail(otherCharges => ({
  //     ...otherCharges,
  //     annualCharge: changes.Months,
  //   }))
  // }
  // function handleChangeSingleMonthAnnual(id, month) {
  //   // const newMonth = otherChargeDetails.AnnualCharge
  //   // const index = newMonth.findIndex(i => i.id === id)
  //   // newMonth[index] = month
  //   handleMonthAnnualChange({ Months: month })
  // }

  // function handleDeleteMonthAnnual(id) {
  //   handleMonthAnnualChange({
  //     Months: otherChargeDetails.annualCharge.filter(i => i.id !== id),
  //   })
  // }
  const handleCancel = () => {
    props.onChange(popup => ({
      ...popup,
      updatePopUp: "cancel",
    }))
  }
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Form>
            {/* <Row className="form-group mx-3 my-3">
              <Col md={5}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <Label className="input-group-text input-text-size">
                      Customer Id
                    </Label>
                  </InputGroupAddon>
                  <Select
                    value={cusId}
                    id="cusId"
                    name="cusId"
                    // innerRef={register({ required: true })}
                    onChange={event => customerIDEvent(event)}
                    options={optionsCustomerId}
                    className=" input-text-size width-settlement-id"
                    classNamePrefix="select2-selection"
                  />
                </InputGroup>
                {cusIdError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
              <Col md={3}></Col>
              <Col md={4}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <Label className="input-group-text input-text-size">
                      Agreement Id
                    </Label>
                  </InputGroupAddon>
                  <Select
                    id="cusAgreementId"
                    value={cusAgreementId}
                    name="cusAgreementId"
                    //innerRef={register({ required: true })}
                    onChange={event => customerAgreementEvent(event)}
                    options={optionsCustomerAgreements}
                    className=" input-text-size width-settlement-name"
                    classNamePrefix="select2-selection"
                  />
                </InputGroup>
                {cusAgreementError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
            </Row> */}
            <Row className="mx-3 ">
              <Col md={4}>
                <Label className="color-primary small">Customer Id</Label>
                <p className="bold otherCharges-text-size">
                  {otherChargeDetails.customerId}
                </p>
              </Col>
              <Col md={4}>
                <Label className="color-primary small">Customer Name</Label>
                <p className="bold otherCharges-text-size">
                  {otherChargeDetails.customerName}
                </p>
              </Col>
              <Col md={4}>
                <Label className="color-primary small">Agreement Id</Label>
                <p className="bold otherCharges-text-size">
                  {otherChargeDetails.agreementId}
                </p>
              </Col>
            </Row>
            {/* <Row className=" mx-3">
              <Col md={12}>
                <Label className="color-primary bold">Fiscal Year</Label>
              </Col>
            </Row> */}
            {/* <Row className=" mx-3">
              <Col md={4}>
                <Label className="color-primary small">From</Label>
                <p className="bold  otherCharges-text-size">
                  {dateFormatDDMMMYYYY(otherChargeDetails.fiscalYear?.from)}
                </p>
              </Col>
              <Col md={4}>
                <Label className="color-primary small">To</Label>
                <p className="bold  otherCharges-text-size">
                  {dateFormatDDMMMYYYY(otherChargeDetails.fiscalYear?.to)}
                </p>
              </Col>
             
            </Row> */}
            {/* <Row className="mx-3 ">
              <Col md={3}>
                <Label className="color-primary">Customer Id</Label>
              </Col>
              <Col md={3}>
                <Label className="color-primary">Agreement Id</Label>
              </Col>
              <Col md={3}>
                <Label className="color-primary">From</Label>
              </Col>
              <Col md={3}>
                <Label className="color-primary">To</Label>
              </Col>
            </Row>
            <Row className="mx-3">
              <Col md={3} className="bold">
              
              </Col>
              <Col md={3} className="bold">
                {otherChargeDetails.agreementId}
              </Col>
              <Col md={3} className="bold">
                {dateFormatDDMMMYYYY(otherChargeDetails.fiscalYear?.from)}
              </Col>
              <Col md={3} className="bold">
                {dateFormatDDMMMYYYY(otherChargeDetails.fiscalYear?.to)}
              </Col>
            </Row> */}
            <Row className="form-group mx-3">
              <Col md={4}>
                <Label className="color-primary small">Billing Option</Label>
                <p className="bold  otherCharges-text-size">
                  {otherChargeDetails.billingOption}
                </p>
              </Col>
              <Col md={4}>
                <Label className=" input-text-size">Charge Name</Label>
                <Select
                  id="selectChargeName"
                  value={selectChargeName}
                  name="selectChargeName"
                  defaultValue={{
                    label: otherChargeDetails.chargeName,
                    value: otherChargeDetails.chargeName,
                  }}
                  //innerRef={register({ required: true })}
                  onChange={event => handleChargeNameEvent(event)}
                  options={optionsChargeDetails}
                  classNamePrefix="select2-selection"
                />
                {/* {errors.cusId && errors.cusId.type === "required" && (
                  <p className="color-danger">This is required</p>
                )} */}
                {chargeNameError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
              <Col md={4}>
                <Label className=" input-text-size">External Reference</Label>
                <input
                  id="selectExternalReference"
                  name="selectExternalReference"
                  value={selectExternalReference}
                  type="text"
                  ref={register({ required: true })}
                  className="form-control "
                  onChange={event => handleExternalReferenceEvent(event)}
                />
                {cusIdError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
            </Row>

            {/* <p
              style={{ color: "#5c8FEC" }}
              className="item-center input-text-size"
            >
              <small>
                <b>
                  {selectFiscalYear ? (
                    <div>
                      {dateFormatDDMMMYYYY(selectFiscalYearFrom)} to{" "}
                      {dateFormatDDMMMYYYY(selectFiscalYearTo)}
                    </div>
                  ) : (
                    ""
                  )}
                </b>
              </small>
            </p> */}

            <Row className="form-group mx-3  my-2">
              <Col md={4}>
                <Label className=" input-text-size">Date</Label>

                <Flatpickr
                  name={"Posting"}
                  className="form-control w-100 my-1  "
                  placeholder="Select Date Range"
                  //innerRef={register({ required: true })}
                  value={otherChargeDetails.postingDate}
                  options={{
                    altFormat: "Y-m-d",
                    dateFormat: "F j, Y",
                  }}
                  onChange={e => handlePostingDate(new Date(e).toISOString())}
                />
                {/* {cusAgreementError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null} */}
              </Col>
              <Col md={4}>
                <Label className=" input-text-size">Amount</Label>
                <input
                  id="amount"
                  name="amount"
                  value={otherChargeDetails.amount}
                  type="number"
                  min="1"
                  step=".01"
                  ref={register({ required: true })}
                  className="form-control "
                  onChange={event => handleAmountEvent(event)}
                />
                {/* {errors.cusId && errors.cusId.type === "required" && (
                  <p className="color-danger">This is required</p>
                )} */}
                {/* {chargeNameError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null} */}
              </Col>
              <Col md={4}>
                <Label className=" input-text-size">Currency</Label>
                <input
                  id="Currency"
                  name="Currency"
                  value={otherChargeDetails.currency}
                  type="text"
                  ref={register({ required: true })}
                  className="form-control "
                  onChange={event => handleCurrencyEvent(event)}
                />

                {/* {chargeNameError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null} */}
              </Col>
            </Row>
            <Row className="form-group mx-3  my-2">
              <Col md={8}>
                <Label className=" input-text-size">Remarks</Label>
                <input
                  id="Remarks"
                  name="Remarks"
                  value={otherChargeDetails.remarks}
                  type="text"
                  ref={register({ required: true })}
                  className="form-control "
                  onChange={event => handleRemarksEvent(event)}
                />
                {/* {errors.cusId && errors.cusId.type === "required" && (
                  <p className="color-danger">This is required</p>
                )} */}
                {/* {chargeNameError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null} */}
              </Col>
            </Row>
            <Row>
              <Col md={12} className="item-center mb-3">
                <Button
                  color="primary"
                  //type="submit"
                  className=" waves-effect waves-light mt-3 btn-lg"
                  onClick={() => handleSubmit(handleUpdateSettlement())}
                >
                  <i className="mdi mdi-pencil mr-1" />
                  Update
                </Button>

                <Button
                  color="danger"
                  //type="submit"
                  className=" waves-effect waves-light mx-3 mt-3 btn-lg"
                  onClick={() => handleCancel()}
                >
                  <i className="fas fa-window-close mr-1" />
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </React.Fragment>
  )
}
const mapStateToProps = state => {
  return {
    customerList: state.customerconfigurations.customerConfigurations,
    customerConfigurationDetailByCustomerId:
      state.customerconfigurations.customerConfigurationDetailByCustomerId,
  }
}
const mapDispatchToProps = dispatch => ({
  onGetCustomerConfigurationsList: () => dispatch(getCustomerConfigurations()),
  onGetCustomerConfigurationDetailByCustomerID: id =>
    dispatch(getCustomerConfigurationDetailByCustomerId(id)),
  onUpdateOtherCharges: data => dispatch(updateOtherCharges(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Update)
