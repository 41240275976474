/* OPTIONS */


export const GET_OPTIONS = "GET_OPTIONS"
export const GET_OPTIONS_SUCCESS = "GET_OPTIONS_SUCCESS"
export const GET_OPTIONS_FAIL = "GET_OPTIONS_FAIL"

export const GET_OPTION_DETAIL = "GET_OPTION_DETAIL"
export const GET_OPTION_DETAIL_SUCCESS = "GET_OPTION_DETAIL_SUCCESS"
export const GET_OPTION_DETAIL_FAIL = "GET_OPTION_DETAIL_FAIL"


