import {
  GET_SETTLEMENT_LIST,
  GET_SETTLEMENT_LIST_SUCCESS,
  GET_SETTLEMENT_LIST_FAIL,
  GET_SETTLEMENT_BY_CUSTOMERID,
  GET_SETTLEMENT_BY_CUSTOMERID_SUCCESS,
  GET_SETTLEMENT_BY_CUSTOMERID_FAIL,
  GET_SETTLEMENT_ID,
  GET_SETTLEMENT_ID_SUCCESS,
  GET_SETTLEMENT_ID_FAIL,
  SET_SETTLEMENT,
  SET_SETTLEMENT_SUCCESS,
  SET_SETTLEMENT_FAIL,
  UPDATE_SETTLEMENT,
  UPDATE_SETTLEMENT_SUCCESS,
  UPDATE_SETTLEMENT_FAIL,
  DELETE_SETTLEMENT,
  DELETE_SETTLEMENT_SUCCESS,
  DELETE_SETTLEMENT_FAIL,
  FILE_UPLOAD,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAIL,
  RETURN_STATE,
  SET_SAVE_SETTLEMENT,
  SET_SETTLEMENT_EXCEL,
  SET_SETTLEMENT_EXCEL_SUCCESS,
  SET_SETTLEMENT_EXCEL_FAIL,
} from "./actionTypes"

export const getSettlementList = () => ({
  type: GET_SETTLEMENT_LIST,
})

export const getSettlementListSuccess = settlements => ({
  type: GET_SETTLEMENT_LIST_SUCCESS,
  payload: settlements,
})

export const getSettlementListFail = error => ({
  type: GET_SETTLEMENT_LIST_FAIL,
  payload: error,
})

export const getSettlementByCustomerId = id => ({
  type: GET_SETTLEMENT_BY_CUSTOMERID,
  payload: id,
})

export const getSettlementByCustomerIdSuccess = settlements => ({
  type: GET_SETTLEMENT_BY_CUSTOMERID_SUCCESS,
  payload: settlements,
})

export const getSettlementByCustomerIdFail = error => ({
  type: GET_SETTLEMENT_BY_CUSTOMERID_FAIL,
  payload: error,
})

export const getSettlementId = id => ({
  type: GET_SETTLEMENT_ID,
  payload: id,
})

export const getSettlementIdSuccess = settlements => ({
  type: GET_SETTLEMENT_ID_SUCCESS,
  payload: settlements,
})

export const getSettlementIdFail = error => ({
  type: GET_SETTLEMENT_ID_FAIL,
  payload: error,
})

export const setSettlement = settlement => ({
  type: SET_SETTLEMENT,
  payload: settlement,
})

export const setSettlmentSuccess = settlement => ({
  type: SET_SETTLEMENT_SUCCESS,
  payload: settlement,
})

export const setSettlementFail = error => ({
  type: SET_SETTLEMENT_FAIL,
  payload: error,
})

export const updateSettlement = settlement => ({
  type: UPDATE_SETTLEMENT,
  payload: settlement,
})

export const updateSettlementSuccess = settlement => ({
  type: UPDATE_SETTLEMENT_SUCCESS,
  payload: settlement,
})

export const updateSettlementFail = error => ({
  type: UPDATE_SETTLEMENT_FAIL,
  payload: error,
})

export const deleteSettlement = id => ({
  type: DELETE_SETTLEMENT,
  payload: id,
})

export const deleteSettlementSuccess = settlement => ({
  type: DELETE_SETTLEMENT_SUCCESS,
  payload: settlement,
})

export const deleteSettlementFail = error => ({
  type: DELETE_SETTLEMENT_FAIL,
  payload: error,
})

export const fileUpload = data => ({
  type: FILE_UPLOAD,
  payload: data,
})

export const fileUploadSuccess = data => ({
  type: FILE_UPLOAD_SUCCESS,
  payload: data,
})

export const fileUploadFail = error => ({
  type: FILE_UPLOAD_FAIL,
  payload: error,
})

export const setSaveSettlement = data => ({
  type: SET_SAVE_SETTLEMENT,
  payload: data,
})

export const returnState = () => ({
  type: RETURN_STATE,
})

export const setSettlementExcel = data => ({
  type: SET_SETTLEMENT_EXCEL,
  payload: data,
})

export const setSettlementExcelSuccess = data => ({
  type: SET_SETTLEMENT_EXCEL_SUCCESS,
  payload: data,
})

export const setSettlementExcelFail = data => ({
  type: SET_SETTLEMENT_EXCEL_FAIL,
  payload: data,
})
