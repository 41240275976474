import React from "react"
import {
  Row,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button,
  Badge,
  UncontrolledDropdown,
} from "reactstrap"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import { dateFormatDDMMMYYYY } from "../../../helpers/commonhelpers/dateFormat"

const SettlementColumn = (
  handleActionUpdate,
  handleActionDelete,
  handleDownload,
  allowUser
) => {
  return [
    // {
    //   dataField: "settlementId",
    //   text: "Settlement Id",
    //   searchable: true,
    //   sort: true,
    // },
    {
      dataField: "customerId",
      text: "Customer Id",
      searchable: true,
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return <>N/A</>
        } else {
          return <>{cellContent}</>
        }
      },
    },

    {
      dataField: "customerName",
      text: "Customer Name",
      searchable: true,
      sort: true,
    },
    {
      dataField: "agreementId",
      text: "Agreement Id",
      searchable: true,
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return <>N/A</>
        } else {
          return <>{cellContent}</>
        }
      },
    },

    {
      dataField: "dateCreated",
      text: "Date Created",
      searchable: false,
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{dateFormatDDMMMYYYY(cellContent)}</span>
      },
    },
    {
      dataField: "periodCoverageFrom",
      text: "Period Coverege From",
      searchable: false,
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{dateFormatDDMMMYYYY(cellContent)}</span>
      },
    },
    {
      dataField: "periodCoverageTo",
      text: "Period Coverege To",
      searchable: false,
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{dateFormatDDMMMYYYY(cellContent)}</span>
      },
    },
    {
      text: "Added By",
      dataField: "addedBy",
      sort: true,
      searchable: true,
    },
    {
      text: "Amount",
      dataField: "amount",
      sort: true,
    },
    {
      text: "Currency",
      dataField: "currency",
      sort: true,
    },

    {
      text: "External Reference",
      dataField: "externalReference",
      sort: true,
    },
    {
      text: "Remarks",
      dataField: "remarks",
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return <>N/A</>
        } else {
          return <>{cellContent}</>
        }
      },
    },
    {
      text: "File",
      dataField: "file",
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return <>N/A</>
        } else {
          if (cellContent.substring(14).length >= 20) {
            return <>{cellContent.substring(14, 30)}...</>
          } else {
            return <>{cellContent.substring(14)}</>
          }
        }
      },
    },
    {
      text: "CO",
      dataField: "co",
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return <>N/A</>
        } else {
          return <>{cellContent}</>
        }
      },
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === "Cleared") {
          return (
            <Badge
              className={"font-size-12 badge-soft-success"}
              color="success"
              pill
            >
              {cellContent.toString()}
            </Badge>
          )
        }
        if (cellContent !== "Cleared") {
          return (
            <Badge
              className={"font-size-12 badge-soft-danger"}
              color="danger"
              pill
            >
              {cellContent.toString()}
            </Badge>
          )
        }
      },
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <UncontrolledDropdown>
            <DropdownToggle href="#" className="card-drop" tag="i">
              <i className="mdi mdi-dots-horizontal font-size-18" />
            </DropdownToggle>
            <DropdownMenu right>
              {allowUser ? (
                <>
                  <DropdownItem onClick={() => handleActionUpdate(true)}>
                    <i
                      data-tip="Update Settlement "
                      data-html={true}
                      className="mdi mdi-pencil font-size-18 mr-2 color-primary"
                      id="edittooltip"
                    />
                    Update
                  </DropdownItem>

                  <DropdownItem onClick={() => handleActionDelete(true)}>
                    <i
                      data-tip="Delete Settlement "
                      data-html={true}
                      className="fas fa-trash-alt font-size-18 mr-2 color-danger"
                      id="deletetooltip"
                    />
                    Delete
                  </DropdownItem>
                </>
              ) : null}
              <DropdownItem onClick={() => handleDownload()}>
                <i
                  data-tip="Download File"
                  data-html={true}
                  className="dripicons-download font-size-18 mr-2 color-active"
                  id="deletetooltip"
                />
                Download File
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      },
    },
  ]
}

export default SettlementColumn
