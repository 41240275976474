import React, { useState, useEffect } from "react"
import {
  Col,
  Row,
  Button,
  InputGroup,
  InputGroupAddon,
  Label,
  Form,
} from "reactstrap"
import { setSettlementExcel } from "store/actions"
import AuthUser from "components/AuthUser/AuthUser"
import { connect } from "react-redux"
function Excel(props) {
  const { onSetSettlementExcel } = props
  const [file, setFile] = useState()
  const [array, setArray] = useState([])
  const fileReader = new FileReader()
  const [user, setUser] = useState(AuthUser())
  useEffect(() => {
    if (Array.isArray(array) && array.length !== 0) {
      // console.log(array)
      onSetSettlementExcel(array)
    }
  }, [array])
  const handleOnChange = e => {
    setFile(e.target.files[0])
  }

  const handleOnSubmit = e => {
    e.preventDefault()

    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result
        //console.log(csvOutput.replace(/(\r)/gm, ""))
        csvFileToArray(csvOutput.replace(/(\r)/gm, ""))
      }

      fileReader.readAsText(file)
    }
  }

  const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(";")
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n")

    const array = csvRows.map(i => {
      const values = i.split(";")
      const obj = csvHeader.reduce((object, header, index) => {
        if (header === "DateCreated" && values[index] !== undefined) {
          object[header] = new Date(values[index]).toISOString()
          return object
        } else if (
          header === "PeriodCoverageFrom" &&
          values[index] !== undefined
        ) {
          object[header] = new Date(values[index]).toISOString()
          return object
        } else if (header === "AddedBy" && values[index] === "") {
          object[header] = user.email
          return object
        } else if (
          header === "PeriodCoverageTo" &&
          values[index] !== undefined
        ) {
          object[header] = new Date(values[index]).toISOString()
          return object
        } else if (header === "Amount" && values[index] !== undefined) {
          if (values[index] !== "") {
            object[header] = parseFloat(values[index].replace(",", ""))
          } else {
            object[header] = parseFloat(0)
          }
          return object
        } else if (header === "IsActive" && values[index] !== undefined) {
          object[header] = true
          return object
        } else {
          object[header] = values[index]
          return object
        }
      }, {})
      return obj
    })

    setArray(array)
  }

  return (
    <div style={{ textAlign: "center" }}>
      <form className="my-3">
        <Row>
          <Col lg={8}>
            {" "}
            <input
              className="form-control  input-text-size "
              type={"file"}
              id={"csvFileInput"}
              accept={".csv"}
              onChange={handleOnChange}
            />
          </Col>
          <Col lg={4}>
            <Button
              color="success"
              onClick={e => {
                handleOnSubmit(e)
              }}
            >
              IMPORT CSV
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    customerList: state.customerconfigurations.customerConfigurations,
    customerConfigurationDetailByCustomerId:
      state.customerconfigurations.customerConfigurationDetailByCustomerId,
    settlement: state.Settlement.settlement,
    fileUpload: state.Settlement.file,
    fileNameBack: state.Settlement.fileName,
    storedSettlement: state.Settlement.storedSettlement,
  }
}

const mapDispatchToProps = dispatch => ({
  onSetSettlementExcel: data => dispatch(setSettlementExcel(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Excel)
