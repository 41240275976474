import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { isEmpty } from "lodash"
import "toastr/build/toastr.min.css"
import CustomerConfigurationList from "./CustomerConfigurationList"
import { getCustomerConfigurations } from "store/actions"

const CustomerConfiguration = props => {
  const { customerConfigurationList, onGetCustomerConfigurationList } = props

  const [custConfigList, setCustConfigList] = useState()

  useEffect(() => {
    onGetCustomerConfigurationList()
  }, [])

  useEffect(() => {
    if (customerConfigurationList) {
      setCustConfigList(customerConfigurationList)
    }
  }, [customerConfigurationList])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Incentive Due Calculation "
            breadcrumbItem="Customer List"
          />
          <CustomerConfigurationList custConfigList={custConfigList} />
        </Container>
      </div>
    </React.Fragment>
  )
}

CustomerConfiguration.propTypes = {
  customerConfigurationList: PropTypes.any,
  onGetCustomerConfigurationList: PropTypes.func,
}

const mapStateToProps = state => ({
  customerConfigurationList:
    state.customerconfigurations.customerConfigurations,
})

const mapDispatchToProps = dispatch => ({
  onGetCustomerConfigurationList: () => dispatch(getCustomerConfigurations()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerConfiguration)
