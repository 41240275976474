import React from "react"
import { Row, Col, Button, Badge } from "reactstrap"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import { dateFormatDDMMMYYYY } from "../../../helpers/commonhelpers/dateFormat"

const OtherChargesColumn = () => {
  return [
    {
      dataField: "otherChargesId",
      text: "Other Charges Id",
      searchable: true,
      sort: true,
    },
    {
      dataField: "customerId",
      text: "Customer Id",
      searchable: true,
      sort: true,
    },
    {
      dataField: "customerName",
      text: "Customer Name",
      searchable: true,
      sort: true,
    },

    {
      dataField: "agreementId",
      text: "Agreement Id",
      searchable: true,
      sort: true,
    },

    // {
    //   dataField: "fiscalYear.from",
    //   text: "Fiscal Year From",
    //   searchable: false,
    //   sort: true,
    //   formatter: (cellContent, row) => {
    //     if (cellContent == null) {
    //       return <span>N/A</span>
    //     } else {
    //       return <span>{dateFormatDDMMMYYYY(cellContent)}</span>
    //     }
    //   },
    // },
    // {
    //   dataField: "fiscalYear.to",
    //   text: "Fiscal Year To",
    //   searchable: false,
    //   sort: true,
    //   formatter: (cellContent, row) => {
    //     if (cellContent == null) {
    //       return <span>N/A</span>
    //     } else {
    //       return <span>{dateFormatDDMMMYYYY(cellContent)}</span>
    //     }
    //   },
    // },
    {
      dataField: "chargeName",
      text: "Charge Name",
      searchable: true,
      sort: true,
    },
    {
      dataField: "billingOption",
      text: "Billing Option",
      searchable: true,
      sort: true,
    },
    {
      dataField: "dateCreated",
      text: "Date ",
      searchable: false,
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{dateFormatDDMMMYYYY(cellContent)}</span>
      },
    },
    {
      dataField: "postingDate",
      text: "Posting Date",
      searchable: false,
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{dateFormatDDMMMYYYY(cellContent)}</span>
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      searchable: true,
      sort: true,
    },
    {
      dataField: "currency",
      text: "Currency",
      searchable: true,
      sort: true,
    },
    // {
    //   dataField: "dateUpdated",
    //   text: "Date Updated",
    //   searchable: false,
    //   sort: true,
    //   formatter: (cellContent, row) => {
    //     if (cellContent == null) {
    //       return <span>N/A</span>
    //     } else {
    //       return <span>{dateFormatDDMMMYYYY(cellContent)}</span>
    //     }
    //   },
    // },
    // {
    //   dataField: "isActive",
    //   text: "Active",
    //   sort: true,
    //   formatter: (cellContent, row) => {
    //     if (cellContent === true) {
    //       return (
    //         <Badge
    //           className={"font-size-12 badge-soft-success"}
    //           color="success"
    //           pill
    //         >
    //           {cellContent.toString()}
    //         </Badge>
    //       )
    //     }
    //     if (cellContent === false) {
    //       return (
    //         <Badge
    //           className={"font-size-12 badge-soft-danger"}
    //           color="danger"
    //           pill
    //         >
    //           {cellContent.toString()}
    //         </Badge>
    //       )
    //     }
    //   },
    // },
    // {
    //   dataField: "fiscalYear.To",
    //   text: "Fiscal Year To",
    //   searchable: false,
    //   sort: true,
    //   formatter: (cellContent, row) => {
    //     return <span>{dateFormatDDMMMYYYY(cellContent)}</span>
    //   },
    // },
    // {
    //   text: "Added By",
    //   dataField: "addedBy",
    //   sort: true,
    //   searchable: true,
    // },
    // {
    //   text: "Amount",
    //   dataField: "amount",
    //   sort: true,
    // },

    // {
    //   text: "Outstanding",
    //   dataField: "outstanding",
    //   sort: true,
    // },
    // {
    //   text: "Remarks",
    //   dataField: "remarks",
    //   sort: true,
    //   formatter: (cellContent, row) => {
    //     if (cellContent === null || cellContent === "") {
    //       return <>N/A</>
    //     } else {
    //       return <>{cellContent}</>
    //     }
    //   },
    // },
    // {
    //   text: "File",
    //   dataField: "file",
    //   sort: true,
    //   formatter: (cellContent, row) => {
    //     if (cellContent === null || cellContent === "") {
    //       return <>N/A</>
    //     } else {
    //       if (cellContent.substring(14).length >= 20) {
    //         return <>{cellContent.substring(14, 30)}...</>
    //       } else {
    //         return <>{cellContent.substring(14)}</>
    //       }
    //     }
    //   },
    // },
    // {
    //   dataField: "_id",
    //   text: "Action",
    //   formatter: (cellContent, row) => {
    //     return (
    //       <React.Fragment>
    //         <ReactTooltip place="bottom" type="dark" effect="solid" />
    //         <Button
    //           data-tip="<div><small>Edit Other Charge</small></div> "
    //           data-html={true}
    //           color="primary"
    //           type="submit"
    //           className=" waves-effect waves-light  btn-lg"
    //           //onClick={() => handleUpdateSettlement()}
    //         >
    //           <i className="mdi mdi-pencil mr-1" />
    //         </Button>
    //         <Button
    //           data-tip="<div><small>Delete Other Charge</small></div> "
    //           data-html={true}
    //           color="danger"
    //           type="submit"
    //           className=" waves-effect waves-light ml-2 btn-lg"
    //           onClick={() => handleActionDelete()}
    //         >
    //           <i className="fas fa-trash-alt mr-1" />
    //         </Button>
    //       </React.Fragment>
    //     )
    //   },
    // },
  ]
}

export default OtherChargesColumn
