import React, { useState, useEffect, useRef } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Popover,
  PopoverHeader,
  PopoverBody,
  Collapse,
} from "reactstrap"
import moment from "moment"
import { map, isEmpty } from "lodash"
import { Link } from "react-router-dom"
import { dateFormatDDMMMYYYY } from "../../helpers/commonhelpers/dateFormat"
import ReactExport from "react-export-excel"
import ReactHTMLTableToExcel from "react-html-table-to-excel"

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const ExcelRow = ReactExport.ExcelFile.ExcelRow

const dataSet1 = [
  {
    name: "Johson",
    amount: 30000,
    sex: "M",
    is_married: true,
  },
  {
    name: "Monika",
    amount: 355000,
    sex: "F",
    is_married: false,
  },
  {
    name: "John",
    amount: 250000,
    sex: "M",
    is_married: false,
  },
  {
    name: "Josef",
    amount: 450500,
    sex: "M",
    is_married: true,
  },
]

var dataSet2 = [
  {
    name: "Johnson",
    total: 25,
    remainig: 16,
  },
  {
    name: "Josef",
    total: 25,
    remainig: 7,
  },
]

const Summary = props => {
  const {
    custConfig,
    incentiveDue,
    coveredPeriod,
    userRoleId,
    isCustomer,
    handleShowTicketedSegments,
    handleShowUnpaidAirlines,
    handleShowUidUsage,
    handleProductsDetailedView,
  } = props

  console.log(props)

  const [customerConfigurationDetail, setCustomerConfigurationDetail] =
    useState(custConfig)
  const [dueIncentiveCalculation, setDueIncentiveCalculation] =
    useState(incentiveDue)

  const [dueIncentiveCalculationArray, setDueIncentiveCalculationArray] =
    useState([])
  const [filteredDates, setFilteredDates] = useState(coveredPeriod)
  const [popovertop, setpopovertop] = useState(false)

  const [billableProductsCollapse, setBillableProductsCollapse] =
    useState(true)
  const [otherchargesCollapse, setOtherChargesCollapse] = useState(true)
  const [paymentSettlementsCollapse, setPaymentSettlementCollapse] =
    useState(true)
  const [fileName, setFileName] = useState("Summary")

  console.log(dueIncentiveCalculation)

  useEffect(() => {
    setCustomerConfigurationDetail(custConfig)
    var filename = custConfig?.businessName + "- SUMMARY - " 
    setFileName(filename)
  }, [custConfig])

  useEffect(() => {
    setDueIncentiveCalculation(incentiveDue)

    const arr = []
    arr.push(incentiveDue)
    setDueIncentiveCalculationArray(arr)
    console.log(arr)
  }, [incentiveDue])

  useEffect(() => {
    setFilteredDates(coveredPeriod)
  }, [coveredPeriod])

  const collapseBillableProducts = () => {
    setBillableProductsCollapse(!billableProductsCollapse)
  }
  const collapseOthercharges = () => {
    setOtherChargesCollapse(!otherchargesCollapse)
  }
  const collapsePaymentSettlements = () => {
    setPaymentSettlementCollapse(!paymentSettlementsCollapse)
  }

  useEffect(() => {
    var twoyearsago = new Date()
    twoyearsago.setFullYear(twoyearsago.getFullYear() - 2)

    console.log(twoyearsago)
  }, [])

  return (
    <React.Fragment>
      <>
        <Card>
          <CardBody style={{ minHeight: "635px" }}>
            <div>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button download-button"
                table="table-to-xls"
                filename={fileName}
                sheet="tablexls"
                buttonText={<p style={{
                  fontSize: "15px",
                  color: "green",
                  textAlign: "right",
                }}><i className="fas fa-file-excel fa-2x" />
                </p>}
            
              />
              <table id="table-to-xls">
                <tr>
                  <td colSpan={2}>
                    {" "}
                    <b> Summary of Incentive Calculation </b>
                  </td>
                </tr>
                <tr>
                <td colSpan={2}>{customerConfigurationDetail?.businessName}</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <span style={{ color: "blue" }}>
                      {moment(filteredDates?.[0]).format("MMMM YYYY")}{" "}
                      {filteredDates?.[1]
                        ? " to " +
                          moment(filteredDates?.[1]).format("MMMM YYYY")
                        : ""}{" "}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
              
                <tr>
                  <td>
                    <Button
                      color="secondary"
                      outline
                      className="waves-effect btn-sm"
                      style={{
                        borderColor: "#ffffff00",
                        padding: "0px",
                        fontSize: "0.8125rem",
                      }}
                      onClick={handleShowTicketedSegments}
                    >
                      Ticketed Segments
                    </Button>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    <span className="line-item">
                      {dueIncentiveCalculation?.totalSegments}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Button
                      color="secondary"
                      outline
                      className="waves-effect btn-sm"
                      style={{
                        borderColor: "#ffffff00",
                        padding: "0px",
                        fontSize: "0.8125rem",
                      }}
                      onClick={handleShowUidUsage}
                    >
                      UID Usage
                    </Button>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    <span className="line-item-deduction">
                      ( {dueIncentiveCalculation?.totalUidUsage} )
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Button
                      color="secondary"
                      outline
                      className="waves-effect btn-sm"
                      style={{
                        borderColor: "#ffffff00",
                        padding: "0px",
                        fontSize: "0.8125rem",
                      }}
                      onClick={handleShowUnpaidAirlines}
                    >
                      Unpaid Airlines
                    </Button>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    <span className="line-item-deduction">
                      ( {dueIncentiveCalculation?.totalUnpaidAirlines} )
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Button
                      color="secondary"
                      outline
                      className="waves-effect btn-sm"
                      style={{
                        borderColor: "#ffffff00",
                        padding: "0px",
                        fontSize: "0.8125rem",
                      }}
                    >
                      NDC
                    </Button>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    <span className="line-item-deduction">( 0 )</span>
                  </td>
                </tr>

                <tr >
                    <td colspan={2} style={{borderBottom: "1px solid black"}}>
                     
                    </td>
                  </tr>

                <tr>
                  <td>
                    <b className="line-item-bolder-gray">
                      Net Ticketed Segments
                    </b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <b className="line-item-bolder-gray">
                      {dueIncentiveCalculation?.totalNetTicketedSegment}
                    </b>
                  </td>
                </tr>

<tr>
  <td></td>
</tr>
                <tr>
                  {!isCustomer && (
                    <>
                      <td>
                        <Button
                          style={{
                            borderColor: "#ffffff00",
                            padding: "0px",
                            fontSize: "0.8125rem",
                            color: "gray",
                          }}
                          id="Popovertop"
                          color="gray"
                          onClick={() => {
                            setpopovertop(!popovertop)
                          }}
                        >
                          Incentive Rate*
                        </Button>
                        <Popover
                          placement="top"
                          isOpen={popovertop}
                          target="Popovertop"
                          toggle={() => {
                            setpopovertop(!popovertop)
                          }}
                        >
                          <PopoverHeader>Rate per Booking</PopoverHeader>
                          <PopoverBody>See customer configuration</PopoverBody>
                        </Popover>{" "}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <span className="line-item">
                          {dueIncentiveCalculation?.ratePerBooking}{" "}
                          {dueIncentiveCalculation?.currency}
                        </span>
                      </td>
                    </>
                  )}
                </tr>

                <tr>
                  <td colSpan={2}>
                    {" "}
                    <small className="color-danger">
                      <b>*Disclaimer:</b> Rates may change depending on the
                      achieved eligible bookings within the contract year cycle.
                      {/* <li>Minimum rate is used for unachieved number of bookings.</li> */}
                    </small>
                  </td>
                </tr>

                <tr >
                    <td colspan={2} style={{borderBottom: "1px solid black"}}>
                     
                    </td>
                  </tr>

                <tr>
                  <td>
                    <b className="line-item-bolder-green">
                      {" "}
                      Calculated Incentive{" "}
                    </b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <b className="line-item-bolder-green">
                      {dueIncentiveCalculation?.totalIncentiveDue}{" "}
                      {dueIncentiveCalculation?.currency}
                    </b>
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>



                {/* TOTAL BILLABLE PRODUCTS -------------------------------------------------------*/}

               
                  <tr >
                    <td>
                      <small style={{ color: "red" }}>
                        {" "}
                        Products & Solutions{" "}
                      </small>
                    </td>
                    <td></td>
                  </tr>

                  {map(
                    dueIncentiveCalculation?.productsSolutions
                      ?.productsSolutionsSummaryList,
                    (x, index) => (
                      <tr key={index} hidden={billableProductsCollapse}>
                        {x.total <= 0 && x.isWaived === false ? (
                          <>
                            <td >
                              <Button
                                color="secondary"
                                outline
                                className="waves-effect btn-sm"
                                style={{
                                  borderColor: "#ffffff00",
                                  padding: "0px",
                                  fontSize: "0.8125rem",
                                  textAlign: "left"
                                }}
                              >
                                {" "}
                                {x.productName}{" "}
                              </Button>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <span className="line-item">
                                {x.total} {x.currency}
                              </span>
                            </td>
                          </>
                        ) : (
                          <>
                           <td >
                              <Button
                                color="secondary"
                                outline
                                className="waves-effect btn-sm"
                                style={{
                                  borderColor: "#ffffff00",
                                  padding: "0px",
                                  fontSize: "0.8125rem",
                                }}
                                onClick={() =>
                                  handleProductsDetailedView(x.productCode)
                                }
                              >
                                {" "}
                                {x.productName}{" "}
                              </Button>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {x.isWaived ? (
                                <span className="line-item-waived">
                                  {x.total} {x.currency}
                                </span>
                              ) : (
                                <span className="line-item">
                                  {x.total} {x.currency}
                                </span>
                              )}
                            </td>
                          </>
                        )}
                      </tr>
                    )
                  )}

              

                <tr>
                  <td>
                    <Link
                      to="#"
                      onClick={collapseBillableProducts}
                      style={{ cursor: "pointer", color: "#495057" }}
                    >
                      <span className="line-item-bolder-gray">
                        Total Billable Products
                        {billableProductsCollapse === false ? (
                          <i className="bx bx-chevron-down" />
                        ) : (
                          <i className="bx bx-chevron-up" />
                        )}{" "}
                      </span>
                    </Link>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <Link
                      to="#"
                      onClick={collapseBillableProducts}
                      style={{ cursor: "pointer", color: "#495057" }}
                    >
                      <span className="line-item-bolder-red">(
                        {
                          dueIncentiveCalculation?.productsSolutions
                            ?.totalProductsSolutions
                        }{" "}
                        {dueIncentiveCalculation?.currency} )
                      </span>
                    </Link>
                  </td>
                </tr>

                {/* OTHER CHARGES -------------------------------------------------------*/}

                <tr>
                  <td></td>
                </tr>
           
                  <tr >
                    <td>
                      <small style={{ color: "red" }}> Other Charges </small>
                    </td>
                    <td></td>
                  </tr>
                  {map(
                    dueIncentiveCalculation?.otherCharges?.otherChargesList,
                    (x, index) => (
                      <tr hidden={otherchargesCollapse}>
                        <td>
                          <p style={{ color: "gray" }}>
                            {" "}
                            {x.chargeName}{" "}
                            <small>{dateFormatDDMMMYYYY(x.postingDate)}</small>
                          </p>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <p style={{ textAlign: "right", color: "red" }}>
                            {" "}
                            ( {x.amount} {x.currency} )
                          </p>
                        </td>
                      </tr>
                    )
                  )}
           
           

                <tr>
                  <td>
                    <Link
                      to="#"
                      onClick={collapseOthercharges}
                      style={{ cursor: "pointer", color: "#495057" }}
                    >
                      <span className="line-item-bolder-gray">
                        Total Other Charges{" "}
                        {otherchargesCollapse === false ? (
                          <i className="bx bx-chevron-down" />
                        ) : (
                          <i className="bx bx-chevron-up" />
                        )}{" "}
                      </span>
                    </Link>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <Link
                      to="#"
                      onClick={collapseOthercharges}
                      style={{ cursor: "pointer", color: "#495057" }}
                    >
                      <span className="line-item-bolder-red">(
                        {
                          dueIncentiveCalculation?.otherCharges
                            ?.totalOtherCharges
                        }{" "}
                          {dueIncentiveCalculation?.currency})
                      </span>
                    </Link>
                  </td>
                </tr>



                {/* PAYMENT SETTLEMENTS -------------------------------------------------------*/}

                <tr>
                  <td></td>
                </tr>

                  <tr >
                    <td>
                      <small style={{ color: "red" }}>
                        {" "}
                        Payment Settlements{" "}
                      </small>
                    </td>
                    <td></td>
                  </tr>

                  {map(
                    dueIncentiveCalculation?.paymentSettlements
                      ?.paymentSettlementDetailedList,
                    (x, index) => (
                      <tr  hidden = {paymentSettlementsCollapse}>
                        <td >
                          <span className="line-item">
                            {" "}
                            {x.remarks}{" "}
                            {/* <small>({dateFormatDDMMMYYYY(x.dateofSettlement)})</small> */}
                            <small>{x.externalReference}</small>
                          </span>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <span className="line-item-deduction">
                            {" "}
                             {x.amount} QAR 
                          </span>
                        </td>
                      </tr>
                    )
                  )}
          
      

                <tr>
                  <td>
                    <Link
                      to="#"
                      onClick={collapsePaymentSettlements}
                      style={{ cursor: "pointer", color: "#495057" }}
                    >
                      <span className="line-item-bolder-gray">
                        {" "}
                        Total Settlements{" "}
                        {paymentSettlementsCollapse === false ? (
                          <i className="bx bx-chevron-down" />
                        ) : (
                          <i className="bx bx-chevron-up" />
                        )}{" "}
                      </span>
                    </Link>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <Link
                      to="#"
                      onClick={collapsePaymentSettlements}
                      style={{ cursor: "pointer", color: "#495057" }}
                    >
                      <span className="line-item-bolder-red">(
                        {
                          dueIncentiveCalculation?.paymentSettlements
                            ?.totalPaymentSettlement
                        }{" "}
                          {dueIncentiveCalculation?.currency})
                      </span>
                    </Link>
                  </td>
                </tr>

                {/* REMANINING INCENTIVES -------------------------------------------------------*/}

                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td
                    colspan={2}
                    style={{ borderBottom: "2px solid black" }}
                  ></td>
                </tr>
                <tr>
                  <td>
                    <span className="line-item-bolder-blue">
                      {" "}
                      REMAINING INCENTIVE{" "}
                    </span>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <span className="line-item-bolder-blue">
                      {dueIncentiveCalculation?.totalRemainingIncentive}{" "}
                      {dueIncentiveCalculation?.currency}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </CardBody>
        </Card>
      </>
    </React.Fragment>
  )
}

export default Summary
