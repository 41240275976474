import {
  GET_CUSTOMER_CONFIGURATIONS,
  GET_CUSTOMER_CONFIGURATIONS_SUCCESS,
  GET_CUSTOMER_CONFIGURATIONS_FAIL,
  GET_CUSTOMER_CONFIGURATION_DETAIL,
  GET_CUSTOMER_CONFIGURATION_DETAIL_SUCCESS,
  GET_CUSTOMER_CONFIGURATION_DETAIL_FAIL,

  GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID,
  GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID_SUCCESS,
  GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID_FAIL,

  CREATE_CUSTOMER_CONFIGURATION,
  CREATE_CUSTOMER_CONFIGURATION_SUCCESS,
  CREATE_CUSTOMER_CONFIGURATION_FAIL,
  RESTART_STATE

} from "./actionTypes"

export const getCustomerConfigurations = () => ({
  type: GET_CUSTOMER_CONFIGURATIONS,
})

export const getCustomerConfigurationsSuccess = configurations => ({
  type: GET_CUSTOMER_CONFIGURATIONS_SUCCESS,
  payload: configurations,
})

export const getCustomerConfigurationsFail = error => ({
  type: GET_CUSTOMER_CONFIGURATIONS_FAIL,
  payload: error,
})

export const getCustomerConfigurationDetail = id => ({
  type: GET_CUSTOMER_CONFIGURATION_DETAIL,
  id,
})

export const getCustomerConfigurationDetailSuccess = configuration => ({
  type: GET_CUSTOMER_CONFIGURATION_DETAIL_SUCCESS,
  payload: configuration,
})

export const getCustomerConfigurationDetailFail = error => ({
  type: GET_CUSTOMER_CONFIGURATION_DETAIL_FAIL,
  payload: error,
})


export const getCustomerConfigurationDetailByCustomerId = id =>({
  type:GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID,
  payload:id
})

export const getCustomerConfigurationDetailByCustomerIdSuccess = configuration =>({
  type:GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID_SUCCESS,
  payload:configuration
})

export const getCustomerConfigurationDetailByCustomerIdFail = error =>({
  type:GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID_FAIL,
  payload:error
})

export const createCustomerConfiguration= configuration => ({
  type: CREATE_CUSTOMER_CONFIGURATION,
  payload: configuration,
})

export const createCustomerConfigurationSuccess = configuration => ({
  type: CREATE_CUSTOMER_CONFIGURATION_SUCCESS,
  payload: configuration,
})

export const createCustomerConfigurationFail = error => ({
  type: CREATE_CUSTOMER_CONFIGURATION_FAIL,
  payload: error,
})

export const restartState = configuration => ({
  type: RESTART_STATE,
  payload: configuration
})

