export const GET_OTHERCHARGES_LIST = "GET_OTHERCHARGES_LIST"
export const GET_OTHERCHARGES_LIST_SUCCESS = "GET_OTHERCHARGES_LIST_SUCCESS"
export const GET_OTHERCHARGES_LIST_FAIL = "GET_OTHERCHARGES_LIST_FAIL"

export const GET_OTHERCHARGES_BY_CUSTOMERID_LIST =
  "GET_OTHERCHARGES_BY_CUSTOMERID_LIST"
export const GET_OTHERCHARGES_BY_CUSTOMERID_LIST_SUCCESS =
  "GET_OTHERCHARGES_BY_CUSTOMERID_LIST_SUCCESS"
export const GET_OTHERCHARGES_BY_CUSTOMERID_LIST_FAIL =
  "GET_OTHERCHARGES_BY_CUSTOMERID_LIST_FAIL"

export const GET_OTHERCHARGES_DETAIL = "GET_OTHERCHARGES_DETAIL"
export const GET_OTHERCHARGES_DETAIL_SUCCESS = "GET_OTHERCHARGES_DETAIL_SUCCESS"
export const GET_OTHERCHARGES_DETAIL_FAIL = "GET_OTHERCHARGES_DETAIL_FAIL"

export const SET_OTHERCHARGES = "SET_OTHERCHARGES"
export const SET_OTHERCHARGES_SUCCESS = "SET_OTHERCHARGES_SUCCESS"
export const SET_OTHERCHARGES_FAIL = "SET_OTHERCHARGES_FAIL"

export const UPDATE_OTHERCHARGES = "UPDATE_OTHERCHARGES"
export const UPDATE_OTHERCHARGES_SUCCESS = "UPDATE_OTHERCHARGES_SUCCESS"
export const UPDATE_OTHERCHARGES_FAIL = "UPDATE_OTHERCHARGES_FAIL"

export const DELETE_OTHERCHARGES = "DELETE_OTHERCHARGES"
export const DELETE_OTHERCHARGES_SUCCESS = "DELETE_OTHERCHARGES_SUCCESS"
export const DELETE_OTHERCHARGES_FAIL = "DELETE_OTHERCHARGES_FAIL"

export const RESTART_STATE = "RESTART_STATE"
