/* INVOICES */
export const GET_CUSTOMER_CONFIGURATIONS = "GET_CUSTOMER_CONFIGURATIONS"
export const GET_CUSTOMER_CONFIGURATIONS_SUCCESS = "GET_CUSTOMER_CONFIGURATIONS_SUCCESS"
export const GET_CUSTOMER_CONFIGURATIONS_FAIL = "GET_CUSTOMER_CONFIGURATIONS_FAIL"

export const GET_CUSTOMER_CONFIGURATION_DETAIL = "GET_CUSTOMER_CONFIGURATION_DETAIL"
export const GET_CUSTOMER_CONFIGURATION_DETAIL_SUCCESS = "GET_CUSTOMER_CONFIGURATION_DETAIL_SUCCESS"
export const GET_CUSTOMER_CONFIGURATION_DETAIL_FAIL = "GET_CUSTOMER_CONFIGURATION_DETAIL_FAIL"


export const GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID="GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID"
export const GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID_SUCCESS="GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID_SUCCESS"
export const GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID_FAIL="GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID_FAIL"

export const CREATE_CUSTOMER_CONFIGURATION = "CREATE_CUSTOMER_CONFIGURATION"
export const CREATE_CUSTOMER_CONFIGURATION_SUCCESS = "CREATE_CUSTOMER_CONFIGURATION_SUCCESS"
export const CREATE_CUSTOMER_CONFIGURATION_FAIL = "CREATE_CUSTOMER_CONFIGURATION_FAIL"

export const RESTART_STATE="RESTART_STATE"

