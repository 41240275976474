import React from 'react'
import moment from 'moment'

export const  dateFormat = (date) => {
    return (
        <div>
            {moment(date).format("MMM YY")}{" "}
        </div>
    )
}

export const  dateFormatMMMYYYY = (date) => {
    return (
        <>
            {moment(date).format("MMM YYYY")}{" "}
        </>
    )
}

export const  dateFormatDDMMMYYYY = (date) => {
    return (
        <>
            {moment(date).format("DD MMM YYYY")}{" "}
        </>
    )
}

export const  dateFormatString = (date) => {

    return moment(date).format("MMM YY")
        
}


export const dateISO = (date) =>{

    return moment(date).format()
}



export const date =(date) => {

    return moment(date).format('MMMM Do YYYY, h:mm:ss a')
}



export const utcToLocal = (dateInUTC) => {

    return moment(dateInUTC).local().format()
}

export const localToUtc = (dateLocal) => {

   return moment.utc(dateLocal).toDate();
}


export const utcOffset = (localdate) =>{

    // return moment.utc(date).toDate();
    let utcTime = moment.utc(localdate).toDate();
    // var utcText = moment(utcTime).format("L LT");

    // // First way
     var offset = moment().utcOffset();
     return  moment.utc(utcTime).local(true).add(12, 'hours').format()

}