import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMER_DETAIL,
  GET_CUSTOMER_DETAIL_SUCCESS,
  GET_CUSTOMER_DETAIL_FAIL,
  GET_CUSTOMER_OFFICES,
  GET_CUSTOMER_OFFICES_SUCCESS,
  GET_CUSTOMER_OFFICES_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  customersList: [],
  officeList: [],
  customerDetail: {},
  error: {},
  loading: true,
}

const Customers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customersList: action.payload,
      }

    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CUSTOMER_OFFICES_SUCCESS:
      return {
        ...state,
        officeList: action.payload,
        loading: false,
      }

    case GET_CUSTOMER_OFFICES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_CUSTOMER_DETAIL_SUCCESS:
      return {
        ...state,
        customerDetail: action.payload,
        loading: false,
      }

    case GET_CUSTOMER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Customers
