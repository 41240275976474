import React, { useState, useEffect } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Table,
} from "reactstrap"
import { map, isEmpty } from "lodash"
import { dateFormat, dateFormatMMMYYYY } from "../../../helpers/commonhelpers/dateFormat"
import ReactHTMLTableToExcel from "react-html-table-to-excel"

const Segments = props => {
  const { custConfig, isCustomer, products } = props

  const [fileName,setFileName] = useState()




  useEffect(() => {
    var filename = custConfig?.businessName + "-" + products?.productName
    setFileName(filename)

  }, [custConfig, products])
  
  function GetOverAllTotal(transactionalArr) {
    var sum = 0
    transactionalArr.forEach(n => (sum += n.rowTotal))

    return sum
  }

  function GetOverAllCount(transactionalArr) {
    var sum = 0
    transactionalArr.forEach(n => (sum += n.rowCount))

    return sum
  }

  return (
    <>
      {products && (
        <Card>
          <CardBody style={{ minHeight: "635px" }}>
           
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button download-button"
              table="segments"
              filename={fileName}
              sheet={products.productName}
              buttonText={<p style={{
                fontSize: "15px",
                color: "green",
                textAlign: "right",
              }}><i className="fas fa-file-excel mr-1" />
              Download this report</p>}
            />

            <Table className="table table-bordered" id="segments">

              <tr>
                <td colSpan={3}>
                <p>{custConfig.businessName}</p><br></br>
                  
                  <CardTitle className="mb-4" style={{ color: "blue" }}>
                  {products.productName === "Selling Platform Connect" ? "UID Usage" :products.productName }
                  <br></br>
                  <small style={{ color: "gray" }}>MONTHLY DETAILS</small>   <br></br>
                  <small style={{ color: "gray" }}><b>{dateFormatMMMYYYY(products.coveredPeriodFxRate[0].date)} - {dateFormatMMMYYYY(products.coveredPeriodFxRate[products.coveredPeriodFxRate.length-1].date)}</b></small><br></br>
                
                  
                </CardTitle></td>
              </tr>
              <tr>
                <td width="15%">
                  {" "}
                  <small>DATE</small>
                </td>

                {map(products.coveredPeriodFxRate, (date, index) => (
                  <td
                    maxWidth="120px"
                    minWidth="120px"
                    style={{ textAlign: "right" }}
                    key={index}
                  >
                    {" "}
                    <small>
                      <b>{dateFormat(date.date)}</b>
                    </small>
                  </td>
                ))}

                <td width="10%" style={{ color: "green", textAlign: "right" }}>
                  {" "}
                  <small>
                    <b>TOTAL</b>
                  </small>
                </td>
              </tr>

               {/*--------------------- AMC */}
            {products.billingOptionsList.amc && (
              <>
                <tr>
                <td colSpan={products.coveredPeriodFxRate.length + 1}>
                      <div className="d-flex justify-content-start">
                    <small>
                      <b style={{ color: "blue" }}>AMC </b>
                    </small>
                    <small>
                      <b>
                        {" "}
                        <small>
                          {" "}
                          ({products.billingOptionsList.amc.price}{" "}
                          {products.billingOptionsList.amc.priceCurrency} /
                          YEARLY)
                        </small>
                      </b>
                    </small>
                    </div>
                  </td>
<td>
                  <div className="d-flex justify-content-end">
                    <small style={{ color: "green", textAlign: "right" }}>
                      <b> {products.billingOptionsList.amc.rowTotal} QAR </b>
                    </small>
                  </div>
                  </td>
                </tr>

               
                  <tr>
                    <td width="115px">
                      {" "}
                      <small></small>
                    </td>
                    {map(
                      products.billingOptionsList.amc.monthlyDetail,
                      (r, index) => (
                        <td style={{ textAlign: "right" }} key={index}>
                          <p>
                            <small>
                              <b>
                                {r.total} {r.currency}
                              </b>
                            </small>
                          </p>
                        </td>
                      )
                    )}

                    <td
                      width="115px"
                      style={{ textAlign: "right", color: "blue" }}
                    >
                      <small>
                        {" "}
                        <b> {products.billingOptionsList.amc.rowTotal} QAR</b>
                      </small>
                    </td>
                  </tr>
            
              </>
            )}

{/*------------------------ TRANSACTIONAL */}
              {products.billingOptionsList.transactional && (
                <>
                  
                  <tr>
                    <td colSpan={products.coveredPeriodFxRate.length + 1}>
                      <div className="d-flex justify-content-start">
                        <small>
                          <b style={{ color: "blue" }}>TRANSACTIONAL </b>
                        </small>

                        <small>
                          <b>
                            {" "}
                            <small>
                              {" "}
                              {
                                products.billingOptionsList.transactional[0]
                                  .remarks
                              }
                            </small>
                          </b>
                        </small>
                      </div>
                     </td><td>
                      <div className="d-flex justify-content-end">
                        <small style={{ color: "green", textAlign: "right" }}>
                          <b>
                            {" "}
                            {GetOverAllCount(
                              products.billingOptionsList.transactional
                            )}{" "}
                          </b>
                        </small>

                        <small style={{ color: "green", textAlign: "right" }}>
                          {/* <b>
                        {" "}
                        -{" "}
                        {GetOverAllTotal(
                          products.billingOptionsList.transactional
                        )}{" "}
                      </b> */}
                        </small>
                      </div>
                    </td>
                  </tr>

                  <tr style={{ textAlign: "right" }}></tr>

                  {map(
                    products.billingOptionsList.transactional,
                    (r, index) => (
                      <tr key={index}>
                        <td width="15%">
                          <p>
                            <small>
                              <b>{r.officeId}</b>
                            </small>
                            {/* <small><b>{r.remarks}</b></small> */}
                          </p>
                        </td>

                        {map(r.monthlyDetail, (m, index) => (
                          <>
                            {m.count > 0 ? (
                              <td
                                width="120px"
                                style={{ textAlign: "right" }}
                                key={index}
                              >
                                <p>
                                  <small style={{ color: "blue" }}>
                                    {m.count}
                                  </small>
                                  <br></br>
                                </p>
                              </td>
                            ) : (
                              <td
                                width="120px"
                                style={{ textAlign: "right" }}
                                key={index}
                              >
                                <p>
                                  <small>{m.count}</small>
                                  <br></br>
                                </p>
                              </td>
                            )}
                          </>
                        ))}
                        <td
                          style={{ textAlign: "right", color: "blue" }}
                          width="10%"
                        >
                          <p>
                            <small>
                              <b>{r.rowCount}</b>
                            </small>
                            <br></br>
                          </p>
                        </td>
                      </tr>
                    )
                  )}
                </>
              )}
            </Table>

           
           
          </CardBody>
        </Card>
      )}
    </>
  )
}

export default Segments
