import React, { useState, useEffect } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormGroup,
  Collapse,
  // Offcanvas,
  // OffcanvasHeader,
  // OffcanvasBody,
} from "reactstrap"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { dateFormatString } from "../../helpers/commonhelpers/dateFormat"
import moment from "moment"
import { map, isEmpty } from "lodash"
import { Link } from "react-router-dom"
import ConfigurationDetails from "./ConfigurationDetails"
import { utcOffset } from "../../helpers/commonhelpers/dateFormat"

const animatedComponents = makeAnimated()

const optionGroup2 = [
  {
    label: "Months",
    options: [
      { label: "January", value: "January" },
      { label: "February", value: "February" },
      { label: "March", value: "March" },
      { label: "April", value: "April" },
      { label: "May", value: "May" },
      { label: "June", value: "June" },
      { label: "July", value: "July" },
      { label: "August", value: "August" },
      { label: "September", value: "September" },
      { label: "October", value: "October" },
      { label: "November", value: "November" },
      { label: "December", value: "December" },
    ],
  },
]

const optionGroup2Year = [
  {
    label: "Years",
    options: [
      { label: "2021", value: "2021" },
      { label: "2020", value: "2020" },
      { label: "2019", value: "2019" },
      { label: "2018", value: "2018" },
      { label: "2017", value: "2017" },
      { label: "2016", value: "2016" },
      { label: "2015", value: "2015" },
    ],
  },
]

const multi = {
  filtered: [],
  select2: undefined,
}

const multiyear = {
  filtered: [],
  select2: undefined,
}

const Filters = props => {
  const {
    custConfig,
    handleShowIncetiveDueCalculation,
    handleViewAsCustomer,
    isCustomer,
  } = props
  const [customerConfigurationDetail, setCustomerConfigurationDetail] =
    useState(custConfig)
  const [selectedAgreement, setSelectedAgreement] = useState(
    custConfig?.agreements[0]
  )
  const [selectedAgreementId, setSelectedAgreementId] = useState("")
  const [selectedAgreementEffectiveDate, setSelectedAgreementEffectiveDate] =
    useState("")
  const [selectedAgreementEndDate, setSelectedAgreementEndDate] = useState("")
  const [selectedMultiSelect, setselectedMultiSelect] = useState(multi)
  const [selectedMultiSelectYear, setselectedMultiSelectYear] =
    useState(multiyear)
  const [selectedDateRange, setSelectedDateRange] = useState(null)
  const [showSelectedAgreementDetails, setShowSelectedAgreementDetails] = useState(false)
  const [selectedFiscalYear, setSelectedFiscalYear] = useState(0)
  const [startDate, setStartDate] = useState(new Date(selectedAgreement?.fiscalYearsDates[0].from) )
  const [endDate, setEndDate] = useState( new Date(selectedAgreement?.fiscalYearsDates[0].to) )
  const [fiscalDateStart, setFiscalDateStart] = useState(new Date(selectedAgreement?.fiscalYearsDates[0].from) )
  const [fiscalDateEnd, setFiscalDateEnd] = useState( new Date(selectedAgreement?.fiscalYearsDates[0].to) )
  const [dateRange, setDateRange] = useState(null)

  useEffect(() => {
    if (custConfig) {
      setCustomerConfigurationDetail(custConfig)
      if (custConfig.agreements) {
        setSelectedAgreementId(custConfig?.agreements[0].agreementId)
      }
    }
  }, [custConfig])

  useEffect(() => {
    var agreementSelected = customerConfigurationDetail?.agreements.filter(
      x => x.agreementId == selectedAgreementId
    )[0]
    setSelectedAgreement(agreementSelected)
    setSelectedFiscalYear(0)

    // setStartDate(agreementSelected.fiscalYearsDates[0].from)
    // setEndDate(agreementSelected.fiscalYearsDates[0].to)
    // setSelectedAgreementEffectiveDate(moment(agreementSelected?.effectiveDate).format("YYYY-MM"))
    // setSelectedAgreementEndDate(moment(agreementSelected?.endDate).format("YYYY-MM-31"))
  }, [selectedAgreementId])

  useEffect(() => {
    var dates = new Array()
    dates.push(startDate)
    dates.push(endDate)
    setDateRange(dates)

    console.log(dates)
  }, [startDate, endDate])

  useEffect(() => {

    fillDates()
    setFiscalDateStart(new Date(selectedAgreement?.fiscalYearsDates[selectedFiscalYear].from))
    setFiscalDateEnd(new Date(selectedAgreement?.fiscalYearsDates[selectedFiscalYear].to))

  }, [selectedAgreement, selectedFiscalYear])



  useEffect(() => {}, [
    selectedAgreementEffectiveDate,
    selectedAgreementEndDate,
  ])

  const fillDates = () => {
    if (selectedAgreement) {
      var selectedFiscalDates =
        selectedAgreement?.fiscalYearsDates[selectedFiscalYear]
      if (selectedFiscalDates) {
        // setSelectedAgreementEffectiveDate(
        //   moment(selectedFiscalDates.from).format("YYYY-MM")
        // )
        // setSelectedAgreementEndDate(
        //   moment(selectedFiscalDates.to).format("YYYY-MM-30")
        // )

        setStartDate(new Date(selectedFiscalDates.from))
        setEndDate(new Date(selectedFiscalDates.to))
      }
    }
  }

  const onChange = e => {
    console.log("selected value:", e.target.value)
  }

  const getDropList = () => {
    const year = new Date().getFullYear()
    return Array.from(new Array(15), (v, i) => (
      <option key={i} value={year - i}>
        {year - i}
      </option>
    ))
  }

  const showSelectedAgreement = () => {
    setShowSelectedAgreementDetails(!showSelectedAgreementDetails)
  }

  const getSelectedDateRange = selectedDates => {
    setSelectedDateRange(selectedDates)
  }

  const handleClearFilter = () => {
    setSelectedDateRange(null)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col lg="3">
              <label htmlFor="floatingSelectGrid">Agreements</label>
              <select
               
                className="form-control"
                onChange={e => setSelectedAgreementId(e.target.value)}
              >
                {customerConfigurationDetail?.agreements?.map((a, i) => (
                  <option key={i} value={a.agreementId}>
                    {a.agreementId} (
                    {moment(a.effectiveDate).format("MMM YYYY")} -{" "}
                    {moment(a.endDate).format("MMM YYYY")}){" "}
                  </option>
                ))}
              </select>
            </Col>

            <Col lg="3">
              <label htmlFor="floatingSelectGrid">Fiscal Years</label>
              <select
                className="form-control"
                onChange={e => setSelectedFiscalYear(e.target.value)}
              >
                {selectedAgreement?.fiscalYearsDates.map((a, i) => (
                  <option key={i} value={i}>
                    Year {i + 1} - {moment(a.from).format("MMM YYYY")} -{" "}
                    {moment(a.to).format("MMM YYYY")}{" "}
                  </option>
                ))}
              </select>

              {/* <FormGroup className="mb-0 templating-select select2-container">
                                <label htmlFor="floatingSelectGrid"> Years</label>
                                <Select
                                    name="multiSelectYear"
                                    id="multiSelectYear"
                                    value={selectedMultiSelectYear.select2}
                                    isMulti={true}
                                    // ref={register({ required: true })}
                                    onChange={(entry) => {

                                        if (entry) {
                                            setselectedMultiSelectYear({ select2: entry })
                                            //  entry.map(o => {return onFilteredChangeCustom(o.value,o.label)})
                                            onFilteredChangeCustomYear(
                                                entry.map(o => { return o.value }), "entry"
                                            );
                                        }

                                    }}
                                    options={optionGroup2Year}
                                    // options={selectedMultiSelect.data.map((o, i) => {
                                    //   return { id: i, value: o.firstName, label: o.firstName };
                                    // })}
                                    classNamePrefix="multiSelectYear"
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                />
                            </FormGroup> */}
            </Col>

            <Col lg="3">
              <Label>Date Range</Label>
              <InputGroup>
                <div className="d-flex flex-row">
                  <div className="mr-2">
                    <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="MMM - yyyy"
                      showMonthYearPicker
                      className="react-datepicker-input"
                      minDate={fiscalDateStart}
                      maxDate={fiscalDateEnd}
                    />
                  </div>
                  <div>{" - "}</div>
                  <div className="ml-2">
                    {" "}
                    <DatePicker
                      selected={endDate}
                      onChange={date => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="MMM - yyyy"
                      showMonthYearPicker
                      className="react-datepicker-input"
                      minDate={fiscalDateStart}
                      maxDate={fiscalDateEnd}
                    />
                  </div>
                </div>
              </InputGroup>
            </Col>

            {/* <Col lg="3"> */}
            {/* <FormGroup className="mb-4">
                <Label>Date Range</Label>
                <InputGroup>
                  <Flatpickr
                    className="form-control d-block"
                    placeholder="Select Period"
                    onChange={selectedDates =>
                      getSelectedDateRange(selectedDates)
                    }
                    value={selectedDateRange}
                    options={{
                      mode: "range",
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                      minDate: selectedAgreementEffectiveDate, //  within range of contract
                      maxDate: selectedAgreementEndDate, //  within range of contract
                      // disable: [
                      //     function(date) {
                      //         // disable every multiple of 8
                      //         return !(date.getDate() % 8);
                      //     }
                      // ]
                    }}
                  />
                </InputGroup>
              </FormGroup> */}

            {/* <FormGroup className="mb-0 templating-select select2-container">
                                <label htmlFor="floatingSelectGrid"> Months</label>
                                <Select
                                    name="multiSelect"
                                    id="multiSelect"
                                    value={selectedMultiSelect.select2}
                                    isMulti={true}
                                    // ref={register({ required: true })}
                                    onChange={(entry) => {

                                        if (entry) {
                                            setselectedMultiSelect({ select2: entry })
                                            //  entry.map(o => {return onFilteredChangeCustom(o.value,o.label)})
                                            onFilteredChangeCustom(
                                                entry.map(o => { return o.value }), "entry"
                                            );
                                        }

                                    }}
                                    options={optionGroup2}
                                    // options={selectedMultiSelect.data.map((o, i) => {
                                    //   return { id: i, value: o.firstName, label: o.firstName };
                                    // })}
                                    classNamePrefix="multiSelect"
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                />
                            </FormGroup> */}
            {/* </Col> */}
            <Col lg="2">
              <Row>
                {" "}
                <label className="mb-4"></label>
              </Row>

              <Row>
                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    className="btn btn-success mr-2"
                    color="success"
                    onClick={() =>
                      handleShowIncetiveDueCalculation(
                        // selectedDateRange,
                        dateRange,
                        selectedAgreementId,
                        selectedFiscalYear
                      )
                    }
                  >
                    <i className="bx bx-calculator" /> Show Calculation
                  </Button>

                  {/* <Button
                    className="btn btn-danger mr-2"
                    onClick={() => handleClearFilter()}
                  >
                    <i className="bx bx-calendar-event " /> Clear Dates
                  </Button> */}
                </div>
              </Row>
            </Col>
            <Col lg="1">
              <Row>
                {" "}
                <label className="mb-2"></label>
              </Row>
              <Row>
                <div className="d-flex justify-content-end align-items-center">
                  {!isCustomer ? (
                    <div className="dropdown d-inline-block">
                      {/* <button
                        className="btn header-item noti-icon right-bar-toggle waves-effect"
                        type="button"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="View as Customer"
                        onClick={() => handleViewAsCustomer(true)}
                      >
                        {" "}
                        <i className="bx bxs-user" style={{color:"blue"}}/>{" "}
                      </button> */}

                      <button
                        onClick={showSelectedAgreement}
                        type="button"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Configuration"
                        className="btn header-item noti-icon right-bar-toggle waves-effect"
                      >
                        <i className="bx bx-cog bx-spin bx-sm" style={{color:"blue"}} />
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className="dropdown d-inline-block">
                        <button
                          color="primary"
                          className="btn header-item noti-icon right-bar-toggle waves-effect"
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="View as Amadeus"
                          onClick={() => handleViewAsCustomer(false)}
                        >
                          {" "}
                          <i className="bx bx-paper-plane" />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Collapse isOpen={showSelectedAgreementDetails}>
                {customerConfigurationDetail && (
                  <Card>
                    <CardBody>
                      <ConfigurationDetails
                        customerConfigurationDetail={
                          customerConfigurationDetail
                        }
                      />
                    </CardBody>
                  </Card>
                )}
              </Collapse>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Filters
