import {
  GET_OTHERCHARGES_LIST,
  GET_OTHERCHARGES_LIST_SUCCESS,
  GET_OTHERCHARGES_LIST_FAIL,
  GET_OTHERCHARGES_BY_CUSTOMERID_LIST,
  GET_OTHERCHARGES_BY_CUSTOMERID_LIST_SUCCESS,
  GET_OTHERCHARGES_BY_CUSTOMERID_LIST_FAIL,
  GET_OTHERCHARGES_DETAIL,
  GET_OTHERCHARGES_DETAIL_SUCCESS,
  GET_OTHERCHARGES_DETAIL_FAIL,
  SET_OTHERCHARGES,
  SET_OTHERCHARGES_SUCCESS,
  SET_OTHERCHARGES_FAIL,
  UPDATE_OTHERCHARGES,
  UPDATE_OTHERCHARGES_SUCCESS,
  UPDATE_OTHERCHARGES_FAIL,
  DELETE_OTHERCHARGES,
  DELETE_OTHERCHARGES_SUCCESS,
  DELETE_OTHERCHARGES_FAIL,
  RESTART_STATE,
} from "./actionTypes"

export const getOtherChargesList = () => ({
  type: GET_OTHERCHARGES_LIST,
})

export const getOtherChargesListSuccess = otherCharges => ({
  type: GET_OTHERCHARGES_LIST_SUCCESS,
  payload: otherCharges,
})

export const getOtherChargesListFail = otherCharges => ({
  type: GET_OTHERCHARGES_LIST_FAIL,
  payload: otherCharges,
})

export const getOtherChargesByCustomerIdList = otherCharges => ({
  type: GET_OTHERCHARGES_BY_CUSTOMERID_LIST,
  payload: otherCharges,
})

export const getOtherChargesByCustomerIdListSuccess = otherCharges => ({
  type: GET_OTHERCHARGES_BY_CUSTOMERID_LIST_SUCCESS,
  payload: otherCharges,
})

export const getOtherChargesByCustomerIdListFail = otherCharges => ({
  type: GET_OTHERCHARGES_BY_CUSTOMERID_LIST_FAIL,
  payload: otherCharges,
})

export const getOtherChargesDetail = otherCharges => ({
  type: GET_OTHERCHARGES_DETAIL,
  payload: otherCharges,
})

export const getOtherChargesDetailSuccess = otherCharges => ({
  type: GET_OTHERCHARGES_DETAIL_SUCCESS,
  payload: otherCharges,
})

export const getOtherChargesDetailFail = otherCharges => ({
  type: GET_OTHERCHARGES_DETAIL_FAIL,
  payload: otherCharges,
})

export const setOtherCharges = otherCharges => ({
  type: SET_OTHERCHARGES,
  payload: otherCharges,
})

export const setOtherChargesSuccess = otherCharges => ({
  type: SET_OTHERCHARGES_SUCCESS,
  payload: otherCharges,
})

export const setOtherChargesFail = otherCharges => ({
  type: SET_OTHERCHARGES_FAIL,
  payload: otherCharges,
})

export const updateOtherCharges = otherCharges => ({
  type: UPDATE_OTHERCHARGES,
  payload: otherCharges,
})

export const updateOtherChargesSuccess = otherCharges => ({
  type: UPDATE_OTHERCHARGES_SUCCESS,
  payload: otherCharges,
})

export const updateOtherChargesFail = otherCharges => ({
  type: UPDATE_OTHERCHARGES_FAIL,
  payload: otherCharges,
})

export const deleteOtherCharges = otherCharges => ({
  type: DELETE_OTHERCHARGES,
  payload: otherCharges,
})

export const deleteOtherChargesSuccess = otherCharges => ({
  type: DELETE_OTHERCHARGES_SUCCESS,
  payload: otherCharges,
})

export const deleteOtherChargesFail = otherCharges => ({
  type: DELETE_OTHERCHARGES_FAIL,
  payload: otherCharges,
})

export const restartState = () => ({
  type: RESTART_STATE,
})
