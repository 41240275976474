//USERS
export const POST_LOGIN = "/users/authenticate"
export const OTP_LOGIN = "/users/authenticate/otp"

//DASHBOARD
export const GET_SUMMARY = "dashboard/summary"
export const SET_SURVEY_ANSWERS = "dashboard/survey"
export const GET_TOP_MERCHANT = "dashboard/merchants"
export const GET_TOP_USERS = "dashboard/users"

//SETTLEMENT
export const GET_SETTLEMENT_LIST = "/settlement"
export const GET_SETTLEMENT_BY_CUSTOMERID = "/settlement/customerid"
export const GET_SETTLEMENT_ID = "/settlement/id"
export const SET_SETTLEMENT = "/settlement"
export const SET_SETTLEMENT_EXCEL = "/settlement/excel"
export const UPDATE_SETTLEMENT = "/settlement/update"
export const DELETE_SETTLEMENT = "/settlement"
export const FILE_UPLOAD = "/settlement/files"

//OTHER CHARGES
export const GET_OTHERCHARGES_LIST = "/othercharges"
export const GET_OTHERCHARGES_LIST_BY_CUSTOMERID = "/othercharges/customerId"
export const GET_OTHERCHARGES_DETAIL = "/othercharges/id"
export const SET_OTHERCHARGES = "/othercharges"
export const UPDATE_OTHERCHARGES = "/othercharges/update"
export const DELETE_OTHERCHARGES = "/othercharges/delete"

//CUSTOMER CONFIGURATIONS
export const GET_CUSTOMER_CONFIGURATIONS = "/customerconfigurations"
export const GET_CUSTOMER_CONFIGURATION_DETAIL = "/customerconfigurations"
export const GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID =
  "/customerconfigurations/customerId"

//GET_CUSTOMERS

export const GET_CUSTOMERS = "/customer"

//GET_CUSTOMER_DETAIL
export const GET_CUSTOMER_DETAIL = "/customer"

//GET_CUSTOMER_OFFICES
export const GET_CUSTOMER_OFFICES = "/customer/offices"

//CREATE_CUSTOMER_CONFIGURATION
export const CREATE_CUSTOMER_CONFIGURATION = "/customerconfigurations/create"

//GET_OPTIONS
export const GET_OPTIONS = "/calculations/options"

//GET_PRODUCT_LIST
export const GET_PRODUCT_LIST = "/calculations/products/list"

//GET_OPTION_DETAIL
export const GET_OPTION_DETAIL = "/calculations/options"

//INCENTIVE DUE CALCULATION

export const GET_INCENTIVE_DUE_CALCULATION = "/calculations/incentivedue"

// GET_TICKETED_SEGMENTS

export const GET_TICKETED_SEGMENTS = "/calculations/products/ticketedsegment"

// GET_UNPAID_AIRLINES

export const GET_UNPAID_AIRLINES = "/calculations/products/unpaidairlines"

//GET_UID_GRAPHIC_COUNT

export const GET_UID_GRAPHIC_COUNT = "/calculations/products/uidUsage"

//GET_ATC_REFUND

export const GET_ATC_REFUND = "/calculations/products/atcrefund"

//GET_ATC_REISSUE

export const GET_ATC_REISSUE = "/calculations/products/atcreissue"

//GET_WEBUETTR
export const GET_WEBUETTR = "/calculations/products/webuettr"

//GET_OFFERS
export const GET_OFFERS = "/calculations/products/offers"

//GET_AIR
export const GET_AIR = "/calculations/products/air"

//GET_IMR
export const GET_IMR = "/calculations/products/imr"

//GET_ADTD
export const GET_ADTD = "/calculations/products/adtd"

//GET_DSR
export const GET_DSR = "/calculations/products/dsr"

//GET_CLC
export const GET_CLC = "/calculations/products/clc"

//GET_B2B_WALLET
export const GET_B2B_WALLET = "/calculations/products/b2bwallet"

//GET_MINIRULES
export const GET_MINIRULES = "/calculations/products/minirules"

//GET_PAYALLLINK
export const GET_PAYALLLINK = "/calculations/products/payalllink"

//GET_MOBILETRAVELLER
export const GET_MOBILETRAVELLER = "/calculations/products/mobiletraveller"

//GET_EPOWER
export const GET_EPOWER = "/calculations/products/epower"

//GET_ALL_FARES_PLUS
export const GET_ALL_FARES_PLUS = "/calculations/products/allfaresplus"

//GET_TOUCHLESS_HX
export const GET_TOUCHLESS_HX = "/calculations/products/touchlessHx"

//GET_ATAN
export const GET_ATAN = "/calculations/products/atan"

//GET_RTS
export const GET_RTS = "/calculations/products/rts"

//GET_AGENCY_INSIGHT
export const GET_AGENCY_INSIGHT = "/calculations/products/agencyinsight"

//GET_PNR_RECALL
export const GET_PNR_RECALL = "/calculations/products/pnrrecall"

//GET_FILE_FINISHING
export const GET_FILE_FINISHING = "/calculations/products/filefinishing"

//GET_QUALITY_MONITOR
export const GET_QUALITY_MONITOR = "/calculations/products/qualitymonitor"

//GET_AOCT
export const GET_AOCT = "/calculations/products/aoct"
