import {
  GET_INCENTIVE_DUE_CALCULATION_SUMMARY,
  GET_INCENTIVE_DUE_CALCULATION_SUMMARY_SUCCESS,
  GET_INCENTIVE_DUE_CALCULATION_SUMMARY_FAIL,
  GET_TICKETED_SEGMENTS,
  GET_TICKETED_SEGMENTS_SUCCESS,
  GET_TICKETED_SEGMENTS_FAIL,
  GET_UNPAID_AIRLINES,
  GET_UNPAID_AIRLINES_SUCCESS,
  GET_UNPAID_AIRLINES_FAIL,
  GET_UID_GRAPHIC_USAGE,
  GET_UID_GRAPHIC_USAGE_SUCCESS,
  GET_UID_GRAPHIC_USAGE_FAIL,
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL

} from "./actionTypes"

export const getIncentiveDueCalCulationSummary = (data) => ({
  type: GET_INCENTIVE_DUE_CALCULATION_SUMMARY,
  payload: data
})

export const getIncentiveDueCalCulationSummarySuccess = dueIncentive => ({
  type: GET_INCENTIVE_DUE_CALCULATION_SUMMARY_SUCCESS,
  payload: dueIncentive,
})

export const getIncentiveDueCalCulationSummaryFail = error => ({
  type: GET_INCENTIVE_DUE_CALCULATION_SUMMARY_FAIL,
  payload: error,
})

export const getTicketedSegments = (data) => ({
  type: GET_TICKETED_SEGMENTS,
  payload: data
})

export const getTicketedSegmentsSuccess = ticketedSegments => ({
  type: GET_TICKETED_SEGMENTS_SUCCESS,
  payload: ticketedSegments,
})

export const getTicketedSegmentsFail = error => ({
  type: GET_TICKETED_SEGMENTS_FAIL,
  payload: error,
})

export const  getUnpaidAirlines = (data) => ({
  type: GET_UNPAID_AIRLINES,
  payload: data
})

export const getUnpaidAirlinesSuccess = unpaidAirlines => ({
  type: GET_UNPAID_AIRLINES_SUCCESS,
  payload: unpaidAirlines,
})

export const getUnpaidAirlinesFail = error => ({
  type: GET_UNPAID_AIRLINES_FAIL,
  payload: error,
})

//products

export const getUidGraphicUsage = (data) => ({
  type: GET_UID_GRAPHIC_USAGE,
  payload: data
})

export const getUidGraphicUsageSuccess = uidUsage => ({
  type: GET_UID_GRAPHIC_USAGE_SUCCESS,
  payload: uidUsage,
})

export const getUidGraphicUsageFail = error => ({
  type: GET_UID_GRAPHIC_USAGE_FAIL,
  payload: error,
})

export const getProducts = (data) => ({
  type: GET_PRODUCTS,
  payload: data
})

export const getProductsSuccess = products => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: products,
})

export const getProductsFail = error => ({
  type: GET_PRODUCTS_FAIL,
  payload: error,
})




