import { saveAs } from "file-saver"
import { LoadingBorderSpinner } from "helpers/commonhelpers/loadingSpinner"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory from "react-bootstrap-table2-filter"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { connect } from "react-redux"
import makeAnimated from "react-select/animated"
import {
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import { deleteSettlement, getSettlementId } from "store/actions"
import AddPopup from "../Popup/Add"
import Excel from "../Popup/Excel"
import UpdatePopup from "../Popup/Update"
import SettlementColumn from "./Columns"
const animatedComponents = makeAnimated()
import AWS from "aws-sdk"
import Add from "../Popup/Add"
import AllowedUsers from "components/AuthUser/AllowedUserList"
import AuthUser from "components/AuthUser/AuthUser"
//const S3 = new AWS.S3()
AWS.config.update({
  region: "ap-south-1",
  accessKeyId: "AKIAXIO2FSJOWAQMEXMM",
  secretAccessKey: "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",
})

const index = props => {
  const [list, setList] = useState([])
  const [modal_large, setModal_Large] = useState(false)
  const [step, setStep] = useState(0)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [selectedSettlement, setSelectedSettlement] = useState()
  const [download, setDownload] = useState(false)

  let form = ""
  let modalHeader = ""
  const { loading, settlementList, onDeleteSettlement } = props
  const user = AuthUser()
  const [allowUser, setAllowUser] = useState(false)
  const allowedUsers = AllowedUsers
  useEffect(() => {
    //console.log(allowedUsers)
    allowedUsers.map((users, i) => {
      if (user.email === users) {
        setAllowUser(true)
      }
    })
  }, [user, allowedUsers])

  useEffect(() => {}, [allowUser])
  useEffect(() => {
    if (settlementList) {
      setList(settlementList)
    }
  }, [settlementList])

  useEffect(() => {
    //console.log(step)
  }, [step])

  useEffect(() => {}, [list])

  useEffect(() => {}, [download])
  useEffect(() => {
    if (download) {
      let fileURL =
        `https://ms-incentive-assets.s3.ap-south-1.amazonaws.com/` +
        selectedSettlement.file

      const s3 = new AWS.S3()

      const params = {
        Bucket: "ms-incentive-assets",
        Key: selectedSettlement.file,
      }

      s3.getObject(params, (err, data) => {
        if (data) {
          let blob = new Blob([data.Body], { type: data.ContentType })
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fileURL
          link.click()
        }

        //console.log(error)
      })
    }
    setDownload(false)
  }, [selectedSettlement, download])

  useEffect(() => {
    if (modal_large === false) {
      setStep(0)
    }
  }, [modal_large])

  const pageOptions = {
    sizePerPage: 50,
    totalSize: list?.length,
    custom: true,
    withFirstAndLast: true,
  }
  const { SearchBar } = Search

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (row) {
        setSelectedSettlement(row)
      }
    },
  }

  //search
  const handleTableChange = searchText => {
    if (searchText !== "" && searchText !== undefined) {
      setList(
        list.filter(lst =>
          Object.keys(lst).some(key =>
            lst[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    }
  }

  const openModal = () => {
    setModal_Large(true)
  }

  const handleActionUpdate = modal => {
    //if(selectedSettlement){
    //console.log(selectedSettlement._id)
    setStep(1)
    setModal_Large(modal)
    // }
  }

  const handleActionDelete = value => {
    if (value) {
      setconfirm_alert(true)
    }
  }

  const handleDownload = () => {
    //console.log("click")
    setDownload(true)
  }

  const handleDeleteSettlment = () => {
    if (selectedSettlement) {
      //console.log(selectedSettlement._id)
      onDeleteSettlement(selectedSettlement._id)
      setconfirm_alert(false)
    }
  }

  switch (step) {
    case 0:
      modalHeader = "ADD NEW PAYMENT SETTLEMENT"
      form = <Add onChange={value => setModal_Large(value)} />
      break
    case 1:
      modalHeader = "UPDATE PAYMENT SETTLEMENT"
      form = (
        <UpdatePopup
          // id={settlementId}
          selectedSettlement={selectedSettlement}
          onChange={value => setModal_Large(value)}
        />
      )

      break
    case 3:
      modalHeader = "ADD PAYMENT SETTLEMENT via EXCEL"
      form = <Excel onChange={value => setModal_Large(value)} />
      break
    default:
      break
  }

  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <Modal size="lg" isOpen={modal_large} centered={true}>
            <div className="modal-header">
              <Label className="my-auto">{modalHeader}</Label>
              <h5
                className="modal-title mt-0 color-danger"
                id="myLargeModalLabel"
              ></h5>
              <button
                onClick={() => {
                  setModal_Large(false)
                }}
                type="button"
                color="danger"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-window-close " style={{ color: "red" }} />
              </button>
            </div>
            <ModalBody>
              {loading ? (
                <Row className="spinner-custom mx-auto my-auto ">
                  <LoadingBorderSpinner />
                </Row>
              ) : (
                form
              )}
            </ModalBody>
          </Modal>
          {confirm_alert ? (
            <SweetAlert
              title="Delete Settlement"
              warning
              showCancel
              confirmButtonText="Confirm"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                handleDeleteSettlment()
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              Delete the selected settlement.
            </SweetAlert>
          ) : null}
          <Card>
            <CardBody>
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={list || []}
                    columns={SettlementColumn(
                      handleActionUpdate,
                      handleActionDelete,
                      handleDownload,
                      allowUser
                    )}
                    loading={true}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="6">
                            <div className="search-box mr-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar
                                  {...toolkitProps.searchProps}
                                  style={{ width: "30rem" }}
                                  placeholder="Enter Keyword"
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          {allowUser ? (
                            <Col sm="6">
                              <div className="text-sm-right">
                                <Button
                                  type="button"
                                  color="info"
                                  onClick={() => {
                                    setStep(3)
                                    setModal_Large(true)
                                  }}
                                  className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                  <i className="mdi mdi-plus mr-1" />
                                  Add Payment Settlement via Excel
                                </Button>
                                <Button
                                  type="button"
                                  color="success"
                                  onClick={openModal}
                                  className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                  <i className="mdi mdi-plus mr-1" />
                                  New Payment Settlement
                                </Button>
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                responsive
                                // remote={ {
                                //   filter: true, // not working
                                //   pagination: true, //not working
                                //   sort: true, // not working
                                //   cellEdit: false
                                // } }
                                loading={true}
                                bordered={false}
                                hover={true}
                                striped={false}
                                classes={"table table-centered table-nowrap"}
                                keyField="_id"
                                headerWrapperClasses={"thead-light"}
                                onTableChange={handleTableChange}
                                rowEvents={rowEvents}
                                // selectRow={selectRow}
                                filter={filterFactory()}
                                // defaultSorted={defaultSorted}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
                        <Col xs="12">
                          <div className="text-center my-3">
                            <Link to="#" className="text-success">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2" />
                                  Load more
                                </Link>
                          </div>
                        </Col>
                      </Row> */}

                        <Row className="align-items-md-center mt-30">
                          <Col xs="6">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </Col>

                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    loading: state.Settlement.loading,
    settlementDelete: state.Settlement.settlementDelete,
  }
}

const mapDispatchToProps = dispatch => ({
  //   onGetCustomerConfigurations :()=> dispatch(getCustomerConfigurations())
  onGetSettlementId: id => dispatch(getSettlementId(id)),
  onDeleteSettlement: id => dispatch(deleteSettlement(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(index)
