import classnames from "classnames"
import "flatpickr/dist/themes/material_blue.css"
import React, { useState, useEffect } from "react"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom"
import {
  Button,
  CardText,
  Col,
  Collapse,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { v4 as uuidv4 } from "uuid"
import { isEmpty } from "lodash"
import SweetAlert from "react-bootstrap-sweetalert"
import { Content } from "../Agreement/Content"
import { IncentiveSlab } from "../Agreement/IncentiveSlab"
import OtherCharges from "../Agreement/OtherCharges"
import { ProductsSolutions } from "../Agreement/ProductsSolutions"
import { useForm } from "react-hook-form"
import AgreementList from "../Agreement/AgreementList"

export const Agreement_2 = props => {
  const {
    customerConfigDetails,
    optionsList,
    productList,
    otherCharges,
    handleAgreementChange,
    handleOtherChargesListChange,
    handleAgreementDelete,
    toggleTab,
    activeTab,
  } = props

  //TODO: Calcualte fiscal years
  const { register, handleSubmit, errors, reset, watch } = useForm()
  const [proceed, setProceeed] = useState(false)

  useEffect(() => {
    setProceeed(proceed)
  }, [proceed])

  const onSubmitAggreement = data => handleCheckNoErrors()

  function handleCheckNoErrors() {
    if (isEmpty(errors)) {
      if (
        isEmpty(otherCharges.OtherCharges) ||
        otherCharges.OtherCharges[0].ChargeName === ""
      ) {
        setProceeed(true)
      } else {
        handleProceed()
      }
    }
  }

  function handleProceed() {
    toggleTab(activeTab + 1)
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit(onSubmitAggreement)} id="my-form">
        {customerConfigDetails.Agreements.map((agreement, index) => (
          <AgreementList
            key={agreement.id}
            index={index}
            agreementId={agreement.id}
            customerId={customerConfigDetails.CustomerId}
            agreement={agreement}
            optionsList={optionsList}
            productList={productList}
            otherCharges={otherCharges}
            handleOtherChargesListChange={handleOtherChargesListChange}
            handleAgreementChange={handleAgreementChange}
            handleAgreementDelete={handleAgreementDelete}
            toggleTab={toggleTab}
            activeTab={activeTab}
            handleSubmit={handleSubmit}
            onSubmit={onSubmitAggreement}
            register={register}
            errors={errors}
          />
        ))}

        <div className="d-flex justify-content-end">
          <ul
            className="pager wizard twitter-bs-wizard-pager-link"
            style={{ listStyleType: "none" }}
          >
            <li
              className={
                activeTab === 1 ? "previous disabled mr-4" : "previous mr-4"
              }
              style={{ display: "inline-block" }}
            >
              <Button
                color="primary"
                onClick={() => {
                  toggleTab(activeTab - 1)
                }}
              >
                Previous
              </Button>
            </li>
            <li
              className={activeTab === 3 ? "next disabled" : "next"}
              style={{ display: "inline-block" }}
            >
              <Button type="submit" color="primary" id="agreementSubmit">
                {" "}
                Next{" "}
              </Button>
            </li>
          </ul>
        </div>

        {proceed ? (
          <SweetAlert
            title="No other charges added, are you sure you want to proceed?"
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setProceeed(false), handleProceed()
            }}
            onCancel={() => {
              setProceeed(false)
            }}
          ></SweetAlert>
        ) : null}
      </Form>
    </React.Fragment>
  )
}


