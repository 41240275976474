import { call, put, takeEvery } from "redux-saga/effects"
import { productCodes } from '../../helpers/commonhelpers/productCodes'
import {
  GET_INCENTIVE_DUE_CALCULATION_SUMMARY,
  GET_TICKETED_SEGMENTS,
  GET_UNPAID_AIRLINES,
  GET_UID_GRAPHIC_USAGE,
  GET_PRODUCTS
} from "./actionTypes"
import {
  getIncentiveDueCalCulationSummarySuccess,
  getIncentiveDueCalCulationSummaryFail,
  getTicketedSegmentsSuccess,
  getUnpaidAirlinesSuccess,
  getUnpaidAirlinesFail,
  getTicketedSegmentsFail,
  getUidGraphicUsageSuccess,
  getUidGraphicUsageFail,
  getProductsSuccess,
  getProductsFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getIncentiveDueCalculation,
  getTicketedSegments,
  getUnpaidAirlines,
  getAtcRefund,
  getAtcReissue,
  getUidGraphics,
  getWebUettr,
  getOffers,
  getAdtd,
  getDsr,
  getClc,
  getPayAllLink,
  getMiniRules,
  getMobileTraveller,
  getEpower,
  getAir,
  getImr,
  getAllFaresPlus,
  getTouchlessHx,
  getB2bWallet,
  getAtan,
  getRts,
  getAgencyInsight,
  getPnrRecall,
  getFileFinishing,
  getQualityMonitor,
  getAoct
} from "helpers/fakebackend_helper"


function* _getIncentiveDueCalculation({ payload: data }) {
  try {

    const response = yield call(getIncentiveDueCalculation, data)
    yield put(getIncentiveDueCalCulationSummarySuccess(response))
  } catch (error) {
    yield put(getIncentiveDueCalCulationSummaryFail(error))
  }
}

function* _getTicketedSegments({ payload: data }) {
  try {

    const response = yield call(getTicketedSegments, data)
    yield put(getTicketedSegmentsSuccess(response))
  } catch (error) {
    yield put(getTicketedSegmentsFail(error))
  }
}

function* _getUnpaidAirlines({ payload: data }) {
  try {
    const response = yield call(getUnpaidAirlines, data)
    yield put(getUnpaidAirlinesSuccess(response))
  } catch (error) {
    yield put(getUnpaidAirlinesFail(error))
  }
}

function* _getUidGraphicCounts({ payload: data }) {
  try {

    const response = yield call(getUidGraphics, data)
    yield put(getUidGraphicUsageSuccess(response))
  } catch (error) {
    yield put(getUidGraphicUsageFail(error))
  }
}

function* _getProducts({ payload: data }) {
  try {

    console.log(data)
    var response = {}
    if (data?.productCode === productCodes.AtcRefund) {
      response = yield call(getAtcRefund, data)
    }

    if (data?.productCode === productCodes.AtcReissue) {
      response = yield call(getAtcReissue, data)
    }

    
    if (data?.productCode === productCodes.WebUETTR) {
      response = yield call(getWebUettr, data)
    }

    if (data?.productCode === productCodes.AmadeusSellingPlatformConnect) {
      response = yield call(getUidGraphics, data)
    }

    if (data?.productCode === productCodes.AmadeusOffers) {
      response = yield call(getOffers, data)
    }

    if (data?.productCode === productCodes.SecondaryAIR) {
      response = yield call(getAir, data)
    }

    if (data?.productCode === productCodes.IMR) {
      response = yield call(getImr, data)
    }

    if (data?.productCode === productCodes.AllFares) {
      response = yield call(getAllFaresPlus, data)
    }


    if (data?.productCode === productCodes.AmadeusTouchlessHX) {
      response = yield call(getTouchlessHx, data)
    }

    if (data?.productCode === productCodes.AmadeusTravelAlertsNotifier) {
      response = yield call(getAtan, data)
    }

    if (data?.productCode === productCodes.RTS) {
      response = yield call(getRts, data)
    }

    if (data?.productCode === productCodes.AgencyInsight) {
      response = yield call(getAgencyInsight, data)
    }

    if (data?.productCode === productCodes.PNRRecall) {
      response = yield call(getPnrRecall, data)
    }

    if (data?.productCode === productCodes.FileFinishing) {
      response = yield call(getFileFinishing, data)
    }

    if (data?.productCode === productCodes.QualityMonitor) {
      response = yield call(getQualityMonitor, data)
    }

    if (data?.productCode === productCodes.AmadeusOnlineCorporateTraveller) {
      response = yield call(getAoct, data)
    }





    //NO REPORT PRODUCTS

   
    if (data?.productCode === productCodes.AmadeusDynamicTravelDocument) {
      response = yield call(getAdtd, data)
    }

    
    if (data?.productCode === productCodes.DailySalesReport) {
      response = yield call(getDsr, data)
    }


     
    if (data?.productCode === productCodes.AmadeusCreditLimitControl) {
      response = yield call(getClc, data)
    }

      
    if (data?.productCode === productCodes.B2BWallet) {
      response = yield call(getB2bWallet, data)
    }

    if (data?.productCode === productCodes.MiniRules) {
      response = yield call(getMiniRules, data)
    }

    if (data?.productCode === productCodes.PayAllLink) {
      response = yield call(getPayAllLink, data)
    }

    if (data?.productCode === productCodes.MobileTraveller) {
      response = yield call(getMobileTraveller, data)
    }


    if (data?.productCode === productCodes.ePowerStarter) {
      response = yield call(getEpower, data)
    }





    yield put(getProductsSuccess(response))

  } catch (error) {
    yield put(getProductsFail(error))
  }
}


function* incentiveDueCalcualtionSaga() {
  yield takeEvery(GET_INCENTIVE_DUE_CALCULATION_SUMMARY, _getIncentiveDueCalculation)
  yield takeEvery(GET_TICKETED_SEGMENTS, _getTicketedSegments)
  yield takeEvery(GET_UNPAID_AIRLINES, _getUnpaidAirlines)
  yield takeEvery(GET_UID_GRAPHIC_USAGE, _getUidGraphicCounts)
  yield takeEvery(GET_PRODUCTS, _getProducts)
  
}

export default incentiveDueCalcualtionSaga
