import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_SETTLEMENT_LIST,
  GET_SETTLEMENT_BY_CUSTOMERID,
  GET_SETTLEMENT_ID,
  SET_SETTLEMENT,
  UPDATE_SETTLEMENT,
  DELETE_SETTLEMENT,
  FILE_UPLOAD,
  SET_SETTLEMENT_EXCEL,
} from "./actionTypes"

import {
  getSettlementListSuccess,
  getSettlementListFail,
  getSettlementByCustomerIdSuccess,
  getSettlementByCustomerIdFail,
  getSettlementIdSuccess,
  getSettlementIdFail,
  setSettlmentSuccess,
  setSettlementFail,
  setSettlementExcelSuccess,
  setSettlementExcelFail,
  updateSettlementSuccess,
  updateSettlementFail,
  deleteSettlementSuccess,
  deleteSettlementFail,
  fileUploadSuccess,
  fileUploadFail,
  returnState,
} from "./actions"

import {
  getSettlementList,
  getSettlementByCustomerId,
  getSettlementId,
  setSettlement,
  updateSettlement,
  deleteSettlement,
  fileUpload,
  setSettlementExcel,
} from "helpers/fakebackend_helper"

function* _getSettlementList() {
  try {
    const response = yield call(getSettlementList)
    if (response) {
      yield put(getSettlementListSuccess(response))
    } else {
      yield put(getSettlementListFail(response))
    }
  } catch (error) {
    yield put(getSettlementListFail(error))
  }
}

function* _getSettlementByCustomerId({ payload: data }) {
  try {
    const response = yield call(getSettlementByCustomerId, data)
    if (response) {
      yield put(getSettlementByCustomerIdSuccess(response))
    } else {
      yield put(getSettlementByCustomerIdFail(response))
    }
  } catch (error) {
    yield put(getSettlementByCustomerIdFail(error))
  }
}

function* _getSettlementId({ payload: data }) {
  try {
    const response = yield call(getSettlementId, data)
    if (response) {
      yield put(getSettlementIdSuccess(response))
    } else {
      yield put(getSettlementIdFail(response))
    }
  } catch (error) {
    yield put(getSettlementIdFail(error))
  }
}

function* _setSettlement({ payload: data }) {
  try {
    const response = yield call(setSettlement, data)
    if (response) {
      yield put(setSettlmentSuccess(response))
    } else {
      yield put(setSettlementFail(response))
    }
    yield put(returnState())
  } catch (error) {
    yield put(setSettlementFail(error))
  }
}

function* _setSettlementExcel({ payload: data }) {
  try {
    const response = yield call(setSettlementExcel, data)
    if (response) {
      yield put(setSettlementExcelSuccess(response))
    } else {
      yield put(setSettlementExcelFail(response))
    }
  } catch (err) {
    yield put(setSettlementExcelFail(err))
  }
}

function* _updateSettlement({ payload: data }) {
  try {
    const response = yield call(updateSettlement, data)
    if (response) {
      yield put(updateSettlementSuccess(response))
    } else {
      yield put(updateSettlementFail(response))
    }
    yield put(returnState())
  } catch (error) {
    yield put(updateSettlementFail(error))
  }
}

function* _deleteSettlement({ payload: data }) {
  try {
    const response = yield call(deleteSettlement, data)
    if (response) {
      yield put(deleteSettlementSuccess(response))
    } else {
      yield put(deleteSettlementFail(response))
    }
    yield put(returnState())
  } catch (error) {
    yield put(deleteSettlementFail(error))
  }
}

function* _fileUpload({ payload: data }) {
  try {
    const response = yield call(fileUpload, data)
    if (response) {
      yield put(fileUploadSuccess(response))
    } else {
      yield put(fileUploadFail(response))
    }
    yield put(returnState())
  } catch (error) {
    yield put(fileUploadFail(error))
  }
}

function* SettlementSaga() {
  yield takeEvery(GET_SETTLEMENT_LIST, _getSettlementList),
    yield takeEvery(GET_SETTLEMENT_BY_CUSTOMERID, _getSettlementByCustomerId)
  yield takeEvery(GET_SETTLEMENT_ID, _getSettlementId),
    yield takeEvery(SET_SETTLEMENT, _setSettlement),
    yield takeEvery(UPDATE_SETTLEMENT, _updateSettlement),
    yield takeEvery(DELETE_SETTLEMENT, _deleteSettlement)
  yield takeEvery(FILE_UPLOAD, _fileUpload),
    yield takeEvery(SET_SETTLEMENT_EXCEL, _setSettlementExcel)
}

export default SettlementSaga
