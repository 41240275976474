import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
// Dashboard

import Dashboard from "../pages/Dashboard/index"
import CustomerConfiguration from "../pages/CustomerConfigurationDetail"
import CustomerConfigurationList from "../pages/CustomerConfigurationList"

//Settlement
import Settlement from "../pages/Settlement/index"

//Other Charges
import OtherCharges from "../pages/OtherChargesPage/index"
import OverviewOtherCharges from "../pages/OtherChargesPage/Overview"
import AddOtherCharges from "../pages/OtherChargesPage/AddOtherCharges/Add.js"

import CutomerConfigurationCreate from '../pages/CustomerConfigurationCreate'

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/configurations/overview/:id", component: CustomerConfiguration },
  { path: "/configurations/list", component: CustomerConfigurationList },
  { path: "/payment-settlement", component: Settlement },
  { path: "/othercharges", component: OtherCharges },
  { path: "/othercharges/overview/:id", component: OverviewOtherCharges },
  { path: "/othercharges/create", component: AddOtherCharges },
  { path: "/configurations/create", component: CutomerConfigurationCreate },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { component: () => <Redirect to="/page-404" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
]

export { userRoutes, authRoutes }
