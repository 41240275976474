import { call, put, takeEvery } from "redux-saga/effects"

import { GET_PRODUCT_LIST} from "./actionTypes"
import { getProductListSuccess, getProductListFail} from "./actions"
import { getProductList } from "helpers/fakebackend_helper"



function* _getProductList() {
  try {
 
    const response = yield call(getProductList)
 
    yield put(getProductListSuccess(response))
  } catch (error) {
    yield put(getProductListFail(error))
  }
}

function* productListSaga() {
  yield takeEvery(GET_PRODUCT_LIST, _getProductList)
}

export default productListSaga
