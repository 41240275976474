import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import AuthUser from "components/AuthUser/AuthUser"
import { Redirect } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Col, Row, Container, Card, CardBody, FormGroup } from "reactstrap"
import Select from "react-select"
import { useDispatch } from "react-redux"
import { connect } from "react-redux"
import makeAnimated from "react-select/animated"
import SweetAlert from "react-bootstrap-sweetalert"
import ListOtherCharges from "./OtherChargesList/List"
function index(props) {
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [user, setUser] = useState(AuthUser())
  useEffect(() => {
    if (props.otherChargesCreate !== undefined) {
      if (props.otherChargesCreate === true) {
        setsuccess_dlg(props.otherChargesCreate)
        setdynamic_title("Success")
        setdynamic_description("Other Charge successfully created.")
      }
      if (props.otherChargesCreate === false) {
        setsuccess_dlg(props.otherChargesCreate)
        setdynamic_title("Fail")
        setdynamic_description("Other Charge not created.")
      }
    }
  }, [props.otherChargesCreate])
  useEffect(() => {
    if (props.otherChargesDelete !== undefined) {
      if (props.otherChargesDelete === true) {
        setsuccess_dlg(props.otherChargesDelete)
        setdynamic_title("Success")
        setdynamic_description("Other Charge successfully deleted.")
      }
      if (props.otherChargesDelete === false) {
        setsuccess_dlg(props.otherChargesDelete)
        setdynamic_title("Fail")
        setdynamic_description("Other Charge not deleted.")
      }
    }
  }, [props.otherChargesDelete])

  useEffect(() => {
    if (props.otherChargesUpdate !== undefined) {
      if (props.otherChargesUpdate === true) {
        setsuccess_dlg(props.otherChargesUpdate)
        setdynamic_title("Success")
        setdynamic_description("Other Charge successfully updated.")
      }
      if (props.otherChargesUpdate === false) {
        setsuccess_dlg(props.otherChargesUpdate)
        setdynamic_title("Fail")
        setdynamic_description("Other Charge not updated.")
      }
    }
  }, [props.otherChargesUpdate])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem=" Other Charges" />
          {success_dlg ? (
            <SweetAlert
              timeout={1000}
              success
              showConfirm={false}
              title={dynamic_title}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {error_dlg ? (
            <SweetAlert
              timeout={1000}
              error
              showConfirm={false}
              title={dynamic_title}
              onConfirm={() => {
                seterror_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {user.roleID === 1 ? (
            <ListOtherCharges />
          ) : (
            <Redirect to="/page-404" />
          )}
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    // customerConfigurations: state.CustomerConfigurations.customerConfigurations,
    otherChargesDelete: state.OtherCharges.otherChargesDelete,
    otherChargesCreate: state.OtherCharges.otherChargesCreate,
    otherChargesUpdate: state.OtherCharges.otherChargesUpdate,
  }
}

const mapDispatchToProps = dispatch => ({
  //   onGetCustomerConfigurations :()=> dispatch(getCustomerConfigurations())
})

export default connect(mapStateToProps, mapDispatchToProps)(index)
