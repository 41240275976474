import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_CUSTOMER_CONFIGURATIONS,
  GET_CUSTOMER_CONFIGURATION_DETAIL,
  GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID,
  CREATE_CUSTOMER_CONFIGURATION,
} from "./actionTypes"
import {
  getCustomerConfigurationsSuccess,
  getCustomerConfigurationsFail,
  getCustomerConfigurationDetailSuccess,
  getCustomerConfigurationDetailFail,
  getCustomerConfigurationDetailByCustomerIdSuccess,
  getCustomerConfigurationDetailByCustomerIdFail,
  createCustomerConfigurationSuccess,
  createCustomerConfigurationFail,
  restartState,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCustomerConfigurations,
  getCustomerConfigurationDetail,
  getCustomerConfigurationDetailByCustomerId,
  createCustomerConfiguration,
} from "helpers/fakebackend_helper"

function* _getCustomerConfigurations() {
  try {
    const response = yield call(getCustomerConfigurations)
    yield put(getCustomerConfigurationsSuccess(response))
  } catch (error) {
    yield put(getCustomerConfigurationsFail(error))
  }
}

function* _getCustomerConfigurationDetail({ id }) {
  try {
    const response = yield call(getCustomerConfigurationDetail, id)
    yield put(getCustomerConfigurationDetailSuccess(response))
  } catch (error) {
    yield put(getCustomerConfigurationDetailFail(error))
  }
}

function* _getCustomerConfigurationDetailByCustomerId({ payload: id }) {
  try {
    const response = yield call(getCustomerConfigurationDetailByCustomerId, id)
    if (response) {
      yield put(getCustomerConfigurationDetailByCustomerIdSuccess(response))
    } else {
      yield put(getCustomerConfigurationDetailByCustomerIdFail(response))
    }
  } catch (error) {
    yield put(getCustomerConfigurationDetailByCustomerIdFail(error))
  }
}

function* _createCustomerConfiguration({ payload: configuration }) {
  try {
    const response = yield call(createCustomerConfiguration, configuration)

    console.log(response)
    if (response) {
      yield put(createCustomerConfigurationSuccess(response))
    } else {
      yield put(createCustomerConfigurationFail(response))
      console.log("failed")
    }
    //yield put(restartState())
  } catch (error) {
    yield put(createCustomerConfigurationFail(error))
  }
}

function* customerConfigurationSaga() {
  yield takeEvery(GET_CUSTOMER_CONFIGURATIONS, _getCustomerConfigurations)
  yield takeEvery(
    GET_CUSTOMER_CONFIGURATION_DETAIL,
    _getCustomerConfigurationDetail
  )
  yield takeEvery(
    GET_CUSTOMER_CONFIGURATION_DETAIL_BY_CUSTOMERID,
    _getCustomerConfigurationDetailByCustomerId
  )
  yield takeEvery(
    GET_CUSTOMER_CONFIGURATION_DETAIL,
    _getCustomerConfigurationDetail
  )
  yield takeEvery(CREATE_CUSTOMER_CONFIGURATION, _createCustomerConfiguration)
}

export default customerConfigurationSaga
