import React, { useState, useEffect } from "react"
import {
  Col,
  Row,
  Button,
  InputGroup,
  InputGroupAddon,
  Label,
  Form,
} from "reactstrap"
import { connect } from "react-redux"
import FileUpload from "./FileUpload"
import { useForm } from "react-hook-form"
import AuthUser from "components/AuthUser/AuthUser"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
  getCustomerConfigurations,
  getCustomerConfigurationDetailByCustomerId,
  setSettlement,
  fileUpload,
  setSaveSettlement,
} from "store/actions"
import Select from "react-select"
function Add(props) {
  const [
    {
      amount,
      externalReference,
      remarks,
      file,
      customerId,
      customerName,
      agreementId,
      currency,
      co,
      status,
    },
    setSettlementDetail,
  ] = useState({
    amount: 0,
    externalReference: "",
    currency: "QAR",
    remarks: "",
    file: "",
    customerId: "",
    customerName: "",
    agreementId: "",
  })
  const [selectedFiles, setselectedFiles] = useState([])
  const [cusId, setCustId] = useState("")
  const [cusName, setCusName] = useState("")
  const [selectCus, setSelectCus] = useState({})
  const [cusAgreementId, setCusAgreementId] = useState("")
  const [cusAgreementIds, setCusAgreementIds] = useState([])
  const [user, setUser] = useState(AuthUser())
  const { register, handleSubmit, errors } = useForm()
  const [selectedDateRangeFrom, setSelectedDateRangeFrom] = useState(Date.now())
  const [selectedDateRangeTo, setSelectedDateRangeTo] = useState(
    Date.now() + 3600 * 1000 * 24
  )
  const [cusNameError, setCustNameError] = useState(false)
  const [cusAgreementError, setCustAgreementError] = useState(false)
  const [cusIdError, setCustIdError] = useState(false)
  const [fileChecker, setFileChecker] = useState(false)
  const {
    onGetCustomerConfigurationsList,
    customerList,
    onSetSaveSettlement,
    storedSettlement,
    onFileUpload,
    fileUpload,
    fileNameBack,
    onSetSettlement,
  } = props

  let optionsCustomerId = customerList.map((cus, i) => {
    return { value: cus.customerId, label: cus.customerId }
  })

  let optionsCustomerName = customerList.map((cus, i) => {
    return { value: cus.businessName, label: cus.businessName }
  })

  let optionsCustomerAgreements = cusAgreementIds?.map((ag, i) => {
    return { value: ag.agreementId, label: ag.agreementId }
  })

  useEffect(() => {
    onGetCustomerConfigurationsList()
  }, [])

  useEffect(() => {}, [customerList])
  useEffect(() => {}, [cusAgreementIds])
  useEffect(() => {}, [cusAgreementId])

  useEffect(() => {
    if (selectCus !== undefined) {
      setCusAgreementIds(selectCus.agreements)
      setCusAgreementId("")
      setSettlementDetail(settlement => ({
        ...settlement,
        agreementId: "",
      }))
    }
  }, [selectCus])

  useEffect(() => {
    if (customerId !== "") {
      hadnleGetCustomerDetails("id", customerId)
    }
  }, [customerId])

  useEffect(() => {
    if (customerName !== "") {
      hadnleGetCustomerDetails("name", customerName)
    }
  }, [customerName])

  useEffect(() => {
    // console.log(selectedFiles)
    if (selectedFiles !== undefined) {
      setFileChecker(false)
      setSettlementDetail(settlement => ({
        ...settlement,
        file: selectedFiles.name,
      }))
    }
  }, [selectedFiles])

  useEffect(() => {}, [cusId])
  useEffect(() => {}, [cusName])
  useEffect(() => {}, [file])
  useEffect(() => {}, [cusNameError])
  useEffect(() => {}, [cusAgreementError])
  useEffect(() => {}, [cusIdError])
  useEffect(() => {}, [selectedDateRangeFrom])
  useEffect(() => {}, [selectedDateRangeTo])
  useEffect(() => {}, [fileChecker])
  useEffect(() => {
    if (customerName !== "") {
      setCustNameError(false)
    }
  }, [customerName])

  useEffect(() => {
    if (customerId !== "") {
      setCustIdError(false)
    }
  }, [customerId])

  useEffect(() => {
    if (agreementId !== "") {
      setCustAgreementError(false)
    }
  }, [agreementId])

  useEffect(() => {}, [fileUpload])
  useEffect(() => {
    if (fileNameBack) {
      handleSaveSettlement(storedSettlement)
    }
  }, [fileNameBack])
  useEffect(() => {}, [storedSettlement])

  const handleSaveSettlement = () => {
    const settlementDetail = {
      amount: storedSettlement.amount,
      externalReference: storedSettlement.externalReference,
      remarks: storedSettlement.remarks,
      agreementId: storedSettlement.agreementId,
      file: fileUpload.toString(),
      currency: storedSettlement.currency,
      customerId: storedSettlement.customerId,
      customerName: storedSettlement.customerName,
      addedBy: user.email,
      periodCoverageFrom: storedSettlement.periodCoverageFrom,
      periodCoverageTo: storedSettlement.periodCoverageTo,
      cO: storedSettlement.cO,
      status: storedSettlement.status,
      dateofSettlement: storedSettlement.dateofSettlement,
    }
    //console.log(settlementDetail)
    onSetSettlement(settlementDetail)
    props.onChange(false)
    //window.location.reload()
  }

  const storeDataOnForm = event => {
    const { name, value } = event.target
    setSettlementDetail(settlement => ({ ...settlement, [name]: value }))
  }

  const customerIDEvent = event => {
    setCustId(event)
    setSettlementDetail(settlement => ({
      ...settlement,
      customerName: "",
      customerId: event.value,
    }))
  }
  const customerNameEvent = event => {
    setCusName(event)
    setSettlementDetail(settlement => ({
      ...settlement,
      customerId: "",
      customerName: event.value,
    }))
  }

  const customerAgreementEvent = event => {
    setCusAgreementId(event)
    setSettlementDetail(settlement => ({
      ...settlement,
      agreementId: event.value,
    }))
  }

  const hadnleGetCustomerDetails = (type, data) => {
    if (type === "id" && customerName === "") {
      customerList.map((cus, i) => {
        if (data === cus.customerId) {
          setCusName({ label: cus.businessName, value: cus.businessName })
          setSelectCus(cus)
          setSettlementDetail(settlement => ({
            ...settlement,
            customerName: cus.businessName,
          }))
        }
      })
    }
    if (type === "name" && customerId === "") {
      customerList.map((cus, i) => {
        if (data === cus.businessName) {
          setCustId({ label: cus.customerId, value: cus.customerId })
          setSelectCus(cus)
          setSettlementDetail(settlement => ({
            ...settlement,
            customerId: cus.customerId,
          }))
        }
      })
    }
  }

  const handleCreateSettlement = () => {
    //data.preventDefault()
    if (customerName === "") {
      setCustNameError(true)
    } else {
      setCustNameError(false)
    }
    if (customerId === "") {
      setCustIdError(true)
    } else {
      setCustIdError(false)
    }
    if (agreementId === "") {
      setCustAgreementError(true)
    } else {
      setCustAgreementError(false)
    }
    if (customerId !== "" && customerName !== "" && agreementId !== "") {
      let dateFrom = ""
      let dateTo = ""
      if (selectedDateRangeFrom[0] === undefined) {
        dateFrom = new Date(Date.now())
      }
      if (selectedDateRangeTo[0] === undefined) {
        dateTo = new Date(Date.now() + 3600 * 1000 * 24)
      }

      if (selectedDateRangeFrom[0] !== undefined) {
        dateFrom = selectedDateRangeFrom[0]
      }
      if (selectedDateRangeTo[0] !== undefined) {
        dateTo = selectedDateRangeTo[0]
      }

      const settlementDetail = {
        amount: amount,
        externalReference: externalReference,
        remarks: remarks,
        file: "",
        currency: currency,
        customerId: customerId,
        customerName: customerName,
        userId: user._id,
        addedBy: user.email,
        periodCoverageFrom: dateFrom,
        periodCoverageTo: dateTo,
        cO: co,
        status: status,
        agreementId: agreementId,
      }
      //console.log(settlementDetail)
      onSetSaveSettlement(settlementDetail)
      if (selectedFiles !== undefined) {
        if (fileChecker === false) {
          handleFileUpload()
        } else {
          setFileChecker(true)
        }
      } else {
        setFileChecker(true)
      }
    }
  }

  function handleFileUpload() {
    if (selectedFiles !== undefined) {
      const formData = new FormData()
      formData.append("file", selectedFiles)
      formData.append("FileName", selectedFiles.name)
      if (formData) {
        onFileUpload(formData)
      }
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Form onSubmit={handleSubmit(handleCreateSettlement)}>
            <Row className="form-group mx-3 my-3">
              <Col md={6}>
                <Label className=" input-text-size">Amount</Label>

                <input
                  id="amount"
                  name="amount"
                  value={amount}
                  min="1"
                  step=".01"
                  type="number"
                  ref={register({ required: true })}
                  className="form-control form-control-lg input-text-size"
                  onChange={event => storeDataOnForm(event)}
                />
                {errors.amount && errors.amount.type === "required" && (
                  <p className="color-danger">This is required</p>
                )}
              </Col>
              <Col md={6}>
                <Label className=" input-text-size">Currency</Label>
                <input
                  id="currency"
                  name="currency"
                  value={currency}
                  type="text"
                  ref={register({ required: true })}
                  className="form-control form-control-lg input-text-size"
                  onChange={event => storeDataOnForm(event)}
                />
                {errors.outstanding &&
                  errors.outstanding.type === "required" && (
                    <p className="color-danger">This is required</p>
                  )}
              </Col>
            </Row>
            <Row className="form-group mx-3 my-3">
              <Col md={6}>
                <Label className=" input-text-size">Customer Name</Label>
                <Select
                  id="cusName"
                  value={cusName}
                  name="cusName"
                  //innerRef={register({ required: true })}
                  onChange={event => customerNameEvent(event)}
                  options={optionsCustomerName}
                  className=" input-text-size"
                  classNamePrefix="select2-selection"
                />
                {/* {errors.cusName && errors.cusName.type === "required" && (
                  <p className="color-danger">This is required</p>
                )} */}
                {cusNameError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
              <Col md={6}>
                <Label className=" input-text-size">Customer Id</Label>
                <Select
                  value={cusId}
                  id="cusId"
                  name="cusId"
                  // innerRef={register({ required: true })}
                  onChange={event => customerIDEvent(event)}
                  options={optionsCustomerId}
                  className=" input-text-size "
                  classNamePrefix="select2-selection"
                />
                {/* {errors.cusId && errors.cusId.type === "required" && (
                  <p className="color-danger">This is required</p>
                )} */}
                {cusIdError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row className="form-group mx-3 my-3">
              <Col md={6}>
                <Label className=" input-text-size">Agreement Id</Label>
                <Select
                  id="cusAgreementId"
                  value={cusAgreementId}
                  name="cusAgreementId"
                  //innerRef={register({ required: true })}
                  onChange={event => customerAgreementEvent(event)}
                  options={optionsCustomerAgreements}
                  className=" input-text-size"
                  classNamePrefix="select2-selection"
                />
                {/* {errors.cusName && errors.cusName.type === "required" && (
                  <p className="color-danger">This is required</p>
                )} */}
                {cusAgreementError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
              <Col md={6}>
                <Label className=" input-text-size">External Reference</Label>
                <input
                  id="externalReference"
                  name="externalReference"
                  value={externalReference}
                  type="text"
                  ref={register({ required: true })}
                  className="form-control form-control-lg input-text-size"
                  onChange={event => storeDataOnForm(event)}
                />
                {errors.outstanding &&
                  errors.outstanding.type === "required" && (
                    <p className="color-danger">This is required</p>
                  )}
              </Col>
            </Row>
            <Row className="form-group mx-3 my-3">
              <Col md={6}>
                <Label>Period Coverage From</Label>
                <Flatpickr
                  name="selectedDates"
                  className="form-control w-100 my-1  "
                  placeholder="Select Date Range"
                  //innerRef={register({ required: true })}
                  value={selectedDateRangeFrom}
                  options={{
                    altFormat: "Y-m-d",
                    dateFormat: "F j, Y",
                  }}
                  onChange={selectedDates =>
                    setSelectedDateRangeFrom(selectedDates)
                  }
                />
              </Col>
              <Col md={6}>
                <Label>Period Coverage To</Label>
                <Flatpickr
                  name="selectedDates"
                  className="form-control w-100 my-1  "
                  placeholder="Select Date Range"
                  //innerRef={register({ required: true })}
                  value={selectedDateRangeTo}
                  options={{
                    altFormat: "Y-m-d",
                    dateFormat: "F j, Y",
                  }}
                  onChange={selectedDates =>
                    setSelectedDateRangeTo(selectedDates)
                  }
                />
              </Col>
            </Row>
            <Row className="form-group mx-3 my-3">
              <Col md={6}>
                <Label>REMARKS</Label>
                <input
                  id="remarks"
                  name="remarks"
                  value={remarks}
                  // innerRef={register({ required: false })}
                  className="form-control form-control-lg input-text-size"
                  onChange={event => storeDataOnForm(event)}
                />
              </Col>
              <Col md={6}>
                <Label>CO</Label>
                <input
                  id="co"
                  name="co"
                  value={co}
                  // innerRef={register({ required: false })}
                  className="form-control form-control-lg input-text-size"
                  onChange={event => storeDataOnForm(event)}
                />
              </Col>
            </Row>
            <Row className="form-group mx-3 my-3">
              <Col md={6}>
                <Label>Status</Label>
                <input
                  name="status"
                  value={status}
                  // innerRef={register({ required: false })}
                  className="form-control form-control-lg input-text-size"
                  onChange={event => storeDataOnForm(event)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FileUpload
                  // ref={register({ required: false })}
                  onChange={value => setselectedFiles(value)}
                />
                {fileChecker ? (
                  <div className="item-center">
                    <p className="color-danger">File is required</p>
                  </div>
                ) : null}
              </Col>
            </Row>

            <Row>
              <Col md={12} className="item-center mb-3">
                <Button
                  color="success"
                  type="submit"
                  className=" waves-effect waves-light mt-3 btn-lg"
                  //onClick={() => handleSubmit(handleCreateSettlement)}
                >
                  <i className="fas fa-plus mr-1" />
                  Add New Payment Settlement
                </Button>
                {/* <Button
                  color="success"
                  type="submit"
                >
                  click
                </Button> */}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    customerList: state.customerconfigurations.customerConfigurations,
    customerConfigurationDetailByCustomerId:
      state.customerconfigurations.customerConfigurationDetailByCustomerId,
    settlement: state.Settlement.settlement,
    fileUpload: state.Settlement.file,
    fileNameBack: state.Settlement.fileName,
    storedSettlement: state.Settlement.storedSettlement,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetCustomerConfigurationsList: () => dispatch(getCustomerConfigurations()),
  onGetCustomerConfigurationDetailByCustomerID: id =>
    dispatch(getCustomerConfigurationDetailByCustomerId(id)),
  onSetSettlement: settlement => dispatch(setSettlement(settlement)),
  onFileUpload: data => dispatch(fileUpload(data)),
  onSetSaveSettlement: data => dispatch(setSaveSettlement(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Add)
