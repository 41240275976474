import {
  GET_INCENTIVE_DUE_CALCULATION_SUMMARY,
  GET_INCENTIVE_DUE_CALCULATION_SUMMARY_SUCCESS,
  GET_INCENTIVE_DUE_CALCULATION_SUMMARY_FAIL,
  GET_TICKETED_SEGMENTS,
  GET_TICKETED_SEGMENTS_SUCCESS,
  GET_TICKETED_SEGMENTS_FAIL,
  GET_UNPAID_AIRLINES,
  GET_UNPAID_AIRLINES_SUCCESS,
  GET_UNPAID_AIRLINES_FAIL,
  GET_UID_GRAPHIC_USAGE,
  GET_UID_GRAPHIC_USAGE_SUCCESS,
  GET_UID_GRAPHIC_USAGE_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL
} from "./actionTypes"

const INIT_STATE = {
  dueIncentiveCalculation: {},
  ticketedSegments: [],
  uidGraphicUsage:[],
  error: {},
  
}

const IncentiveDueCalculations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INCENTIVE_DUE_CALCULATION_SUMMARY_SUCCESS:
      return {
        ...state,
        dueIncentiveCalculation: action.payload,
        loadingSummary: false,
      }

    case GET_INCENTIVE_DUE_CALCULATION_SUMMARY_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingSummary: false,
      }

    case GET_TICKETED_SEGMENTS_SUCCESS:
      return {
        ...state,
        ticketedSegments: action.payload,
        loadingDetailed:false
      }

    case GET_TICKETED_SEGMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingDetailed:false
      }

      case GET_UNPAID_AIRLINES_SUCCESS:
      return {
        ...state,
        unpaidAirlines: action.payload,
        loadingDetailed:false
      }

    case GET_UNPAID_AIRLINES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingDetailed:false
      }




//PRODUCTS
    case GET_UID_GRAPHIC_USAGE_SUCCESS:
      return {
        ...state,
        uidGraphicUsage: action.payload,
        loadingDetailed:false
      }

    case GET_UID_GRAPHIC_USAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingDetailed:false
      }

      case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        loadingDetailed:false
      }

    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingDetailed:false
      }


    default:
      return state
  }
}

export default IncentiveDueCalculations
