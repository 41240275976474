import { call, put, takeEvery } from "redux-saga/effects"

import { GET_OPTION_DETAIL ,  GET_OPTIONS} from "./actionTypes"
import { getOptionDetailSuccess, getOptionDetailFail, getOptionsSuccess,getOptionsFail } from "./actions"

import { getOptionDetail, getOptions } from "helpers/fakebackend_helper"

function* _getOptionDetail({ option }) {
  try {
    const response = yield call(getOptionDetail, option)
    yield put(getOptionDetailSuccess(response))
  } catch (error) {
    yield put(getOptionDetailFail(error))
  }
}

function* _getOptions() {
  try {
    const response = yield call(getOptions)
    yield put(getOptionsSuccess(response))
  } catch (error) {
    yield put(getOptionsFail(error))
  }
}

function* optionsSaga() {
  yield takeEvery(GET_OPTION_DETAIL, _getOptionDetail)
  yield takeEvery(GET_OPTIONS, _getOptions)
}

export default optionsSaga
