import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import AuthUser from "components/AuthUser/AuthUser"
// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
  SESSION_TIMEOUT,
  OTP,
} from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  loginFail,
  sessionTimeoutSuccess,
  sessionTimeouFail,
  otpSuccess,
  otpFail,
  resetState,
} from "./actions"

import {
  postLogin,
  otpLogin,
  getCustomerConfigurationDetailByCustomerId,
  getCustomerConfigurationDetail,
} from "../../../helpers/fakebackend_helper"

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postLogin, {
        username: user.email,
        password: user.password,
      })
      //console.log(response)
      if (
        response.message !== "Username or password is incorrect" &&
        user.email.includes("amadeus.qa")
      ) {
        localStorage.setItem("authUser", JSON.stringify(response))
        yield put(loginSuccess(response))
        history.push("/configurations/list")
      }
      if (response.message !== "Username or password is incorrect") {
        //localStorage.setItem("authUser", JSON.stringify(response))
        yield put(loginSuccess(response))
      } else {
        console.log(response.message)
        yield put(loginFail(response.message))
      }
    }
    yield put(resetState())
  } catch (error) {
    console.log(error.message)
    yield put(apiError(error.message))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/configurations/list")
  } catch (error) {
    yield put(apiError(error))
  }
}
function* _sessionTimeout({ payload: user }) {
  try {
    if (user === true) {
      sessionTimeoutSuccess(true)
      localStorage.setItem("sessionTimeout", true)
      localStorage.removeItem("authUser")
      window.location = "/login"
    } else {
      sessionTimeouFail(false)
      localStorage.removeItem("sessionTimeout")
    }
  } catch (error) {
    sessionTimeouFail(false)
  }
}

function* _otpLogin({ payload: { data, history } }) {
  try {
    const response = yield call(otpLogin, data)
    if (
      response.message !== "Username or password is incorrect" &&
      response.message !== "OTP is incorrect."
    ) {
      localStorage.setItem("authUser", JSON.stringify(response))
      const user = AuthUser()
      const cusDetailsByCusId = yield call(
        getCustomerConfigurationDetailByCustomerId,
        user.customerID
      )
      if (cusDetailsByCusId !== undefined) {
        yield put(loginSuccess(response))
        history.push("/configurations/overview/" + cusDetailsByCusId._id)
      }
    } else {
      yield put(loginFail(response.message))
    }
    yield put(resetState())
  } catch (err) {
    yield put(apiError(error.message))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(SESSION_TIMEOUT, _sessionTimeout)
  yield takeEvery(OTP, _otpLogin)
}

export default authSaga
