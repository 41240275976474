import React from "react"
import Flatpickr from "react-flatpickr"
import {
  Button,
  CardText,
  Col,
  Collapse,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"


const OtherCharges_Monthly = props => {
  const {
    index,
    key,
    monthlycharge,
    handleMonthlyChargeChange,
    handleMonthlyChargeDelete,
    // handleSubmit,
    // onSubmit,
    register,
    // errors,
  } = props

  const inputDate = "Date" + index
  const inputAmount = "Amount" + index
  const inputCurrency = "Currency" + index

 


  function handleChange(changes) {
    handleMonthlyChargeChange(monthlycharge.id, { ...monthlycharge, ...changes })
  }

  return (
    <React.Fragment>
      <Form>
        <Row>
          <Col lg="6"></Col>
        </Row>
        <Row>
          <Col lg="3">
            <FormGroup>
              <Flatpickr
                className="form-control d-block"
                value={monthlycharge.Date}
                onChange={e => {
                  handleChange({Date:e[0]})
                }}
                name={inputDate}
                id="effectivedate"
                placeholder="Select Date"
                options={{
                  altInput: true,
                  altFormat: "F j, Y",
                  dateFormat: "Y-m-d",
                }}
                // ref={register({
                //   required: {
                //     value: true,
                //     message: "This is required",
                //   },
                // })}
              />

              {/* <small className="color-danger">
                {errors[inputDate] &&
                  errors[inputDate].message}
              </small> */}
            </FormGroup>
          </Col>

          <Col lg="3">
            <FormGroup>
              <input
                value={monthlycharge.Amount}
                onChange={e =>
                  handleChange({ Amount: parseFloat(e.target.value) })
                }
                type="number"
                min="0"
                step="0.5"
                name={inputAmount}
                className="form-control"
                placeholder="Rate"
                // ref={register({
                //   required: {
                //     value: true,
                //     message: "This is required",
                //   },
                // })}
              />

              {/* <small className="color-danger">
                {errors[inputAmount] && errors[inputAmount].message}
              </small> */}
            </FormGroup>
          </Col>

          <Col lg="3">
            <FormGroup>
              <select
                className="custom-select"
                name={inputCurrency}
                value={monthlycharge.Currency}
                onChange={e => handleChange({ Currency: e.target.value })}
                // ref={register({
                //   required: {
                //     value: true,
                //     message: "This is required",
                //   },
                // })}
              >
                <option value="QAR">QAR</option>
              </select>

              {/* <small className="color-danger">
                {errors[inputCurrency] && errors[inputCurrency].message}
              </small> */}
            </FormGroup>
          </Col>

         

          <Col>
            <FormGroup>
              <Button
                onClick={e => handleMonthlyChargeDelete(monthlycharge.id)}
                color="danger"
                className="btn-block inner"
                style={{ width: "100%" }}
              >
                {" "}
                Delete
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default OtherCharges_Monthly
