import React, { useState, useEffect } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Table,
  Collapse,
} from "reactstrap"
import { Link } from "react-router-dom"
import { map, isEmpty } from "lodash"
import {
  dateFormat,
  dateFormatMMMYYYY,
} from "../../../helpers/commonhelpers/dateFormat"
import ReactHTMLTableToExcel from "react-html-table-to-excel"

const Products = props => {
  const { custConfig, isCustomer, products } = props
  const [amc, setAmc] = useState(true)
  const [productFee, setProductFee] = useState(true)
  const [implementation, setImplementation] = useState(true)
  const [uid, setUid] = useState(true)
  const [oid, setOid] = useState(true)
  const [corporate, setCorporate] = useState(true)
  const [transaction, setTransaction] = useState(true)
  const [transaction_1, setTransaction_1] = useState(true)
  const [transaction_2, setTransaction_2] = useState(true)
  const [addOn, setAddOn] = useState(true)
  const [fileName, setFileName] = useState("BusinessName")

  console.log(products)

  useEffect(() => {
    var filename = custConfig?.businessName + "-" + products?.productName
    setFileName(filename)
  }, [custConfig, products])

  const AmcCollapse = () => {
    setAmc(!amc)
  }

  const ProdFeeCollapse = () => {
    setProductFee(!productFee)
  }

  const ImplementationCollapse = () => {
    setImplementation(!implementation)
  }

  const UidCollapse = () => {
    setUid(!uid)
  }

  const OidCollapse = () => {
    setOid(!oid)
  }

  const CorporateCollapse = () => {
    setCorporate(!corporate)
  }

  const TransactionCollapse = () => {
    setTransaction(!transaction)
  }
  const Transaction_1Collapse = () => {
    setTransaction_1(!transaction_1)
  }
  const Transaction_2Collapse = () => {
    setTransaction_2(!transaction_2)
  }

  const AddOnCollapse = () => {
    setAddOn(!addOn)
  }

  function GetOverAllTotal(transactionalArr) {
    var sum = 0
    transactionalArr.forEach(n => (sum += n.rowTotal))

    return sum.toFixed(2)
  }

  function GetOverAllCount(transactionalArr) {
    var sum = 0
    transactionalArr.forEach(n => (sum += n.rowCount))

    return sum
  }

  return (
    <>
      {products && (
        <Card>
          <CardBody style={{ minHeight: "635px" }}>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button download-button"
              table="products"
              filename={fileName}
              sheet={products.productName}
              buttonText={
                <p
                  style={{
                    fontSize: "15px",
                    color: "green",
                    textAlign: "right",
                  }}
                >
                  <i className="fas fa-file-excel mr-1" />
                  Download this report
                </p>
              }
            />

            <Table className="table table-bordered" id="products">
              <tr>
                <td colSpan={3}>
                  <p>{custConfig.businessName}</p>
                  <br></br>

                  <CardTitle className="mb-4" style={{ color: "blue" }}>
                    {products.productName === "Selling Platform Connect"
                      ? "UID Usage"
                      : products.productName}
                    <br></br>
                    <small style={{ color: "gray" }}>
                      MONTHLY DETAILS
                    </small>{" "}
                    <br></br>
                    <small style={{ color: "gray" }}>
                      <b>
                        {dateFormatMMMYYYY(
                          products.coveredPeriodFxRate[0].date
                        )}{" "}
                        -{" "}
                        {dateFormatMMMYYYY(
                          products.coveredPeriodFxRate[
                            products.coveredPeriodFxRate.length - 1
                          ].date
                        )}
                      </b>
                    </small>
                    <br></br>
                  </CardTitle>
                </td>
              </tr>

              <tr>
                <td width="10%">
                  {" "}
                  <small>DATE</small>
                </td>

                {map(products.coveredPeriodFxRate, (date, index) => (
                  <td width="120px" style={{ textAlign: "right" }} key={index}>
                    {" "}
                    <small>
                      <b>{dateFormat(date.date)}</b>
                    </small>
                  </td>
                ))}

                <td width="10%" style={{ color: "green", textAlign: "right" }}>
                  {" "}
                  <small>
                    <b>TOTAL</b>
                  </small>
                </td>
              </tr>

              {!isCustomer && (
                <tr>
                  <td width="10%">
                    {" "}
                    <small>FXRATE</small>
                  </td>

                  {map(products.coveredPeriodFxRate, (fx, index) => (
                    <>
                      {fx.fxRate.euro.selling === 0.0 ? (
                        <td
                          style={{ textAlign: "right", color: "red" }}
                          key={index}
                        >
                          {" "}
                          <small>{fx.fxRate.euro.selling.toFixed(2)}</small>
                        </td>
                      ) : (
                        <td
                          width="120px"
                          style={{ textAlign: "right" }}
                          key={index}
                        >
                          {" "}
                          <small>{fx.fxRate.euro.selling.toFixed(2)}</small>
                        </td>
                      )}
                    </>
                  ))}

                  <td
                    width="10%"
                    style={{ color: "green", textAlign: "right" }}
                  >
                    {" "}
                    <small>
                      <b>{products.overAllRowTotal} QAR</b>{" "}
                    </small>
                  </td>
                </tr>
              )}

              {/*--------------------- AMC */}
              {products.billingOptionsList?.amc && (
                <>
                  <tr>
                    <td colSpan={products.coveredPeriodFxRate.length + 1}>
                      <Link
                        to="#"
                        onClick={AmcCollapse}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="d-flex justify-content-start">
                        <span style={{marginRight:"10px"}}>
                            <b style={{ color: "blue" }}>
                              AMC{" "}
                              {products.billingOptionsList.amc.isWaived ? (
                                <small style={{ color: "orange" }}>
                                  <b>WAIVED</b>
                                </small>
                              ) : (
                                ""
                              )}
                            </b>
                          </span>
                          <span style={{marginRight:"10px"}}>
                            <b>
                              {" "}
                              <small>
                                {" "}
                                ({products.billingOptionsList.amc.price}{" "}
                                {products.billingOptionsList.amc.priceCurrency}{" "}
                                / YEARLY)
                              </small>
                            </b>
                          </span>

                          <span>
                              {amc ? (
                                <>  <small style={{}}>View More </small><i className="bx bx-chevron-down" /></>
                              
                              ) : (
                                <>  <small> View Less</small> <i className="bx bx-chevron-up" /></>
                               
                              )}
                            </span>
                        </div>
                      </Link>
                    </td>
                    <td>
                      <div className="d-flex justify-content-end">
                        {products.billingOptionsList.amc.isWaived ? (
                          <small
                            style={{
                              color: "orange",
                              textAlign: "right",
                              textDecoration: "line-through",
                            }}
                          >
                            <b>
                              {" "}
                              {
                                products.billingOptionsList.amc.rowTotal
                              } QAR{" "}
                            </b>
                          </small>
                        ) : (
                          <small style={{ color: "green", textAlign: "right" }}>
                            <b>
                              {" "}
                              {
                                products.billingOptionsList.amc.rowTotal
                              } QAR{" "}
                            </b>
                          </small>
                        )}
                      </div>
                    </td>
                  </tr>

                  <tr id="amc" hidden={amc}>
                    <td>
                      {" "}
                      <small></small>
                    </td>
                    {map(
                      products.billingOptionsList.amc.monthlyDetail,
                      (r, index) => (
                        <td
                          width="120px"
                          style={{ textAlign: "right" }}
                          key={index}
                        >
                          <p>
                            <small>
                              <b>
                                {r.total} {r.currency}
                              </b>
                            </small>
                          </p>
                        </td>
                      )
                    )}

                    <td
                      width="10%"
                      style={{ textAlign: "right", color: "blue" }}
                    >
                      <small>
                        {" "}
                        <b> {products.billingOptionsList.amc.rowTotal} QAR</b>
                      </small>
                    </td>
                  </tr>
                </>
              )}

                 {/*--------------------- PRODUCTFEE */}
            {products.billingOptionsList?.productFee && (
              <>
                
                  <tr>
                  <td colSpan={products.coveredPeriodFxRate.length + 1}>
                  <Link
                  to="#"
                  onClick={ProdFeeCollapse}
                  style={{ cursor: "pointer" }}
                >
                       <span style={{marginRight:"10px"}}>
                        <b style={{ color: "blue" }}>
                          PRODUCT MONTHLY FEE{" "}
                          {products.billingOptionsList.productFee.isWaived ? (
                            <small style={{ color: "orange" }}>
                              <b>WAIVED</b>
                            </small>
                          ) : (
                            ""
                          )}
                        </b>
                      </span>
                      <span style={{marginRight:"10px"}}>
                        <b>
                          {" "}
                          <small>
                            {" "}
                            ({products.billingOptionsList.productFee.price}{" "}
                            {
                              products.billingOptionsList.productFee
                                .priceCurrency
                            }{" "}
                            / YEARLY)
                          </small>
                        </b>
                      </span>
                      <span>
                              {productFee ? (
                                <>  <small style={{}}>View More </small><i className="bx bx-chevron-down" /></>
                              
                              ) : (
                                <>  <small> View Less</small> <i className="bx bx-chevron-up" /></>
                               
                              )}
                            </span>
                      </Link>
                    </td>

                    <td>
                    <div className="d-flex justify-content-end">
                      {products.billingOptionsList.productFee.isWaived ? (
                        <small
                          style={{
                            color: "orange",
                            textAlign: "right",
                            textDecoration: "line-through",
                          }}
                        >
                          <b>
                            {" "}
                            {
                              products.billingOptionsList.productFee.rowTotal
                            }{" "}
                            QAR{" "}
                          </b>
                        </small>
                      ) : (
                        <small style={{ color: "green", textAlign: "right" }}>
                          <b>
                            {" "}
                            {
                              products.billingOptionsList.productFee.rowTotal
                            }{" "}
                            QAR{" "}
                          </b>
                        </small>
                      )}
                      </div>
                    </td>
                  </tr>
              
     
                  <tr id="productfee" hidden={productFee}>
                      <td width="10%">
                        {" "}
                        <small></small>
                      </td>
                      {map(
                        products.billingOptionsList.productFee.monthlyDetail,
                        (r, index) => (
                          <td
                            width="120px"
                            style={{ textAlign: "right" }}
                            key={index}
                          >
                            <p>
                              <small>
                                <b>
                                  {r.total} {r.currency}
                                </b>
                              </small>
                            </p>
                          </td>
                        )
                      )}

                      <td
                        width="10%"
                        style={{ textAlign: "right", color: "blue" }}
                      >
                        <small>
                          {" "}
                          <b>
                            {" "}
                            {
                              products.billingOptionsList.productFee.rowTotal
                            }{" "}
                            QAR
                          </b>
                        </small>
                      </td>
                    </tr>
              
              </>
            )}

              {/*--------------------- IMPLEMENTATION */}
              {products.billingOptionsList?.implementation && (
              <>
               
                  <tr>
                  <td colSpan={products.coveredPeriodFxRate.length + 1}>
                   
                    <Link
                  to="#"
                  onClick={ImplementationCollapse}
                  style={{ cursor: "pointer" }}
                >
                   <div className="d-flex justify-content-start">
                        <span style={{marginRight:"10px"}}>
                        <b style={{ color: "blue" }}>
                          IMPLEMENTATION{" "}
                          {products.billingOptionsList.implementation
                            .isWaived ? (
                            <small style={{ color: "orange" }}>
                              <b>WAIVED</b>
                            </small>
                          ) : (
                            ""
                          )}
                        </b>
                      </span>
                     
                      <span style={{marginRight:"10px"}}>
                      
                        <b>
                          {" "}
                          <small>
                            {" "}
                            ({
                              products.billingOptionsList.implementation.price
                            }{" "}
                            {
                              products.billingOptionsList.implementation
                                .priceCurrency
                            }{" "}
                            / YEARLY)
                          </small>
                        </b>
                 
                      </span>

                      <span>
                              {implementation ? (
                                <>  <small style={{}}>View More </small><i className="bx bx-chevron-down" /></>
                              
                              ) : (
                                <>  <small> View Less</small> <i className="bx bx-chevron-up" /></>
                               
                              )}
                            </span>

                            </div>
                      </Link>
                    </td>

                    <td>
                    <div className="d-flex justify-content-end">
                      {products.billingOptionsList.implementation.isWaived ? (
                        <small
                          style={{
                            color: "orange",
                            textAlign: "right",
                            textDecoration: "line-through",
                          }}
                        >
                          <b>
                            {" "}
                            {
                              products.billingOptionsList.implementation
                                .rowTotal
                            }{" "}
                            QAR{" "}
                          </b>
                        </small>
                      ) : (
                        <small style={{ color: "green", textAlign: "right" }}>
                          <b>
                            {" "}
                            {
                              products.billingOptionsList.implementation
                                .rowTotal
                            }{" "}
                            QAR{" "}
                          </b>
                        </small>
                      )}
                      </div>
                    </td>
                  </tr>
               
               
                    <tr hidden={implementation}>
                      <td width="10%">
                        {" "}
                        <small></small>
                      </td>
                      {map(
                        products.billingOptionsList.implementation
                          .monthlyDetail,
                        (r, index) => (
                          <td
                            width="120px"
                            style={{ textAlign: "right" }}
                            key={index}
                          >
                            <p>
                              <small>
                                <b>
                                  {r.total} {r.currency}
                                </b>
                              </small>
                            </p>
                          </td>
                        )
                      )}

                      <td
                        width="10%"
                        style={{ textAlign: "right", color: "blue" }}
                      >
                        <small>
                          {" "}
                          <b>
                            {" "}
                            {
                              products.billingOptionsList.implementation
                                .rowTotal
                            }{" "}
                            QAR
                          </b>
                        </small>
                      </td>
                    </tr>
                 
            
              </>
            )}
            


              {/*------------------------ TRANSACTIONAL */}
              {products.billingOptionsList?.transactional && (
                <>
                  <tr>
                    <td colSpan={products.coveredPeriodFxRate.length + 1}>
                      <Link
                        to="#"
                        onClick={TransactionCollapse}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="d-flex justify-content-start">
                          
                            <span style={{marginRight:"10px"}}>
                              <b style={{ color: "blue" }}>
                                {" "}
                                {
                                  products.billingOptionsList.transactional[0]
                                    .unitOfMeasure
                                }{" "}
                                {products.billingOptionsList.transactional[0]
                                  .isWaived ? (
                                  <small style={{ color: "orange" }}>
                                    <b>WAIVED</b>
                                  </small>
                                ) : (
                                  ""
                                )}
                              </b>
                            </span>
                         
                            <span style={{marginRight:"10px"}}>
                              <b>
                                {" "}
                                <small>
                                  {" "}
                                  ({" "}
                                  {
                                    products.billingOptionsList.transactional[0]
                                      .price
                                  }{" "}
                                  {
                                    products.billingOptionsList.transactional[0]
                                      .priceCurrency
                                  }{" "}
                                  )
                                </small>
                              </b>
                            </span>
                        
                            <span>
                              {transaction ? (
                                <>  <small style={{}}>View More </small><i className="bx bx-chevron-down" /></>
                              
                              ) : (
                                <>  <small> View Less</small> <i className="bx bx-chevron-up" /></>
                               
                              )}
                            </span>
                          </div>
                       
                      </Link>
                    </td>

                    <td>
                      <div className="d-flex justify-content-end">
                        {products.billingOptionsList.transactional[0]
                          .isWaived ? (
                          <>
                            <small
                              style={{
                                color: "orange",
                                textAlign: "right",
                                textDecoration: "line-through",
                              }}
                            >
                              <b>
                                {" "}
                                {GetOverAllCount(
                                  products.billingOptionsList.transactional
                                )}{" "}
                                -{" "}
                              </b>
                            </small>

                            <small
                              style={{
                                color: "orange",
                                textAlign: "right",
                                textDecoration: "line-through",
                              }}
                            >
                              <b>
                                {" "}
                                {GetOverAllTotal(
                                  products.billingOptionsList.transactional
                                )}{" "}
                                QAR{" "}
                              </b>
                            </small>
                          </>
                        ) : (
                          <>
                            <small
                              style={{ color: "green", textAlign: "right" }}
                            >
                              <b>
                                {" "}
                                {GetOverAllCount(
                                  products.billingOptionsList.transactional
                                )}{" "}
                                -{" "}
                              </b>
                            </small>

                            <small
                              style={{ color: "green", textAlign: "right" }}
                            >
                              <b>
                                {" "}
                                {GetOverAllTotal(
                                  products.billingOptionsList.transactional
                                )}{" "}
                                QAR{" "}
                              </b>
                            </small>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>

                  {map(
                    products.billingOptionsList.transactional,
                    (r, index) => (
                      <tr hidden={transaction}>
                        <td width="10%">
                          <p>
                            <small>
                              <b>{r.officeId}</b>
                            </small>
                            <small>
                              <b>{r.remarks}</b>
                            </small>
                          </p>
                        </td>

                        {map(r.monthlyDetail, (m, index) => (
                          <td
                            width="120px"
                            style={{ textAlign: "right" }}
                            key={index}
                          >
                            <p>
                              {m.count > 0 ? (
                                <small style={{ color: "blue" }}>
                                  {m.count}
                                </small>
                              ) : (
                                <small>{m.count}</small>
                              )}
                              <br></br>
                              <small>
                                <b>
                                  {m.total} <br></br> {m.currency}
                                </b>
                              </small>
                            </p>
                          </td>
                        ))}
                        <td
                          style={{ textAlign: "right", color: "blue" }}
                          width="10%"
                        >
                          <p>
                            <small>
                              <b>{r.rowCount}</b>
                            </small>
                            <br></br>
                            <small>
                              <b>
                                {r.rowTotal} <br></br> {r.currency}
                              </b>
                            </small>
                          </p>
                        </td>
                      </tr>
                    )
                  )}
                </>
              )}

                {/*------------------------ TRANSACTIONAL_1 */}
                {products.billingOptionsList?.transactional_1 && (
                <>
                  <tr>
                    <td colSpan={products.coveredPeriodFxRate.length + 1}>
                      <Link
                        to="#"
                        onClick={Transaction_1Collapse}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="d-flex justify-content-start">
                          
                            <span style={{marginRight:"10px"}}>
                              <b style={{ color: "blue" }}>
                                {" "}
                                {
                                  products.billingOptionsList.transactional_1[0]
                                    .unitOfMeasure
                                }{" "}
                                {products.billingOptionsList.transactional_1[0]
                                  .isWaived ? (
                                  <small style={{ color: "orange" }}>
                                    <b>WAIVED</b>
                                  </small>
                                ) : (
                                  ""
                                )}
                              </b>
                            </span>
                         
                            <span style={{marginRight:"10px"}}>
                              <b>
                                {" "}
                                <small>
                                  {" "}
                                  ({" "}
                                  {
                                    products.billingOptionsList.transactional_1[0]
                                      .price
                                  }{" "}
                                  {
                                    products.billingOptionsList.transactional_1[0]
                                      .priceCurrency
                                  }{" "}
                                  )
                                </small>
                              </b>
                            </span>
                        
                            <span>
                              {transaction_1 ? (
                                <>  <small style={{}}>View More </small><i className="bx bx-chevron-down" /></>
                              
                              ) : (
                                <>  <small> View Less</small> <i className="bx bx-chevron-up" /></>
                               
                              )}
                            </span>
                          </div>
                       
                      </Link>
                    </td>

                    <td>
                      <div className="d-flex justify-content-end">
                        {products.billingOptionsList.transactional_1[0]
                          .isWaived ? (
                          <>
                            <small
                              style={{
                                color: "orange",
                                textAlign: "right",
                                textDecoration: "line-through",
                              }}
                            >
                              <b>
                                {" "}
                                {GetOverAllCount(
                                  products.billingOptionsList.transactional_1
                                )}{" "}
                                -{" "}
                              </b>
                            </small>

                            <small
                              style={{
                                color: "orange",
                                textAlign: "right",
                                textDecoration: "line-through",
                              }}
                            >
                              <b>
                                {" "}
                                {GetOverAllTotal(
                                  products.billingOptionsList.transactional_1
                                )}{" "}
                                QAR{" "}
                              </b>
                            </small>
                          </>
                        ) : (
                          <>
                            <small
                              style={{ color: "green", textAlign: "right" }}
                            >
                              <b>
                                {" "}
                                {GetOverAllCount(
                                  products.billingOptionsList.transactional_1
                                )}{" "}
                                -{" "}
                              </b>
                            </small>

                            <small
                              style={{ color: "green", textAlign: "right" }}
                            >
                              <b>
                                {" "}
                                {GetOverAllTotal(
                                  products.billingOptionsList.transactional_1
                                )}{" "}
                                QAR{" "}
                              </b>
                            </small>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>

                  {map(
                    products.billingOptionsList.transactional_1,
                    (r, index) => (
                      <tr hidden={transaction_1}>
                        <td width="10%">
                          <p>
                            <small>
                              <b>{r.officeId}</b>
                            </small>
                            <small>
                              <b>{r.remarks}</b>
                            </small>
                          </p>
                        </td>

                        {map(r.monthlyDetail, (m, index) => (
                          <td
                            width="120px"
                            style={{ textAlign: "right" }}
                            key={index}
                          >
                            <p>
                              {m.count > 0 ? (
                                <small style={{ color: "blue" }}>
                                  {m.count}
                                </small>
                              ) : (
                                <small>{m.count}</small>
                              )}
                              <br></br>
                              <small>
                                <b>
                                  {m.total} <br></br> {m.currency}
                                </b>
                              </small>
                            </p>
                          </td>
                        ))}
                        <td
                          style={{ textAlign: "right", color: "blue" }}
                          width="10%"
                        >
                          <p>
                            <small>
                              <b>{r.rowCount}</b>
                            </small>
                            <br></br>
                            <small>
                              <b>
                                {r.rowTotal} <br></br> {r.currency}
                              </b>
                            </small>
                          </p>
                        </td>
                      </tr>
                    )
                  )}
                </>
              )}

               {/*------------------------ TRANSACTIONAL_2 */}
               {products.billingOptionsList?.transactional_2 && (
                <>
                  <tr>
                    <td colSpan={products.coveredPeriodFxRate.length + 1}>
                      <Link
                        to="#"
                        onClick={Transaction_2Collapse}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="d-flex justify-content-start">
                          
                            <span style={{marginRight:"10px"}}>
                              <b style={{ color: "blue" }}>
                                {" "}
                                {
                                  products.billingOptionsList.transactional_2[0]
                                    .unitOfMeasure
                                }{" "}
                                {products.billingOptionsList.transactional_2[0]
                                  .isWaived ? (
                                  <small style={{ color: "orange" }}>
                                    <b>WAIVED</b>
                                  </small>
                                ) : (
                                  ""
                                )}
                              </b>
                            </span>
                         
                            <span style={{marginRight:"10px"}}>
                              <b>
                                {" "}
                                <small>
                                  {" "}
                                  ({" "}
                                  {
                                    products.billingOptionsList.transactional_2[0]
                                      .price
                                  }{" "}
                                  {
                                    products.billingOptionsList.transactional_2[0]
                                      .priceCurrency
                                  }{" "}
                                  )
                                </small>
                              </b>
                            </span>
                        
                            <span>
                              {transaction_2 ? (
                                <>  <small style={{}}>View More </small><i className="bx bx-chevron-down" /></>
                              
                              ) : (
                                <>  <small> View Less</small> <i className="bx bx-chevron-up" /></>
                               
                              )}
                            </span>
                          </div>
                       
                      </Link>
                    </td>

                    <td>
                      <div className="d-flex justify-content-end">
                        {products.billingOptionsList.transactional_2[0]
                          .isWaived ? (
                          <>
                            <small
                              style={{
                                color: "orange",
                                textAlign: "right",
                                textDecoration: "line-through",
                              }}
                            >
                              <b>
                                {" "}
                                {GetOverAllCount(
                                  products.billingOptionsList.transactional_2
                                )}{" "}
                                -{" "}
                              </b>
                            </small>

                            <small
                              style={{
                                color: "orange",
                                textAlign: "right",
                                textDecoration: "line-through",
                              }}
                            >
                              <b>
                                {" "}
                                {GetOverAllTotal(
                                  products.billingOptionsList.transactional_2
                                )}{" "}
                                QAR{" "}
                              </b>
                            </small>
                          </>
                        ) : (
                          <>
                            <small
                              style={{ color: "green", textAlign: "right" }}
                            >
                              <b>
                                {" "}
                                {GetOverAllCount(
                                  products.billingOptionsList.transactional_2
                                )}{" "}
                                -{" "}
                              </b>
                            </small>

                            <small
                              style={{ color: "green", textAlign: "right" }}
                            >
                              <b>
                                {" "}
                                {GetOverAllTotal(
                                  products.billingOptionsList.transactional_2
                                )}{" "}
                                QAR{" "}
                              </b>
                            </small>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>

                  {map(
                    products.billingOptionsList.transactional_2,
                    (r, index) => (
                      <tr hidden={transaction_2}>
                        <td width="10%">
                          <p>
                            <small>
                              <b>{r.officeId}</b>
                            </small>
                            <small>
                              <b>{r.remarks}</b>
                            </small>
                          </p>
                        </td>

                        {map(r.monthlyDetail, (m, index) => (
                          <td
                            width="120px"
                            style={{ textAlign: "right" }}
                            key={index}
                          >
                            <p>
                              {m.count > 0 ? (
                                <small style={{ color: "blue" }}>
                                  {m.count}
                                </small>
                              ) : (
                                <small>{m.count}</small>
                              )}
                              <br></br>
                              <small>
                                <b>
                                  {m.total} <br></br> {m.currency}
                                </b>
                              </small>
                            </p>
                          </td>
                        ))}
                        <td
                          style={{ textAlign: "right", color: "blue" }}
                          width="10%"
                        >
                          <p>
                            <small>
                              <b>{r.rowCount}</b>
                            </small>
                            <br></br>
                            <small>
                              <b>
                                {r.rowTotal} <br></br> {r.currency}
                              </b>
                            </small>
                          </p>
                        </td>
                      </tr>
                    )
                  )}
                </>
              )}



            {/*------------------------ UID */}
            {products.billingOptionsList?.uid && (
              <>
               
                  <tr>
                  <td colSpan={products.coveredPeriodFxRate.length + 1}>
                    <Link
                  to="#"
                  onClick={UidCollapse}
                  style={{ cursor: "pointer" }}
                >
                   <div className="d-flex justify-content-start">
                   <span style={{marginRight:"10px"}}>
                        <b style={{ color: "blue" }}>
                          {" "}
                          {
                            products.billingOptionsList.uid[0].unitOfMeasure
                          }{" "}
                          {products.billingOptionsList.uid[0].isWaived ? (
                            <small style={{ color: "orange" }}>
                              <b>WAIVED</b>
                            </small>
                          ) : (
                            ""
                          )}
                        </b>
                      </span>

                      <span style={{marginRight:"10px"}}>
                        <b>
                          {" "}
                          <small>
                            {" "}
                            ( {products.billingOptionsList.uid[0].price}{" "}
                            {products.billingOptionsList.uid[0].priceCurrency} )
                          </small>
                        </b>
                      </span>

                      <span>
                              {uid ? (
                                <>  <small style={{}}>View More </small><i className="bx bx-chevron-down" /></>
                              
                              ) : (
                                <>  <small> View Less</small> <i className="bx bx-chevron-up" /></>
                               
                              )}
                            </span>
                      </div>
                      </Link>
                    </td>

                    <td>
                    <div className="d-flex justify-content-end">
                      {products.billingOptionsList.uid[0].isWaived ? (
                        <>
                          <small
                            style={{
                              color: "orange",
                              textAlign: "right",
                              textDecoration: "line-through",
                            }}
                          >
                            <b>
                              {" "}
                              {GetOverAllCount(
                                products.billingOptionsList.uid
                              )}{" "}
                              -{" "}
                            </b>
                          </small>

                          <small
                            style={{
                              color: "orange",
                              textAlign: "right",
                              textDecoration: "line-through",
                            }}
                          >
                            <b>
                              {" "}
                              {GetOverAllTotal(
                                products.billingOptionsList.uid
                              )}{" "}
                              QAR{" "}
                            </b>
                          </small>
                        </>
                      ) : (
                        <>
                          <small style={{ color: "green", textAlign: "right" }}>
                            <b>
                              {" "}
                              {GetOverAllCount(
                                products.billingOptionsList.uid
                              )}{" "}
                              -{" "}
                            </b>
                          </small>

                          <small style={{ color: "green", textAlign: "right" }}>
                            <b>
                              {" "}
                              {GetOverAllTotal(
                                products.billingOptionsList.uid
                              )}{" "}
                              QAR{" "}
                            </b>
                          </small>
                        </>
                      )}
                      </div>
                    </td>
                  </tr>
           

               
                    {map(products.billingOptionsList.uid, (r, index) => (
                      <tr hidden={uid}>
                        <td width="10%">
                          <p>
                            <small>
                              <b>{r.officeId}</b>
                            </small>
                            <small>
                              <b>{r.remarks}</b>
                            </small>
                          </p>
                        </td>

                        {map(r.monthlyDetail, (m, index) => (
                          <td
                            width="120px"
                            style={{ textAlign: "right" }}
                            key={index}
                          >
                            <p>
                              {m.count > 0 ? (
                                <small style={{ color: "blue" }}>
                                  {m.count}
                                </small>
                              ) : (
                                <small>{m.count}</small>
                              )}
                              <br></br>
                              <small>
                                <b>
                                  {m.total} <br></br> {m.currency}
                                </b>
                              </small>
                            </p>
                          </td>
                        ))}
                        <td
                          style={{ textAlign: "right", color: "blue" }}
                          width="10%"
                        >
                          <p>
                            <small>
                              <b>{r.rowCount}</b>
                            </small>
                            <br></br>
                            <small>
                              <b>
                                {r.rowTotal} <br></br> {r.currency}
                              </b>
                            </small>
                          </p>
                        </td>
                      </tr>
                    ))}
                 
              </>
            )}

                {/*------------------------ OID */}
            {products.billingOptionsList?.oid && (
              <>
               
                  <tr>
                  <td colSpan={products.coveredPeriodFxRate.length + 1}>
                    <Link
                  to="#"
                  onClick={OidCollapse}
                  style={{ cursor: "pointer" }}
                >
                     <div className="d-flex justify-content-start">
                   <span style={{marginRight:"10px"}}>
                        <b style={{ color: "blue" }}>
                          {" "}
                          {
                            products.billingOptionsList.oid[0].unitOfMeasure
                          }{" "}
                          {products.billingOptionsList.oid[0].isWaived ? (
                            <small style={{ color: "orange" }}>
                              <b>WAIVED</b>
                            </small>
                          ) : (
                            ""
                          )}
                        </b>
                      </span>

                      <span style={{marginRight:"10px"}}>
                        <b>
                          {" "}
                          <small>
                            {" "}
                            ( {products.billingOptionsList.oid[0].price}{" "}
                            {products.billingOptionsList.oid[0].priceCurrency} )
                          </small>
                        </b>
                      </span>
                      <span>
                              {oid ? (
                                <>  <small style={{}}>View More </small><i className="bx bx-chevron-down" /></>
                              
                              ) : (
                                <>  <small> View Less</small> <i className="bx bx-chevron-up" /></>
                               
                              )}
                            </span>
                  
</div>
                      </Link>
                    </td>

                    <td>
                    <div className="d-flex justify-content-end">
                      {products.billingOptionsList.oid[0].isWaived ? (
                        <>
                          <small
                            style={{
                              color: "orange",
                              textAlign: "right",
                              textDecoration: "line-through",
                            }}
                          >
                            <b>
                              {" "}
                              {GetOverAllCount(
                                products.billingOptionsList.oid
                              )}{" "}
                              -{" "}
                            </b>
                          </small>

                          <small
                            style={{
                              color: "orange",
                              textAlign: "right",
                              textDecoration: "line-through",
                            }}
                          >
                            <b>
                              {" "}
                              {GetOverAllTotal(
                                products.billingOptionsList.oid
                              )}{" "}
                              QAR{" "}
                            </b>
                          </small>
                        </>
                      ) : (
                        <>
                          <small style={{ color: "green", textAlign: "right" }}>
                            <b>
                              {" "}
                              {GetOverAllCount(
                                products.billingOptionsList.oid
                              )}{" "}
                              -{" "}
                            </b>
                          </small>

                          <small style={{ color: "green", textAlign: "right" }}>
                            <b>
                              {" "}
                              {GetOverAllTotal(
                                products.billingOptionsList.oid
                              )}{" "}
                              QAR{" "}
                            </b>
                          </small>
                        </>
                      )}

</div>
                    </td>
                  </tr>
               
               
                    {map(products.billingOptionsList.oid, (r, index) => (
                      <tr hidden={oid}>
                        <td width="10%">
                          <p>
                            <small>
                              <b>{r.officeId}</b>
                            </small>
                            <small>
                              <b>{r.remarks}</b>
                            </small>
                          </p>
                        </td>

                        {map(r.monthlyDetail, (m, index) => (
                          <td
                            width="120px"
                            style={{ textAlign: "right" }}
                            key={index}
                          >
                            <p>
                              {m.count > 0 ? (
                                <small style={{ color: "blue" }}>
                                  {m.count}
                                </small>
                              ) : (
                                <small>{m.count}</small>
                              )}
                              <br></br>
                              <small>
                                <b>
                                  {m.total} <br></br> {m.currency}
                                </b>
                              </small>
                            </p>
                          </td>
                        ))}
                        <td
                          style={{ textAlign: "right", color: "blue" }}
                          width="10%"
                        >
                          <p>
                            <small>
                              <b>{r.rowCount}</b>
                            </small>
                            <br></br>
                            <small>
                              <b>
                                {r.rowTotal} <br></br> {r.currency}
                              </b>
                            </small>
                          </p>
                        </td>
                      </tr>
                    ))}
                 
              </>
            )}

              {/*------------------------ CORPORATES */}
              {products.billingOptionsList?.corporate && (
              <>
                {map(products.billingOptionsList.corporate, (o, index) => (
                  <>
                  
                      <tr>
                      <td colSpan={products.coveredPeriodFxRate.length + 1}>
                      <Link
                      to="#"
                      onClick={CorporateCollapse}
                      style={{ cursor: "pointer" }}
                    >
                          <div className="d-flex justify-content-start">
                   <span style={{marginRight:"10px"}}>
                            <b style={{ color: "blue" }}>
                              {o.officeId}{" "}
                              {o.isWaived ? (
                                <small style={{ color: "orange" }}>
                                  <b>WAIVED</b>
                                </small>
                              ) : (
                                ""
                              )}
                            </b>
                          </span>

                          <span style={{marginRight:"10px"}}>
                            <b>
                              {" "}
                              <small>
                                {" "}
                                ( {o.corporates[0].price}{" "}
                                {o.corporates[0].priceCurrency} )
                              </small>
                            </b>
                          </span>

                          <span>
                              {corporate ? (
                                <>  <small style={{}}>View More </small><i className="bx bx-chevron-down" /></>
                              
                              ) : (
                                <>  <small> View Less</small> <i className="bx bx-chevron-up" /></>
                               
                              )}
                            </span>
                          </div>
                          </Link>
                        </td>

                        <td>
                        <div className="d-flex justify-content-end">
                          {o.corporates[0].isWaived ? (
                            <>
                              <small
                                style={{
                                  color: "orange",
                                  textAlign: "right",
                                  textDecoration: "line-through",
                                }}
                              >
                                <b> {GetOverAllCount(o.corporates)} - </b>
                              </small>

                              <small
                                style={{
                                  color: "orange",
                                  textAlign: "right",
                                  textDecoration: "line-through",
                                }}
                              >
                                <b> {GetOverAllTotal(o.corporates)} QAR </b>
                              </small>
                            </>
                          ) : (
                            <>
                              <small
                                style={{ color: "green", textAlign: "right" }}
                              >
                                <b> {GetOverAllCount(o.corporates)} - </b>
                              </small>

                              <small
                                style={{ color: "green", textAlign: "right" }}
                              >
                                <b> {GetOverAllTotal(o.corporates)} QAR </b>
                              </small>
                            </>
                          )}
                          </div>
                        </td>
                      </tr>
              
                        {map(o.corporates, (corp, index) => (
                          <tr hidden={corporate}>
                            <td width="10%">
                              <p>
                                <small>
                                  <b>{corp.remarks}</b>
                                </small>
                              </p>
                            </td>

                            {map(corp.monthlyDetail, (m, index) => (
                              <td
                                width="120px"
                                style={{ textAlign: "right" }}
                                key={index}
                              >
                                <p>
                                  {m.count > 0 ? (
                                    <small style={{ color: "blue" }}>
                                      {m.count}
                                    </small>
                                  ) : (
                                    <small>{m.count}</small>
                                  )}
                                  <br></br>
                                  <small>
                                    <b>
                                      {m.total} <br></br> {m.currency}
                                    </b>
                                  </small>
                                </p>
                              </td>
                            ))}
                            <td
                              style={{ textAlign: "right", color: "blue" }}
                              width="10%"
                            >
                              <p>
                                <small>
                                  <b>{corp.rowCount}</b>
                                </small>
                                <br></br>
                                <small>
                                  <b>
                                    {corp.rowTotal} <br></br> {corp.currency}
                                  </b>
                                </small>
                              </p>
                            </td>
                          </tr>
                        ))}
                     
                  </>
                ))}
              </>
            )}

             {/*------------------------ ADD ON PRODUCTS */}
             {products.billingOptionsList?.addOns && (
              <>
                
                  <tr>
                  <td colSpan={products.coveredPeriodFxRate.length + 1}>
                    <Link
                  to="#"
                  onClick={AddOnCollapse}
                  style={{ cursor: "pointer" }}
                >
                      <div className="d-flex justify-content-start">
                   <span style={{marginRight:"10px"}}>
                        <b style={{ color: "blue" }}>
                          {" "}
                          {
                            products.billingOptionsList.addOns[0].unitOfMeasure
                          }{" "}
                          {products.billingOptionsList.addOns[0].isWaived ? (
                            <small style={{ color: "orange" }}>
                              <b>WAIVED</b>
                            </small>
                          ) : (
                            ""
                          )}
                        </b>
                      </span>

                      <span style={{marginRight:"10px"}}>
                        <b>
                          {" "}
                          <small>
                            {" "}
                            ( {products.billingOptionsList.addOns[0].price}{" "}
                            {
                              products.billingOptionsList.addOns[0]
                                .priceCurrency
                            }{" "}
                            )
                          </small>
                        </b>
                      </span>

                      <span>
                              {addOn ? (
                                <>  <small style={{}}>View More </small><i className="bx bx-chevron-down" /></>
                              
                              ) : (
                                <>  <small> View Less</small> <i className="bx bx-chevron-up" /></>
                               
                              )}
                            </span>
                          </div>
                          </Link>
                        </td>

                        <td>
                        <div className="d-flex justify-content-end">
                      {products.billingOptionsList.addOns[0].isWaived ? (
                        <>
                          <small
                            style={{
                              color: "orange",
                              textAlign: "right",
                              textDecoration: "line-through",
                            }}
                          >
                            <b>
                              {" "}
                              {GetOverAllCount(
                                products.billingOptionsList.addOns
                              )}{" "}
                              -{" "}
                            </b>
                          </small>

                          <small
                            style={{
                              color: "orange",
                              textAlign: "right",
                              textDecoration: "line-through",
                            }}
                          >
                            <b>
                              {" "}
                              {GetOverAllTotal(
                                products.billingOptionsList.addOns
                              )}{" "}
                              QAR{" "}
                            </b>
                          </small>
                        </>
                      ) : (
                        <>
                          <small style={{ color: "green", textAlign: "right" }}>
                            <b>
                              {" "}
                              {GetOverAllCount(
                                products.billingOptionsList.addOns
                              )}{" "}
                              -{" "}
                            </b>
                          </small>

                          <small style={{ color: "green", textAlign: "right" }}>
                            <b>
                              {" "}
                              {GetOverAllTotal(
                                products.billingOptionsList.addOns
                              )}{" "}
                              QAR{" "}
                            </b>
                          </small>
                        </>
                      )}
                      </div>
                    </td>
                  </tr>
               
               
                    {map(products.billingOptionsList.addOns, (r, index) => (
                      <tr hidden={addOn}>
                        <td width="10%">
                          <p>
                            <small>
                              <b>{r.officeId}</b>
                            </small>
                            <small>
                              <b>{r.remarks}</b>
                            </small>
                          </p>
                        </td>

                        {map(r.monthlyDetail, (m, index) => (
                          <td
                            width="120px"
                            style={{ textAlign: "right" }}
                            key={index}
                          >
                            <p>
                              {m.count > 0 ? (
                                <small style={{ color: "blue" }}>
                                  {m.count}
                                </small>
                              ) : (
                                <small>{m.count}</small>
                              )}
                              <br></br>
                              <small>
                                <b>
                                  {m.total} <br></br> {m.currency}
                                </b>
                              </small>
                            </p>
                          </td>
                        ))}
                        <td
                          style={{ textAlign: "right", color: "blue" }}
                          width="10%"
                        >
                          <p>
                            <small>
                              <b>{r.rowCount}</b>
                            </small>
                            <br></br>
                            <small>
                              <b>
                                {r.rowTotal} <br></br> {r.currency}
                              </b>
                            </small>
                          </p>
                        </td>
                      </tr>
                    ))}
                 
              </>
            )}
            </Table>





          

         
          


          

          

           
          </CardBody>
        </Card>
      )}
    </>
  )
}

export default Products
