import classnames from "classnames"
import "flatpickr/dist/themes/material_blue.css"
import React, { useState, useEffect } from "react"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom"
import moment from 'moment'
import {
  Button,
  CardText,
  Col,
  Collapse,
  Form,
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { v4 as uuidv4 } from "uuid"
import { isEmpty } from "lodash"
import { Content } from "./Content"
import { IncentiveSlab } from "./IncentiveSlab"
import OtherCharges from "./OtherCharges"
import { ProductsSolutions } from "./ProductsSolutions"
import { useForm } from "react-hook-form"
import { FiscalYearsModal } from "./FiscalYearsModal"
import {dateFormatDDMMMYYYY, dateISO, utcToLocal, localToUtc,utcOffset} from "../../../helpers/commonhelpers/dateFormat"

 const AgreementList = props => {
  const {
    agreementId,
    index,
    customerId,
    agreement,
    optionsList,
    productList,
    otherCharges,
    handleAgreementChange,
    handleOtherChargesListChange,
    handleAgreementDelete,
    toggleTab,
    activeTab,
    handleSubmit,
    onSubmit,
    register,
    errors,
    Modal,
  } = props
  const inputAgreementId = "AgreementId" + index + agreementId
  const inputSignedDate = "SignedDate" + index + agreementId
  const inputEffectiveDate = "EffectiveDate" + index + agreementId
  const inputMonths = "Months" + index + agreementId
  const inputEndDate = "EndDate" + index + agreementId
  const inputLaterToAddOtherCharges =
    "LaterToAddOtherCharges" + index + agreementId

  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [addConfigDetails, setAddConfigDetails] = useState(false)
  const [airlineOptions, setAirlineOptions] = useState([])
  const [unitOfMeasureOptions, setUnitOfMeasureOptions] = useState([])
  const [unitOfMeasureRemarksOptions, setUnitOfMeasureRemarksOptions] =useState([])
  const [effectiveDate, setEffectiveDate] = useState()
  const [endDate, setEndDate] = useState()
  const [months, setMonths] = useState()
  const [laterToAdd, setLaterToAdd] = useState(undefined)
  const [finalFiscalYear, setFinalFiscalYear] = useState([])
  const [modal, setmodal] = useState(false)
  const [signedDate, setSignedDate]=useState()
 

  useEffect(() => {
    handleChange({SignedDate:signedDate})
  }, [signedDate])


  useEffect(() => {
    if (optionsList) {
      var airlineList = optionsList.filter(x => x.label === "Airlines")[0]

      var unitOfMeasureList = optionsList.filter(
        x => x.label === "Unit Of Measure"
      )[0]
      var unitOfMeasureRemarksList = optionsList.filter(
        x => x.label === "Unit Of Measure Remarks"
      )[0]
      if (!isEmpty(airlineList)) {
        setAirlineOptions(airlineList.options)
      }
      if (!isEmpty(unitOfMeasureList)) {
        setUnitOfMeasureOptions(unitOfMeasureList.options)
      }
      if (!isEmpty(unitOfMeasureRemarksList)) {
        setUnitOfMeasureRemarksOptions(unitOfMeasureRemarksList.options)
      }
    }
  }, [optionsList])

  useEffect(() => {
    if(effectiveDate){
      const startDate = new Date(effectiveDate)

      const monthsDividedByYear = agreement.Months / 12
      let year = 0

      const dateList = new Array()
      dateList.push(startDate)

      while (year < monthsDividedByYear) {
        const prevDate = new Date(dateList[year])
        const currentDate = new Date(
          prevDate?.setMonth(prevDate?.getMonth() + 12)
        )

        dateList.push(currentDate)

        year++
      }

      if (!isEmpty(dateList)) {
        const fiscalYearList = new Array()
        const dateListCopy = [...dateList]

        for (var i = 0; i < dateListCopy.length - 1; i++) {
          var count = i
          const fiscalStart = dateListCopy[count]
          const fiscalEnd = dateListCopy[count + 1]
          const fiscalEnd2 = new Date(
            fiscalEnd.getFullYear(),
            fiscalEnd.getMonth() - 1
          ) //get previous month's 1st day
          const fiscalDate3 = new Date(
            fiscalEnd2.getFullYear(),
            fiscalEnd2.getMonth() + 1,
            0
          ) //get previous month's last day

          const fiscalYear = {
            id: uuidv4(),
            From: fiscalStart,
            // To: utcOffset(fiscalDate3),
            To: fiscalDate3.addHours(12),
          }

          fiscalYearList.push(fiscalYear)
        }
        handleChange({  EffectiveDate: effectiveDate})
        setFinalFiscalYear(fiscalYearList)
      }
    }
  }, [effectiveDate, months])

  useEffect(() => {
    if (!isEmpty(finalFiscalYear))
      handleChange({
        EndDate: finalFiscalYear[finalFiscalYear.length - 1]?.To,
        FiscalYearsDates: finalFiscalYear,
      })
  }, [finalFiscalYear])

  
  // const getEndDate = effectiveDate => {
   
  //   setEffectiveDate(effectiveDate)
  //   handleChange({  EffectiveDate: effectiveDate})
  // }

  function handleMonths(months) {
    setEffectiveDate(agreement.EffectiveDate)
    setMonths(months)
    handleChange({ Months: months })
  }


  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const addConfigDetailsCollapse = () => {
    setAddConfigDetails(!addConfigDetails)
  }

  function handleChange(changes) {
    handleAgreementChange(agreement.id, { ...agreement, ...changes })
  }

  function handleChange_OtherCharges(changes) {
    handleOtherChargesListChange({ ...otherCharges, ...changes })
  }

  function handleOtherChargesChange(id, charge) {
    const newCharges = [...otherCharges.OtherCharges]
    const index = newCharges.findIndex(i => i.id === id)
    newCharges[index] = charge
    handleChange_OtherCharges({ OtherCharges: newCharges })
  }

  function handleOtherChargesDelete(id) {
    handleChange_OtherCharges({
      OtherCharges: otherCharges.OtherCharges.filter(i => i.id !== id),
    })
  }

  function handleOtherChargesAdd() {
    const newCharge = {
      id: uuidv4(),
      CustomerId: customerId,
      AgreementId: agreement.AgreementId,
      FiscalYear: {
        From: new Date().toISOString(),
        To: new Date().toISOString(),
      },
      ChargeName: "",
      BillingOption: "",
      MonthlyCharge: [
        {
          id: uuidv4(),
          Date: new Date().toISOString(),
          Amount: 0.0,
          Currency: "QAR",
        },
      ],
      AnnualCharge: {
        Date: new Date().toISOString(),
        Amount: 0.0,
        Currency: "QAR",
      },
      DateCreated: new Date().toISOString(),
      DateUpdated: new Date().toISOString(),
      Remarks: "",
      IsWaived: false,
    }

    handleChange_OtherCharges({
      OtherCharges: [...otherCharges.OtherCharges, newCharge],
    })
  }

  function handleContentDelete(id) {
    handleChange({
      ContentConfiguration: agreement.ContentConfiguration.filter(
        i => i.id !== id
      ),
    })
  }

  function handleIncentiveDelete(id) {
    handleChange({
      IncentiveConfiguration: agreement.IncentiveConfiguration.filter(
        i => i.id !== id
      ),
    })
  }

  function handleProductDelete(id) {
    handleChange({
      ProductsSolutionsConfiguration:
        agreement.ProductsSolutionsConfiguration.filter(i => i.id !== id),
    })
  }

  function handleContentAdd() {
    const newContent = {
      id: uuidv4(),
      ContentType: "",
      Airlines: [],
      IsExcluded: true,
    }

    handleChange({
      ContentConfiguration: [...agreement.ContentConfiguration, newContent],
    })
  }

  function handleIncentiveAdd() {
    const newIncentive = {
      id: uuidv4(),
      MinimumBooking: 0,
      MaximumBooking: 100,
      AmountRate: 1,
      Currency: "QAR",
    }
    handleChange({
      IncentiveConfiguration: [
        ...agreement.IncentiveConfiguration,
        newIncentive,
      ],
    })
  }

  function handleProductAdd() {
    const newProduct = {
      id: uuidv4(),
      ProductName: "",
      ProductCode: "",
      Location: "QATAR",
      TotalPrice: 0,
      BillingOptions: [],
    }
    handleChange({
      ProductsSolutionsConfiguration: [
        ...agreement.ProductsSolutionsConfiguration,
        newProduct,
      ],
    })
  }

  function handleContentChange(id, content) {
    const newContents = [...agreement.ContentConfiguration]
    const index = newContents.findIndex(i => i.id === id)
    newContents[index] = content
    handleChange({ ContentConfiguration: newContents })
  }

  function handleIncentiveChange(id, incentive) {
    const newIncentives = [...agreement.IncentiveConfiguration]
    const index = newIncentives.findIndex(i => i.id === id)
    newIncentives[index] = incentive
    handleChange({ IncentiveConfiguration: newIncentives })
  }

  function handleProductChange(id, product) {
    const newProducts = [...agreement.ProductsSolutionsConfiguration]
    const index = newProducts.findIndex(i => i.id === id)
    newProducts[index] = product
    handleChange({ ProductsSolutionsConfiguration: newProducts })
  }

  function toggleModal() {
    setmodal(!modal)
  }


  Date.prototype.addHours= function(h){
    this.setHours(this.getHours()+h);
    return this;
}

  return (
    <React.Fragment>
    
    <FiscalYearsModal
           
            isOpen={modal}
            toggle={toggleModal}
            fiscalYears = {finalFiscalYear}
          />

    
      <Form onSubmit={handleSubmit(onSubmit)} id="my-form">
        <Row key={index}>
          <div className="d-flex justify-content-start align-self-center">
            <Link to="#" onClick={() => handleAgreementDelete(agreement.id)}>
              <i
                className="fas fa-window-close fa-2x"
                style={{ color: "red" }}
              />
            </Link>
          </div>

          <Col lg="1">
            <FormGroup>
              <Label>#</Label>
              <input
                type="text"
                value={agreement.AgreementId}
                // onChange={e => handleChange({ AgreementId: e.target.value })}
                name={inputAgreementId}
                className="form-control"
                id="agreementid"
                readOnly
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />

              <small className="color-danger">
                {errors[inputAgreementId] && errors[inputAgreementId].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="3">
            <FormGroup>
              <Label>Date Signed</Label>

              <Flatpickr
                className="form-control d-block"
                value={agreement.SignedDate}
                onChange={e => setSignedDate(e[0].addHours(12))}
                name={inputSignedDate}
                id="signeddate"
                placeholder="Select Date"
                options={{
                  altInput: true,
                  altFormat: "F j, Y",
                  dateFormat: "Y-m-d",
                }}
                // ref={register({
                //   required: {
                //     value: true,
                //     message: "This is required",
                //   },
                // })}
              />

              {/* <small className="color-danger">
                {errors[inputSignedDate] && errors[inputSignedDate].message}
              </small> */}
            </FormGroup>
          </Col>

          <Col lg="3">
            <FormGroup>
              <Label>Effective Date</Label>

              <Flatpickr
                className="form-control d-block"
                value={agreement.EffectiveDate}
                onChange={e => setEffectiveDate(e[0].addHours(12))}
                name={inputEffectiveDate}
                id="effectivedate"
                placeholder="Select Date"
                options={{
                  altInput: true,
                  altFormat: "F j, Y",
                  dateFormat: "Y-m-d",
                }}

                // ref={register({
                //   required: {
                //     value: true,
                //     message: "This is required",
                //   },
                // })}
              />

              {/* <small className="color-danger">
                {errors[inputEffectiveDate] &&
                  errors[inputEffectiveDate].message}
              </small> */}
            </FormGroup>
          </Col>

          <Col lg="1">
            <FormGroup>
              <Label>Months</Label>
              <select
                className="custom-select"
                name={inputMonths}
                value={agreement.Months}
                onChange={e => handleMonths(parseInt(e.target.value))}
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              >
                <option value="0" hidden="hidden">
                  Choose
                </option>
                <option value="12">12</option>
                <option value="24">24</option>
                <option value="36">36</option>
                <option value="48">48</option>
              </select>
              <small className="color-danger">
                {errors[inputMonths] && errors[inputMonths].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="2">
            <FormGroup>
              <Label>
                End Date 

                <Link
                to="#"
                onClick={() => {
                  toggleModal()
                }}
                className="right-bar-toggle float-right"
              >
                ( Show Fiscal Years )
              </Link>


              </Label>
              

              <Flatpickr
                className="form-control d-block"
                name={inputEndDate}
                id="enddate"
                value={agreement.EndDate}
                // onChange={e => handleChange({ EndDate: e[0] })}
                placeholder="Select Effective Date First"
                options={{
                  altInput: true,
                  altFormat: "F j, Y",
                  dateFormat: "Y-m-d",
                }}
                disabled
                // ref={register({
                //   required: {
                //     value: true,
                //     message: "This is required",
                //   },
                // })}
              />

              {/* <small className="color-danger">
                {errors[inputEndDate] && errors[inputEndDate].message}
              </small> */}
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Label>.</Label>
              <Button
                onClick={addConfigDetailsCollapse}
                color="success"
                className="btn-block inner"
                style={{ width: "100%" }}
              >
                <i className="bx bx-caret-down mr-2" />
                See More
              </Button>
            </FormGroup>
          </Col>
        </Row>

        <Collapse isOpen={addConfigDetails}>
          <Row className="mt-4">
            <Col>
              <>
                <Nav tabs className="nav-tabs-custom nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        <b>Set Content</b>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-user"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        <b>Set Incentive Slab</b>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "3",
                      })}
                      onClick={() => {
                        toggleCustom("3")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-envelope"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        <b> Set Products & Solutions</b>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "4",
                      })}
                      onClick={() => {
                        toggleCustom("4")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-cog"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        <b>Set Other Charges</b>
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent
                  activeTab={customActiveTab}
                  className="p-3 text-muted"
                >
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        {agreement.ContentConfiguration.map((content, i) => (
                          <Content
                            agreementIndex={index}
                            index={i}
                            key={content.id}
                            content={content}
                            airlinesOptions={airlineOptions}
                            handleContentChange={handleContentChange}
                            handleContentDelete={handleContentDelete}
                            handleSubmit={handleSubmit}
                            onSubmit={onSubmit}
                            register={register}
                            errors={errors}
                          />
                        ))}

                        <Button
                          onClick={() => handleContentAdd()}
                          color="success"
                          className="mt-1"
                        >
                          + Add New Content
                        </Button>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <Label>Set Slab</Label>

                        <Row>
                          <Col lg="3">Minimum Booking</Col>
                          <Col lg="3">Maximum Booking</Col>
                          <Col lg="2">Rate</Col>
                          <Col lg="2">Currency</Col>
                          <Col lg="2"></Col>
                        </Row>
                        <br></br>

                        {agreement.IncentiveConfiguration.map(
                          (incentive, i) => (
                            <IncentiveSlab
                              agreementIndex={index}
                              index={i}
                              key={incentive.id}
                              incentive={incentive}
                              handleIncentiveChange={handleIncentiveChange}
                              handleIncentiveDelete={handleIncentiveDelete}
                              handleSubmit={handleSubmit}
                              onSubmit={onSubmit}
                              register={register}
                              errors={errors}
                            />
                          )
                        )}

                        <Button
                          onClick={() => handleIncentiveAdd()}
                          color="success"
                          className="mt-1"
                        >
                          + Add New Slab
                        </Button>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        <Label>
                          <p>Add Subscribed Products</p>
                        </Label>
                        {agreement.ProductsSolutionsConfiguration.map(
                          (product, i) => (
                            <ProductsSolutions
                              agreementIndex={index}
                              index={i}
                              key={product.id}
                              product={product}
                              productList={productList}
                              unitOfMeasureOptions={unitOfMeasureOptions}
                              unitOfMeasureRemarksOptions={
                                unitOfMeasureRemarksOptions
                              }
                              handleProductChange={handleProductChange}
                              handleProductDelete={handleProductDelete}
                              handleSubmit={handleSubmit}
                              onSubmit={onSubmit}
                              register={register}
                              errors={errors}
                            />
                          )
                        )}
                        <Button
                          onClick={() => handleProductAdd()}
                          color="success"
                          className="mt-1"
                        >
                          + Add A Product
                        </Button>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="4">
                    <Row>
                      <Col sm="12">
                        <Button
                          onClick={() => handleOtherChargesAdd()}
                          color="success"
                          className="mt-1"
                        >
                          + Add Other Charges
                        </Button>
                      </Col>
                      <Col sm="12">
                        <div className="d-flex justify-content-end">
                          <div className="square-switch">
                            <input
                              name={inputLaterToAddOtherCharges}
                              type="checkbox"
                              id="square-switch1"
                              switch="none"
                              checked={laterToAdd}
                              onChange={() => {
                                setLaterToAdd(!laterToAdd)
                              }}
                              ref={register({
                                required: {
                                  value: true,
                                  message: "This is required",
                                },
                              })}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label="Yes"
                              data-off-label="No"
                            ></label>

                            <br></br>
                            <small className="color-danger">
                              {errors[inputLaterToAddOtherCharges] &&
                                errors[inputLaterToAddOtherCharges].message}
                            </small>
                          </div>
                          <b>I confirm the charges below.</b>
                          <br></br> <br></br>
                        </div>

                        {otherCharges.OtherCharges.map((charge, index) => (
                          <>
                            <OtherCharges
                              customerId={customerId}
                              agreementIndex={index}
                              index={index}
                              key={charge.id}
                              charge={charge}
                              agreementId={agreement.AgreementId}
                              handleOtherChargesChange={
                                handleOtherChargesChange
                              }
                              handleOtherChargesDelete={
                                handleOtherChargesDelete
                              }
                            />

                            <hr></hr>
                          </>
                        ))}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </>
            </Col>
          </Row>
        </Collapse>

        <hr style={{ border: "1px solid gray" }}></hr>
        <br></br>
        <br></br>
      </Form>
    </React.Fragment>
  )
}

export default AgreementList
