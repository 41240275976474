import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Table,
} from "reactstrap"
import { v4 as uuidv4 } from "uuid"
import BillingOptionsModal from "./BillingOptionsModal"
import { isEmpty } from "lodash"
import SlabRateModal from "./SlabRateModal"

export const ProductsSolutions = props => {
  const {
    agreementIndex,
    index,
    product,
    productList,
    unitOfMeasureOptions,
    unitOfMeasureRemarksOptions,
    handleProductChange,
    handleProductDelete,
    handleSubmit,
    onSubmit,
    register,
    errors,
  } = props

  const [modal, setModal] = useState(false)
  const [slabRateModal, setSlabRateModal] = useState(false)
  const [selectedBillingOptionId, setSelectedBillingOptionId] = useState()
  const [selectedbillingOption, setSelectedBillingOption] = useState({})
  const [selectedProductName, setSelectedProductName] = useState({})
  const [selectedProduct, setSelectedProduct] = useState({})

  const inputProductName = "ProductName" + index + agreementIndex
  const inputProductCode = "ProductCode" + index + agreementIndex
  const inputLocation = "Location" + index + agreementIndex

  useEffect(() => {}, [slabRateModal, selectedbillingOption, modal])

  useEffect(() => {
    const billingOptionList = product.BillingOptions.filter(
      i => i.id === selectedBillingOptionId
    )
    const billingOption = billingOptionList.filter(i => !isEmpty(i.SlabRate))

    setSelectedBillingOption(billingOption[0])
  }, [selectedBillingOptionId])

  useEffect(() => {
    if (selectedProductName) {
      var productDetail = productList?.filter(
        o => o.alias === selectedProductName
      )[0]

      handleChange({ ProductCode: productDetail?.productCode })
      setSelectedProduct(productDetail)
    }
  }, [selectedProductName])

  const toggleModal = () => {
    setModal(!modal)
  }

  const toggleSlabRateModal = () => {
    setSlabRateModal(!slabRateModal)
  }

  function handleChange(changes) {
    handleProductChange(product.id, { ...product, ...changes })
  }

  function handleBillingOptionsChange(id, billingOption) {
    const newBillingOptions = [...product.BillingOptions]
    const index = newBillingOptions.findIndex(i => i.id === id)
    newBillingOptions[index] = billingOption
    handleChange({ BillingOptions: newBillingOptions })
  }

  const handleBillingOptionsAdd = newBillingOption => {
    handleChange({
      BillingOptions: [...product.BillingOptions, newBillingOption],
    })
    toggleModal()
  }

  function handleBillingOptionsDelete(id) {
    handleChange({
      BillingOptions: product.BillingOptions.filter(i => i.id !== id),
    })
  }

  function handleViewRates(billingOptionId) {
    setSelectedBillingOptionId(billingOptionId)
    toggleSlabRateModal()
  }

  function handleProductName(value) {
    handleChange({ ProductName: value })
    setSelectedProductName(value)
  }

  function handleBillingOptionsModal(){

   
    if(!isEmpty(selectedProductName) ){
    toggleModal()}

  }

  return (
    <React.Fragment>
      <Card style={{ backgroundColor: "#ebf3fa" }}>
        <CardBody>
          <SlabRateModal
            isOpen={slabRateModal}
            toggle={toggleSlabRateModal}
            billingOption={selectedbillingOption}
          />
          <BillingOptionsModal
            key={product.id + index}
            index={index}
            id={uuidv4()}
            isOpen={modal}
            toggle={toggleModal}
            productList={productList}
            product={product}
            unitOfMeasureOptions={unitOfMeasureOptions}
            unitOfMeasureRemarksOptions={unitOfMeasureRemarksOptions}
            handleBillingOptionsChange={handleBillingOptionsChange}
            handleBillingOptionsAdd={handleBillingOptionsAdd}
          />

          {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
          <div>
            <Row>
              <Col>
                <div className="d-flex justify-content-end">
                  <Link to="#" onClick={() => handleProductDelete(product.id)}>
                    <i
                      className="fas fa-window-close fa-2x"
                      style={{ color: "red" }}
                    />
                  </Link>
                </div>
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <select
                    className="custom-select"
                    value={product.ProductName}
                    onChange={e => handleProductName(e.target.value)}
                    name={inputProductName}
                    ref={register({
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    })}
                  >
                    <option value="" hidden="hidden">
                      Choose here
                    </option>

                    {productList?.map((product, index) => (
                      <option key={index} value={product.alias}>
                        {product.productName}
                      </option>
                    ))}
                  </select>

                  <small className="color-danger">
                    {errors[inputProductName] &&
                      errors[inputProductName].message}
                  </small>
                </FormGroup>
              </Col>

              <Col lg="3">
                <FormGroup>
                  <input
                    readOnly
                    value={product.ProductCode}
                    // onChange={e =>
                    //   handleChange({ ProductCode: e.target.value })
                    // }
                    type="text"
                    name={inputProductCode}
                    className="form-control"
                    placeholder="Product Code"
                    ref={register({
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    })}
                  />

                  <small className="color-danger">
                    {errors[inputProductCode] &&
                      errors[inputProductCode].message}
                  </small>
                </FormGroup>
              </Col>

              <Col lg="2">
                <FormGroup>
                  <select
                    className="custom-select"
                    name={inputLocation}
                    value={product.Location}
                    onChange={e => handleChange({ Location: e.target.value })}
                    ref={register({
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    })}
                  >
                    <option value="QATAR">QATAR</option>
                  </select>
                </FormGroup>
              </Col>
              <Col lg="3">
               
                <Button
                  onClick={() => handleBillingOptionsModal()}
                  color="success"
                  className="btn-block inner"
                  style={{ width: "100%" }}
                >
                  + Add BillingOptions
                </Button>

                {isEmpty(selectedProductName) &&
              <small className="color-danger">Please select a product first.</small>}
              </Col>
            </Row>
            {!isEmpty(product?.BillingOptions) && (
              <Row>
                <Col>
                  <Table>
                    <thead>
                      <tr>
                        <th>Unit of Measure</th>

                        <th>Unit of Measure Remarks</th>

                        <th>Price</th>

                        <th>DiscountedPrice</th>

                        <th>Slab Rate</th>

                        <th>Currency</th>

                        <th>Frequency</th>

                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {product.BillingOptions.map((billingOption, index) => (
                      <tr key={'OPT' + product.id + index }>
                          {/* <td>{billingOption.id}</td> */}
                          <td>{billingOption.UnitMeasure}</td>
                          <td>{billingOption.UnitMeasureRemarks}</td>
                          <td>{billingOption.Price}</td>
                          <td>{billingOption.DiscountedPrice}</td>
                          <td>
                            {billingOption?.IsPricedBySlab === false ? (
                              "N/A"
                            ) : (
                              <>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    handleViewRates(billingOption.id)
                                  }
                                >
                                  <span>View Rates</span>
                                </Link>
                              </>
                            )}
                          </td>
                          <td>{billingOption.Currency}</td>
                          <td>{billingOption.Frequency}</td>
                          <td>
                            {billingOption.IsWaived ? "Waived" : "Billed"}
                          </td>
                          <td>
                            <Link
                              to="#"
                              onClick={() =>
                                handleBillingOptionsDelete(billingOption.id)
                              }
                            >
                              <i
                                className="fas fa-window-close fa-lg"
                                style={{ color: "red" }}
                              />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
          {/* </Form> */}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsSolutions)
