/* INVOICES */
export const GET_INCENTIVE_DUE_CALCULATION_SUMMARY = "GET_INCENTIVE_DUE_CALCULATION_SUMMARY"
export const GET_INCENTIVE_DUE_CALCULATION_SUMMARY_SUCCESS = "GET_INCENTIVE_DUE_CALCULATION_SUMMARY_SUCCESS"
export const GET_INCENTIVE_DUE_CALCULATION_SUMMARY_FAIL = "GET_INCENTIVE_DUE_CALCULATION_SUMMARY_FAIL"

export const GET_TICKETED_SEGMENTS = "GET_TICKETED_SEGMENTS"
export const GET_TICKETED_SEGMENTS_SUCCESS = "GET_TICKETED_SEGMENTS_SUCCESS"
export const GET_TICKETED_SEGMENTS_FAIL = "GET_TICKETED_SEGMENTS_FAIL"

export const GET_UNPAID_AIRLINES = "GET_UNPAID_AIRLINES"
export const GET_UNPAID_AIRLINES_SUCCESS = "GET_UNPAID_AIRLINES_SUCCESS"
export const GET_UNPAID_AIRLINES_FAIL = "GET_UNPAID_AIRLINES_FAIL"


export const GET_UID_GRAPHIC_USAGE = "GET_UID_GRAPHIC_USAGE"
export const GET_UID_GRAPHIC_USAGE_SUCCESS = "GET_UID_GRAPHIC_USAGE_SUCCESS"
export const GET_UID_GRAPHIC_USAGE_FAIL = "GET_UID_GRAPHIC_USAGE_FAIL"

export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"