import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import AuthUser from "components/AuthUser/AuthUser"
import { Redirect } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Col, Row, Container, Card, CardBody, FormGroup } from "reactstrap"
import Select from "react-select"
import { useDispatch } from "react-redux"
import { connect } from "react-redux"
import {
  getSettlementList,
  setSettlement,
  updateSettlement,
} from "store/actions"
import makeAnimated from "react-select/animated"
import SweetAlert from "react-bootstrap-sweetalert"
const animatedComponents = makeAnimated()
import ListSettlement from "./SettlementList/List"
// const multi = {

//   filtered: [],
//   select2: undefined

// }
const index = props => {
  const {
    settlementCreate,
    settlementDelete,
    settlementUpdate,
    onGetSettlementList,
    settlementList,
    fileUpload,
    fileNameBack,
    onSetSettlement,
    settlement,
    onUpdateSettlement,
  } = props

  const [settlementListAll, setSettlementListAll] = useState([])
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [user, setUser] = useState(AuthUser())
  //const [selectedSettlement, setSelectedSettlement] = useState()

  useEffect(() => {
    onGetSettlementList()
  }, [])

  useEffect(() => {}, [dynamic_title, dynamic_description])

  useEffect(() => {
    if (settlementList) {
      setSettlementListAll(settlementList)
    }
  }, [settlementList])

  useEffect(() => {}, [settlementListAll])
  useEffect(() => {}, [settlement])
  useEffect(() => {}, [fileUpload])

  useEffect(() => {
    if (settlementCreate === true) {
      onGetSettlementList()
      setsuccess_dlg(settlementCreate)
      setdynamic_title("Success")
      setdynamic_description("Settlement successfully created.")
    }
    if (settlementCreate === false) {
      seterror_dlg(settlementCreate)
      setdynamic_title("Fail")
      setdynamic_description("Settlement not created.")
    }
  }, [settlementCreate])

  useEffect(() => {
    if (settlementUpdate === true) {
      onGetSettlementList()
      setsuccess_dlg(settlementUpdate)
      setdynamic_title("Success")
      setdynamic_description("Settlement successfully updated.")
    }
    if (settlementUpdate === false) {
      seterror_dlg(settlementUpdate)
      setdynamic_title("Fail")
      setdynamic_description("Settlement not updated.")
    }
  }, [settlementUpdate])

  useEffect(() => {
    if (settlementDelete === true) {
      onGetSettlementList()
      setsuccess_dlg(settlementDelete)
      setdynamic_title("Success")
      setdynamic_description("Settlement successfully deleted.")
    }
    if (settlementDelete === false) {
      seterror_dlg(settlementDelete)
      setdynamic_title("Fail")
      setdynamic_description("Settlement not deleted.")
    }
  }, [settlementDelete])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem=" Payment Settlement" />
          {success_dlg ? (
            <SweetAlert
              timeout={1000}
              success
              showConfirm={false}
              title={dynamic_title}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {error_dlg ? (
            <SweetAlert
              timeout={1000}
              error
              showConfirm={false}
              title={dynamic_title}
              onConfirm={() => {
                seterror_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {user.roleID === 1 ? (
            <ListSettlement settlementList={settlementList} />
          ) : (
            <Redirect to="/page-404" />
          )}
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  )
}

// Dashboard.propTypes = {
//    customerConfigurations: PropTypes.object
// }

const mapStateToProps = state => {
  return {
    // customerConfigurations: state.CustomerConfigurations.customerConfigurations,
    settlementList: state.Settlement.settlementList,
    settlementCreate: state.Settlement.settlementCreate,
    settlementUpdate: state.Settlement.settlementUpdate,
    settlementDelete: state.Settlement.settlementDelete,
    settlement: state.Settlement.settlement,
  }
}

const mapDispatchToProps = dispatch => ({
  //   onGetCustomerConfigurations :()=> dispatch(getCustomerConfigurations())
  onGetSettlementList: () => dispatch(getSettlementList()),
  onSetSettlement: data => dispatch(setSettlement(data)),
  onUpdateSettlement: settlement => dispatch(updateSettlement(settlement)),
})

export default connect(mapStateToProps, mapDispatchToProps)(index)
