import { sessionTimeout } from "./actions"
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_FAIL,
  SESSION_TIMEOUT,
  SESSION_TIMEOUT_SUCCESS,
  SESSION_TIMEOUT_FAIL,
  OTP,
  OTP_SUCCESS,
  OTP_FAIL,
  RESET_STATE,
} from "./actionTypes"

const initialState = {
  error: "",
  login: false,
  loading: false,
  sessionTimeout: false,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        login: true,
        error: "",
        loading: false,
      }
      break
    case LOGIN_FAIL:
      state = {
        ...state,
        login: false,
        error: action.payload,
        loading: false,
      }
      break
    case OTP:
      state = {
        ...state,
        otp: action.payload,
        loading: true,
      }
      break
    case OTP_SUCCESS:
      state = {
        ...state,
        otp: action.payload,
        loading: false,
      }
      break
    case OTP_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case SESSION_TIMEOUT:
      state = {
        ...state,
        sessionTimeout: false,
      }
    case SESSION_TIMEOUT_SUCCESS:
      state = {
        ...state,
        sessionTimeout: true,
      }
    case SESSION_TIMEOUT_FAIL:
      state = {
        ...state,
        sessionTimeout: false,
      }
    case RESET_STATE:
      state = {
        ...state,
        login: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
