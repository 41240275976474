import React, { useEffect, useContext, useState } from "react"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import LoadingBorderSpinner from '../../helpers/commonhelpers/loadingSpinner'
import CustomerConfigurationColumn from "./CustomerConfigurationColumn"
import copy from 'copy-to-clipboard';
import toastr from "toastr"
import "toastr/build/toastr.min.css"



const CustomerConfigurationList = (props) => {

    const { custConfigList } = props
    const [customerConfigurationList, setCustomerConfigurationList] = useState([])

    useEffect(() => {
        setCustomerConfigurationList(custConfigList)
    }, [custConfigList])

    const pageOptions = {
        sizePerPage: 50,
        totalSize: customerConfigurationList?.length,
        custom: true,
        withFirstAndLast: true
    }
    const { SearchBar } = Search

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (row) {
                const link = "/configurations/overview/" + row._id
                window.open(link, "_blank")


                console.log(e)
                console.log(row)
                console.log(rowIndex)
            }
        }
    };

    //search
    const handleTableChange = (type, { page, searchText }) => {
        try {
            setCustomerConfigurationList(
                custConfigList.filter(cust =>
                    Object.keys(cust).some(
                        key =>
                            typeof cust[key] === "string" &&
                            cust[key].toLowerCase().includes(searchText.toLowerCase())
                    )
                )
            )
        } catch { }
    }


    return (
        <React.Fragment>


            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>



                            {/* {isLoaded ?


                                <LoadingSpinnerThreeDots />
                                : */}
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}>
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField="_id"
                                        data={customerConfigurationList || []}
                                        columns={CustomerConfigurationColumn()}
                                        loading={true}
                                        bootstrap4
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row className="mb-2">
                                                    <Col sm="8">
                                                        <div className="search-box mr-2 mb-2 d-inline-block" >
                                                            <div className="position-relative" >
                                                                <SearchBar {...toolkitProps.searchProps} style={{ width: "30rem" }} placeholder="Enter Keyword" />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm="4">
                                                        <div className="text-sm-right">
                                                            <Button
                                                                type="button"
                                                                color="success"
                                                                className="btn-rounded waves-effect waves-light mb-2 mr-2">
                                                                <i className="mdi mdi-plus mr-1" />
                                                                New Customer Configuration
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                responsive

                                                                // remote={ {
                                                                //   filter: true, // not working
                                                                //   pagination: true, //not working
                                                                //   sort: true, // not working
                                                                //   cellEdit: false
                                                                // } }
                                                                loading={true}
                                                                bordered={false}
                                                                hover={true}
                                                                striped={false}

                                                                classes={"table table-centered table-nowrap"}
                                                                keyField="_id"
                                                                headerWrapperClasses={"thead-light"}
                                                                onTableChange={handleTableChange}
                                                                rowEvents={rowEvents}
                                                                // selectRow={selectRow}
                                                                filter={filterFactory()}
                                                                // defaultSorted={defaultSorted}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* <Row>
                        <Col xs="12">
                          <div className="text-center my-3">
                            <Link to="#" className="text-success">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2" />
                                  Load more
                                </Link>
                          </div>
                        </Col>
                      </Row> */}


                                                <Row className="align-items-md-center mt-30">

                                                    <Col xs="6">
                                                        <SizePerPageDropdownStandalone
                                                            {...paginationProps}

                                                        />
                                                    </Col>

                                                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />
                                                    </Col>


                                                </Row>

                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                            </PaginationProvider>
                            {/* } */}
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}


export default CustomerConfigurationList



