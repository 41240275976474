export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"

export const OTP = "OTP"
export const OTP_SUCCESS = "OTP_SUCCESS"
export const OTP_FAIL = "OTP_FAIL"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SESSION_TIMEOUT = "SESSION_TIMEOUT"
export const SESSION_TIMEOUT_SUCCESS = "SESSION_TIMEOUT_SUCCESS"
export const SESSION_TIMEOUT_FAIL = "SESSION_TIMEOUT_FAIL"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"
export const RESET_STATE = "RESET_STATE"
