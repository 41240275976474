export const GET_SETTLEMENT_LIST = "GET_SETTLEMENT_LIST"
export const GET_SETTLEMENT_LIST_SUCCESS = "GET_SETTLEMENT_LIST_SUCCESS"
export const GET_SETTLEMENT_LIST_FAIL = "GET_SETTLEMENT_LIST_FAIL"

export const GET_SETTLEMENT_BY_CUSTOMERID = "GET_SETTLEMENT_BY_CUSTOMERID"
export const GET_SETTLEMENT_BY_CUSTOMERID_SUCCESS =
  "GET_SETTLEMENT_BY_CUSTOMERID_SUCCESS"
export const GET_SETTLEMENT_BY_CUSTOMERID_FAIL =
  "GET_SETTLEMENT_BY_CUSTOMERID_FAIL"

export const GET_SETTLEMENT_ID = "GET_SETTLEMENT_ID"
export const GET_SETTLEMENT_ID_SUCCESS = "GET_SETTLEMENT_ID_SUCCESS"
export const GET_SETTLEMENT_ID_FAIL = "GET_SETTLEMENT_ID_FAIL"

export const SET_SETTLEMENT = "SET_SETTLEMENT"
export const SET_SETTLEMENT_SUCCESS = "SET_SETTLEMENT_SUCCESS"
export const SET_SETTLEMENT_FAIL = "SET_SETTLEMENT_FAIL"

export const SET_SETTLEMENT_EXCEL = "SET_SETTLEMENT_EXCEL"
export const SET_SETTLEMENT_EXCEL_SUCCESS = "SET_SETTLEMENT_EXCEL_SUCCESS"
export const SET_SETTLEMENT_EXCEL_FAIL = "SET_SETTLEMENT_EXCEL_FAIL"

export const SET_SAVE_SETTLEMENT = "SET_SAVE_SETTLEMENT"

export const UPDATE_SETTLEMENT = "UPDATE_SETTLEMENT"
export const UPDATE_SETTLEMENT_SUCCESS = "UPDATE_SETTLEMENT_SUCCESS"
export const UPDATE_SETTLEMENT_FAIL = "UPDATE_SETTLEMENT_FAIL"

export const DELETE_SETTLEMENT = "DELETE_SETTLEMENT"
export const DELETE_SETTLEMENT_SUCCESS = "DELETE_SETTLEMENT_SUCCESS"
export const DELETE_SETTLEMENT_FAIL = "DELETE_SETTLEMENT_FAIL"

export const FILE_UPLOAD = "FILE_UPLOAD"
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS"
export const FILE_UPLOAD_FAIL = "FILE_UPLOAD_FAIL"

export const RETURN_STATE = "RETURN_STATE"
