import React, { useEffect } from "react"
import { useState } from "react"
import {
  Col,
  FormGroup,
  Input,
  Row,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
} from "reactstrap"
import { BillingSlab } from "./BillingSlab"
import { v4 as uuidv4 } from "uuid"
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import { isEmpty } from "lodash"

const initialState = {
  id: uuidv4(),
  UnitMeasure: "",
  UnitMeasureRemarks: "",
  Price: 0.0,
  DiscountedPrice: 0,
  Currency: "EUR",
  Frequency: "monthly",
  Remarks: "",
  IsWaived: false,
  IsDiscounted: false,
  IsPricedBySlab: false,
  SlabRate: [
    // {
    //   id: uuidv4(),
    //   Minimum: 0,
    //   Maximum: 0,
    //   AmountRate: 0,
    //   Currency: "",
    // },
  ],
}

const BillingOptionsModal = props => {
  const {
    index,
    id,
    isOpen,
    toggle,
    productList,
    product,
    unitOfMeasureOptions,
    unitOfMeasureRemarksOptions,
    handleBillingOptionsChange,
    handleBillingOptionsAdd,
  } = props

  const { register, handleSubmit, errors, reset, watch } = useForm()
  const [selectedProduct, setSelectedProduct] = useState({})
  const [billingOpt, setBillingOpt] = useState(initialState)
  const [unitMeasureRemarks, setUnitOfMeasureRemarks] = useState()
  const [unitMeasure, setUnitOfMeasure] = useState()

  useEffect(() => {}, [billingOpt, selectedProduct])

  useEffect(() => {
    if (productList) {
      var productDetail = productList?.filter(
        o => o.productCode === product.ProductCode
      )[0]

      product.ProductCode = productDetail?.productCode

      setSelectedProduct(productDetail)
    }
  }, [productList, product])

  useEffect(() => {
    if (unitMeasureRemarks) {
      setUnitOfMeasureRemarks(unitMeasureRemarks)
    }

    if (unitMeasure) {
      setUnitOfMeasure(unitMeasure)
    }

    if(unitMeasure === "Transactional" && selectedProduct.productCode === "SELCO"){

      handleChange({ UnitMeasureRemarks: "UID-Segment" })
      
    }

    if(unitMeasure === "UID" && selectedProduct.productCode === "SELCO"){

      handleChange({ UnitMeasureRemarks: "" })
  
    }

  
  }, [unitMeasureRemarks, unitMeasure])

  function handleChange(changes) {
    setBillingOpt({ ...billingOpt, ...changes })
  }

  function handleAdd() {
    handleBillingOptionsAdd(billingOpt)

    let returnState = {
      id: uuidv4(),
      UnitMeasure: "",
      UnitMeasureRemarks: "",
      Price: 0.0,
      DiscountedPrice: 0,
      Currency: "EUR",
      Frequency: "",
      Remarks: "",
      IsWaived: false,
      IsDiscounted: false,
      IsPricedBySlab: false,
      SlabRate: [],
    }

    setBillingOpt(returnState)
  }

  function handleBillingSlabAdd() {
    const newBillingSlab = {
      id: uuidv4(),
      Minimum: 0,
      Maximum: 0,
      AmountRate: 0,
      Currency: "EUR",
    }

    handleChange({ SlabRate: [...billingOpt.SlabRate, newBillingSlab] })
  }

  function handleBillingSlabDelete(id) {
    handleChange({
      SlabRate: billingOpt.SlabRate.filter(i => i.id !== id),
    })
  }

  function handleBillingSlabChange(id, slabrate) {
    const newSlabRates = [...billingOpt.SlabRate]
    const index = newSlabRates.findIndex(i => i.id === id)
    newSlabRates[index] = slabrate
    handleChange({ SlabRate: newSlabRates })
  }

  function handleMeasureRemarksOptions(value) {
    setUnitOfMeasureRemarks(value)
    handleChange({ UnitMeasureRemarks: value })
  }

  function handleUnitOfMeasure(value) {
    setUnitOfMeasure(value)
    handleChange({ UnitMeasure: value })
  }

  const onSubmitBillingOptions = data => handleCheckNoErrors()

  const handleCheckNoErrors = () => {
    if (isEmpty(errors)) {
      handleAdd()
    }
  }

  return (
    <React.Fragment>
      <Modal
        className="show"
        backdrop="static"
        isOpen={isOpen}
        autoFocus={true}
        centered={true}
        toggle={toggle}
        size="md"
      >
        <ModalHeader>
       
      
          <div class="d-flex">
            <div class="mr-4 p-2"> Add Billing Options for <b>{product.ProductName}</b></div>
            <div class="ml-4 p-2">
              
            <Link to="#" onClick={e => toggle()}>
              <i
                className="fas fa-window-close fa-2x"
                style={{ color: "red" }}
              />
            </Link>
              </div>
        
          </div>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmitBillingOptions)}>
            <Row>
              <input
                type="text"
                value={uuidv4()}
                onChange={e => handleChange({ id: e.target.value })}
                name="id"
                className="form-control"
                readOnly
                hidden
              />
              <Col lg="6">
                <FormGroup>
                  <Label>Unit of Measure</Label>
                  <select
                    className="custom-select"
                    name="UnitMeasure"
                    onChange={e => handleUnitOfMeasure(e.target.value)}
                    ref={register({ required: true })}
                  >
                    <option value="" selected="selected" hidden="hidden">
                      Choose here
                    </option>

                    {selectedProduct?.unitMeasure?.map(
                      (unitofmeasure, index) => (
                        <option key={index} value={unitofmeasure}>
                          {unitofmeasure}
                        </option>
                      )
                    )}
                  </select>
                  {errors.UnitMeasure &&
                    errors.UnitMeasure.type === "required" && (
                      <small className="color-danger">This is required</small>
                    )}
                </FormGroup>
              </Col>
              {!isEmpty(selectedProduct?.unitMeasureRemarks) && (
                <Col lg="6">
                  <FormGroup>
                    <Label>Unit of Measure Remarks</Label>
                    <select
                      className="custom-select"
                      name="UnitMeasureRemarksOptions"
                      onChange={e =>
                        handleMeasureRemarksOptions(e.target.value)
                      }
                      // ref={register({ required: true })}
                    >
                      <option value="" selected="selected" hidden="hidden">
                        Choose here
                      </option>

                      {selectedProduct?.unitMeasureRemarks?.map(
                        (unitofmeasureremarks, index) => (
                          <option key={index} value={unitofmeasureremarks}>
                            {unitofmeasureremarks}
                          </option>
                        )
                      )}
                    </select>
                    {/* {errors.UnitMeasureRemarksOptions &&
                      errors.UnitMeasureRemarksOptions.type === "required" && (
                        <small className="color-danger">This is required</small>
                      )} */}
                  </FormGroup>
                </Col>
              )}
              
            </Row>

            <Row>
            {unitMeasureRemarks === "ProductName" &&
              unitMeasure === "AddOn" ? (
                <Col lg="6">
                  <FormGroup>
                    <Label>Enter AddOn Product Name</Label>
                    <input
                      type="text"
                      onChange={e =>
                        handleChange({ UnitMeasureRemarks: e.target.value })
                      }
                      value={billingOpt.UnitMeasureRemarks}
                      name="UnitMeasureRemarks"
                      className="form-control"
                      id="price"
                      placeholder="Enter Product Name"
                      ref={register({ required: true })}
                    />
                    {errors.UnitMeasureRemarks &&
                      errors.UnitMeasureRemarks.type === "required" && (
                        <small className="color-danger">This is required</small>
                      )}
                  </FormGroup>

                  <Input
                    hidden
                    disabled
                    value=""
                    name="Date"
                    type="text"
                    className="form-control"
                    placeholder={" "}
                  />
                </Col>
              ) : (
                <div></div>
              )}
            </Row>

            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label>Currency</Label>
                  <select
                    className="custom-select"
                    name="Currency"
                    onChange={e => handleChange({ Currency: e.target.value })}
                    ref={register({ required: true })}
                  >
                    <option value="EUR">EUR</option>
                  </select>
                  {errors.Currency && errors.Currency.type === "required" && (
                    <small className="color-danger">This is required</small>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label>Price</Label>
                  <input
                    type="number"
                    min="0.001"
                    step="0.001"
                    onChange={e =>
                      handleChange({ Price: parseFloat(e.target.value) })
                    }
                    name="Price"
                    className="form-control"
                    id="price"
                    ref={register({ required: true })}
                  />
                  {errors.Price && errors.Price.type === "required" && (
                    <small className="color-danger">This is required</small>
                  )}
                </FormGroup>
              </Col>

             
            </Row>

            <Row>
            <Col lg="6">
                <FormGroup>
                  <Label>Is Discounted?</Label>
                  <select
                    className="custom-select"
                    name="IsDiscounted"
                    onChange={e =>
                      handleChange({
                        IsDiscounted: e.target.value === "true" ? true : false,
                      })
                    }
                    ref={register({ required: true })}
                  >
                    <option key={"false"} value="false">
                      No
                    </option>
                    <option key={"true"} value="true">
                      Yes
                    </option>
                  </select>
                  {errors.IsDiscounted &&
                    errors.IsDiscounted.type === "required" && (
                      <small className="color-danger">This is required</small>
                    )}
                </FormGroup>
              </Col>
              {billingOpt.IsDiscounted && (
                <Col lg="6">
                  <FormGroup>
                    <Label>Discounted Price</Label>
                    <input
                      type="number"
                      min="0.001"
                      step="0.001"
                      onChange={e =>
                        handleChange({
                          DiscountedPrice: parseFloat(e.target.value),
                        })
                      }
                      name="DiscountedPrice"
                      className="form-control"
                      id="discountedprice"
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>

            <Row>
              

              <Col lg="12">
                <FormGroup>
                  <Label>Remarks</Label>
                  <input
                    type="text"
                    onChange={e => handleChange({ Remarks: e.target.value })}
                    name="Remarks"
                    className="form-control"
                    id="remarks"
                    //  ref={register({ required: true })}
                  />
                  {/* {errors.Remarks && errors.Remarks.type === "required" && (
                    <small className="color-danger">This is required</small>
                  )} */}
                </FormGroup>
              </Col>

           
            </Row>

            <Row>

            <Col lg="4">
                <FormGroup>
                  <Label>Frequency</Label>
                  <select
                    className="custom-select"
                    name="Frequency"
                    onChange={e => handleChange({ Frequency: e.target.value })}
                    ref={register({ required: true })}
                  >
                    <option value="" selected="selected" hidden="hidden">
                      Choose here
                    </option>
                    <option key={"monthly"} value="monthly">
                      Monthly
                    </option>
                    <option key={"quarterly"} value="quarterly">
                      Quarterly
                    </option>
                    <option key={"yearly"} value="yearly">
                      Yearly
                    </option>
                    <option key={"one time"} value="one time">
                      One Time
                    </option>
                  </select>
                  {errors.Frequency && errors.Frequency.type === "required" && (
                    <small className="color-danger">This is required</small>
                  )}
                </FormGroup>
              </Col>
            
              <Col lg="4">
                <FormGroup>
                  <Label>Is Priced By Slab?</Label>
                  <select
                    className="custom-select"
                    name="PricedBySlab"
                    onChange={e =>
                      handleChange({
                        IsPricedBySlab:
                          e.target.value === "true" ? true : false,
                      })
                    }
                    ref={register({ required: true })}
                  >
                    <option key={"false"} value="false">
                      No
                    </option>
                    <option key={"true"} value="true">
                      Yes
                    </option>
                  </select>
                  {errors.PricedBySlab &&
                    errors.PricedBySlab.type === "required" && (
                      <small className="color-danger">This is required</small>
                    )}
                </FormGroup>
              </Col>

              <Col lg="4">
                <FormGroup>
                  <Label>Is Waived?</Label>
                  <select
                    className="custom-select"
                    name="IsWaived"
                    onChange={e =>
                      handleChange({
                        IsWaived: e.target.value === "true" ? true : false,
                      })
                    }
                    ref={register({ required: true })}
                  >
                    <option key={"false"} value="false">
                      No
                    </option>
                    <option key={"true"} value="true">
                      Yes
                    </option>
                  </select>
                  {errors.IsWaived && errors.IsWaived.type === "required" && (
                    <small className="color-danger">This is required</small>
                  )}
                </FormGroup>
              </Col>
            </Row>

            {billingOpt.IsPricedBySlab === true && (
              <>
                <hr></hr>
                
                <Row>
                  <Col lg="3">Minimum Transactions</Col>
                  <Col lg="3">Maximum Transactions</Col>
                  <Col lg="3">Amount</Col>
                  <Col lg="2">Currency</Col>
                </Row>

                <br></br>
                <Row>
                  <Col>
                    {billingOpt?.SlabRate?.map((slabrate, index) => (
                      <BillingSlab
                        index={index}
                        key={slabrate.id}
                        slabrate={slabrate}
                        handleBillingSlabChange={handleBillingSlabChange}
                        handleBillingSlabDelete={handleBillingSlabDelete}
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmitBillingOptions}
                        register={register}
                        errors={errors}
                      />
                    ))}
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col lg="6">
                    <Button
                      onClick={() => handleBillingSlabAdd()}
                      color="success"
                      className="btn-block inner"
                      style={{ width: "100%" }}
                    >
                      + Add Slab Rate
                    </Button>
                  </Col>
                </Row>
                <br></br>
                <br></br>
              </>
            )}

            <Row>
              <Col>
                <Button
                  type="submit"
                  color="success"
                  className="btn-block inner"
                  style={{ width: "100%" }}
                >
                  + Add
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default BillingOptionsModal
