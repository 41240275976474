import {
  GET_OTHERCHARGES_LIST,
  GET_OTHERCHARGES_LIST_SUCCESS,
  GET_OTHERCHARGES_LIST_FAIL,
  GET_OTHERCHARGES_BY_CUSTOMERID_LIST,
  GET_OTHERCHARGES_BY_CUSTOMERID_LIST_SUCCESS,
  GET_OTHERCHARGES_BY_CUSTOMERID_LIST_FAIL,
  GET_OTHERCHARGES_DETAIL,
  GET_OTHERCHARGES_DETAIL_SUCCESS,
  GET_OTHERCHARGES_DETAIL_FAIL,
  SET_OTHERCHARGES,
  SET_OTHERCHARGES_SUCCESS,
  SET_OTHERCHARGES_FAIL,
  UPDATE_OTHERCHARGES,
  UPDATE_OTHERCHARGES_SUCCESS,
  UPDATE_OTHERCHARGES_FAIL,
  DELETE_OTHERCHARGES,
  DELETE_OTHERCHARGES_SUCCESS,
  DELETE_OTHERCHARGES_FAIL,
  RESTART_STATE,
} from "./actionTypes"

const INIT_STATE = {
  error: {},
  loading: false,
  otherChargesCreate: undefined,
  otherChargesUpdate: undefined,
  otherChargesDelete: undefined,
}

const otherCharges = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_OTHERCHARGES_LIST:
      return {
        ...state,
        loading: true,
      }
    case GET_OTHERCHARGES_LIST_SUCCESS:
      return {
        ...state,
        otherChargesList: action.payload,
        loading: false,
      }
    case GET_OTHERCHARGES_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_OTHERCHARGES_BY_CUSTOMERID_LIST:
      return {
        ...state,
        otherChargesListByCustomerId: action.payload,
        loading: true,
      }
    case GET_OTHERCHARGES_BY_CUSTOMERID_LIST_SUCCESS:
      return {
        ...state,
        otherChargesListByCustomerId: action.payload,
        loading: false,
      }
    case GET_OTHERCHARGES_BY_CUSTOMERID_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_OTHERCHARGES_DETAIL:
      return {
        ...state,
        otherChargesDetail: action.payload,
        loading: true,
      }
    case GET_OTHERCHARGES_DETAIL_SUCCESS:
      return {
        ...state,
        otherChargesDetail: action.payload,
        loading: false,
      }
    case GET_OTHERCHARGES_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case SET_OTHERCHARGES:
      return {
        ...state,
        otherCharge: action.payload,
        loading: true,
      }
    case SET_OTHERCHARGES_SUCCESS:
      return {
        ...state,
        otherCharge: action.payload,
        otherChargesCreate: true,
        loading: false,
      }
    case SET_OTHERCHARGES_FAIL:
      return {
        ...state,
        error: action.payload,
        otherChargesCreate: false,
        loading: false,
      }
    case UPDATE_OTHERCHARGES:
      return {
        ...state,
        otherCharge: action.payload,
        loading: true,
      }
    case UPDATE_OTHERCHARGES_SUCCESS:
      return {
        ...state,
        otherCharge: action.payload,
        otherChargesUpdate: true,
        loading: false,
      }
    case UPDATE_OTHERCHARGES_FAIL:
      return {
        ...state,
        otherCharge: action.payload,
        otherChargesUpdate: false,
        loading: false,
      }
    case DELETE_OTHERCHARGES:
      return {
        ...state,
        otherCharge: action.payload,
        loading: true,
      }
    case DELETE_OTHERCHARGES_SUCCESS:
      return {
        ...state,
        otherCharge: action.payload,
        otherChargesDelete: true,
        loading: false,
      }
    case DELETE_OTHERCHARGES_FAIL:
      return {
        ...state,
        otherCharge: action.payload,
        otherChargesDelete: false,
        loading: false,
      }
    case RESTART_STATE:
      return {
        ...state,
        otherChargesDelete: undefined,
        otherChargesCreate: undefined,
        otherChargesUpdate: undefined,
      }
    default:
      return state
  }
}

export default otherCharges
