import React from "react"
import {
  Col,
  FormGroup,
  Input,
  Row,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import OfficeUsers from "./OfficeUsers"

export default function OfficeUserModal(props) {
  const {
    isOpen,
    toggle,
    office,
    handleUserChange,
    handleUserDelete,
    handleUserAdd,
  } = props

  return (
    <React.Fragment>
      <Modal
        className="show"
        backdrop="static"
        isOpen={isOpen}
        autoFocus={true}
        centered={true}
        toggle={toggle}
        size="xl"
      >
        <ModalHeader>Add Users under {office.OfficeId}</ModalHeader>
        <ModalBody>
          {office.Users.map((user, index) => (
            <OfficeUsers
              key={index}
              index={index}
              user={user}
              handleUserChange={handleUserChange}
              handleUserDelete={handleUserDelete}
            />
          ))}

          <Button
            onClick={() => handleUserAdd()}
            color="success"
            className="btn-block inner"
            style={{ width: "20%" }}
          >
            Add A User
          </Button>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Button
              onClick={e => toggle()}
              color="success"
              className="btn-block inner"
              style={{ width: "100%" }}
            >
              {" "}
              All good.{" "}
            </Button>
          </Col>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}
