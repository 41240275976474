import React from "react"
import { Link } from "react-router-dom"
import {
  Modal,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  ModalBody,
  Table,
} from "reactstrap"

const SlabRateModal = props => {
  const { isOpen, toggle, billingOption } = props

  

  return (
    <React.Fragment>
      <Modal
        className="show"
        backdrop="static"
        isOpen={isOpen}
        autoFocus={true}
        centered={true}
        toggle={toggle}
        size="lg"
      >
        <ModalBody>
          <Row>
            <Col lg="12">
              <div class="d-flex justify-content-end">
                <Link to="#" onClick={() => toggle()}>
                  <i
                    className="fas fa-window-close fa-2x"
                    style={{ color: "red" }}
                  />
                </Link>
              </div>
            </Col>
          </Row>{" "}

          <hr></hr>
          <Row>
              <Col>Unit of Measure :<br></br>  <b>{billingOption?.UnitMeasure}</b>
              </Col>
              <Col> Unit of Measure Remarks : <br></br> <b> {billingOption?.UnitMeasureRemarks} </b>
              </Col>
              <Col>  Is Priced By Slab :<br></br> 
            <b>{billingOption?.IsPricedBySlab ? "Yes" : "No"}</b>
              </Col>
          </Row>
         <br></br>
          <Table>
            <tr>
              <th>Minimum</th>
              <th>Maximum</th>
              <th>Rate</th>
              <th>Currency</th>
            </tr>

            {billingOption?.SlabRate?.map((rate, index) => (
              <tr key={index}>
                <td>{rate.Minimum}</td>
                <td>{rate.Maximum}</td>
                <td>{rate.AmountRate}</td>
                <td>{rate.Currency}</td>
              </tr>
            ))}
          </Table>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default SlabRateModal
