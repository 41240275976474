/* INVOICES */
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL"

export const GET_CUSTOMER_DETAIL = "GET_CUSTOMER_DETAIL"
export const GET_CUSTOMER_DETAIL_SUCCESS = "GET_CUSTOMER_DETAIL_SUCCESS"
export const GET_CUSTOMER_DETAIL_FAIL = "GET_CUSTOMER_DETAIL_FAIL"

export const GET_CUSTOMER_OFFICES = "GET_CUSTOMER_OFFICES"
export const GET_CUSTOMER_OFFICES_SUCCESS = "GET_CUSTOMER_OFFICES_SUCCESS"
export const GET_CUSTOMER_OFFICES_FAIL = "GET_CUSTOMER_OFFICES_FAIL"
