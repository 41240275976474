import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Row,
  Col,
} from "reactstrap"
import { map, isEmpty } from "lodash"
import { dateFormatDDMMMYYYY } from "../../helpers/commonhelpers/dateFormat"

const AgreementModal = props => {
  const { isOpen, toggle, agreementSelected } = props

  const [agreementDetails, setAgreementDetails] = useState(agreementSelected)

  useEffect(() => {
    setAgreementDetails(agreementSelected)
  }, [agreementSelected])

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabindex="-1"
      toggle={toggle}
      size="xl"
    >
      <div className="modal-content p-2">
        {agreementDetails && (
          <>
            <ModalHeader toggle={toggle}>
              Agreement Covered Period{" "}
              <b>
                {dateFormatDDMMMYYYY(agreementDetails?.effectiveDate)} to{" "}
                {dateFormatDDMMMYYYY(agreementDetails?.endDate)}
              </b>
            </ModalHeader>
            <ModalBody className="p-4">
              <br></br>
              <Row>
                {" "}
                <span style={{ color: "blue" }}>Agreement Information</span>
              </Row>
              <br></br>

              <Row>
                <Col lg={2}>
                  <small> Agreement Id </small> <br></br>
                  <b>{agreementDetails.agreementId}</b>
                </Col>

                <Col lg={2}>
                  <small> Signed Date </small> <br></br>
                  <b>{dateFormatDDMMMYYYY(agreementDetails.signedDate)}</b>
                </Col>
                <Col lg={2}>
                  <small> Effective Date </small> <br></br>
                  <b>{dateFormatDDMMMYYYY(agreementDetails.effectiveDate)} </b>
                </Col>
                <Col lg={2}>
                  <small> Months Ending </small> <br></br>
                  <b>{agreementDetails.months}</b>
                </Col>
                <Col lg={2}>
                  <small> End Date </small> <br></br>
                  <b>{dateFormatDDMMMYYYY(agreementDetails.endDate)}</b>
                </Col>
                <Col lg={2}>
                  <small> Status </small> <br></br>
                  <b>
                    {agreementDetails.isActive === true ? "Active" : "InActive"}
                  </b>
                </Col>
              </Row>
              {/* INCENTIVE SLABS */}
              <br></br>
              <Row>
                {" "}
                <span style={{ color: "blue" }}>Incentive Slabs</span>
              </Row>
              <br></br>
              <Row>
                <Col lg={12}>
                  <Table>
                    <thead>
                      <tr>
                        <th>
                          <small>Minimum Booking</small>
                        </th>

                        <th>
                          <small>Maximum Booking</small>
                        </th>
                        <th>
                          <small>Amount Rate</small>
                        </th>
                        <th>
                          <small>Currency</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(
                        agreementDetails.incentiveConfiguration,
                        (i, index) => (
                          <>
                            <tr key={index}>
                              <td>
                                <span>{i.minimumBooking}</span>
                              </td>

                              <td>
                                <span>{i.maximumBooking}</span>
                              </td>
                              <td>
                                <span>{i.amountRate}</span>
                              </td>
                              <td>
                                <span>{i.currency}</span>
                              </td>
                            </tr>
                          </>
                        )
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              {/* CONTENT */}
              <br></br>
              <Row>
                {" "}
                <span style={{ color: "blue" }}>Content</span>
              </Row>
              <br></br>

              <Row>
                {agreementDetails.contentConfiguration?.map((s, index) => (
                  <>
                    <Col>
                      {" "}
                      <p>
                        {s.contentType === "airlines" ? "Unpaid Airlines" : ""}
                      </p>
                    </Col>
                    <Col>
                      {" "}
                      <p key={index}>{(index ? " ,  " : " ") + s.airlines}</p>
                    </Col>
                    <Col> {s.isExcluded === true ? "Exclude" : "Include"}</Col>
                  </>
                ))}
              </Row>

              {/* PRODUCTS & SOLUTIONS */}
              <br></br>
              <Row>
                {" "}
                <span style={{ color: "blue" }}>Products & Solutions</span>
              </Row>
              <br></br>

              {/* new LAYOUT */}

              <Row>
                <Col lg={12}>
                  {/* {map(
                    agreementDetails.productsSolutionsConfiguration,
                    (p, index) => ( */}
                  <>
                    {/* <Col>
                            <b>
                              {index + 1} {p.productName}
                            </b>
                          </Col> */}

                    <Table className="table-sm ">
                      <thead>
                        <tr>
                          <th>
                            <small style={{ color: "darkgray" }}>#</small>
                          </th>

                          <th style={{ width: "200px" }}>
                            <small style={{ color: "darkgray" }}>
                              Product Name
                            </small>
                          </th>
                          <th style={{ width: "130px" }}>
                            <small style={{ color: "darkgray" }}>
                              Unit Measure
                            </small>
                          </th>

                          <th>
                            <small style={{ color: "darkgray" }}>Price</small>
                          </th>
                          <th>
                            <small style={{ color: "darkgray" }}>
                              Discount
                            </small>
                          </th>
                          <th>
                            <small style={{ color: "darkgray" }}>
                              Currency
                            </small>
                          </th>
                          <th>
                            <small style={{ color: "darkgray" }}>
                              Billing Freq
                            </small>
                          </th>
                          <th>
                            <small style={{ color: "darkgray" }}>Status</small>
                          </th>

                          {/* <th>
                                    <small style={{ color: "darkgray" }}>
                                      Billing
                                    </small>
                                  </th> */}
                          {/* <th>
                                    <small style={{ color: "darkgray" }}>
                                      Slab Rate
                                    </small>
                                  </th> */}
                          <th width="115px">
                            <small style={{ color: "darkgray" }}>
                              Unit Remarks
                            </small>
                          </th>
                          <th width="115px">
                            <small style={{ color: "darkgray" }}>Remarks</small>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(
                          agreementDetails.productsSolutionsConfiguration,
                          (p, productIndex) =>
                            map(p.billingOptions, (b, index) => (
                              <>
                                <tr key={index}>
                                  <td>
                                    <small> {productIndex + 1}</small>
                                  </td>
                                  <td width="200px">
                                    <small><b>{p.productName}</b> </small>
                                  </td>

                                  <td>
                                    <small>{b.unitMeasure}</small>
                                  </td>

                                  <td>
                                    {b.isPricedBySlab === true ? (
                                      <>
                                        {b.slabRate?.map((slab, i) => (
                                          <>
                                            <small>
                                              {slab.amountRate} ( {slab.minimum} - {slab.maximum})
                                            </small>
                                            <br></br>
                                          </>
                                        ))}
                                      </>
                                    ) : (
                                      <small>{b.price}</small>
                                    )}
                                  </td>
                                  <td>
                                    <small>{b.discountedPrice}</small>
                                  </td>
                                  <td>
                                    <small>{b.currency}</small>
                                  </td>
                                  <td>
                                    <small>{b.frequency}</small>
                                  </td>
                                  <td>
                                    <small>
                                      {b.isWaived === true
                                        ? "Waived"
                                        : "Billed"}
                                    </small>
                                  </td>
                                  {/* <td>
                                        <small>
                                          {b.isDiscounted === true
                                            ? "Discounted"
                                            : "Regular"}
                                        </small>
                                      </td> */}

                                  {/* <td>
                                        <small>
                                          {b.IsPricedBySlab === true
                                            ? "Priced by Slab"
                                            : "N/A"}
                                        </small>
                                      </td> */}
                                  <td width="115px">
                                    <small>{b.unitMeasureRemarks} </small>
                                  </td>
                                  <td width="115px">
                                    <small>{b.remarks} </small>
                                  </td>
                                </tr>
                              </>
                            ))
                        )}
                      </tbody>
                    </Table>
                  </>
                  {/* )
                  )} */}
                </Col>
              </Row>

              {/* OLD LAYOUT */}
              {/* <Row>
                <Col lg={12}>
                  {map(
                    agreementDetails.productsSolutionsConfiguration,
                    (p, index) => (
                      <>
                        <Row>
                          <Col>
                            <b>
                              {index + 1} {p.productName}
                            </b>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Table className="table-sm ">
                              <thead>
                                <tr>
                                  <th style={{ width: "100px" }}>
                                    <small></small>
                                  </th>
                                  <th style={{ width: "200px" }}>
                                    <small style={{ color: "darkgray" }}>
                                      Unit Measure
                                    </small>
                                  </th>

                                  <th>
                                    <small style={{ color: "darkgray" }}>
                                      Price
                                    </small>
                                  </th>
                                  <th>
                                    <small style={{ color: "darkgray" }}>
                                      Discounted Price
                                    </small>
                                  </th>
                                  <th>
                                    <small style={{ color: "darkgray" }}>
                                      Currency
                                    </small>
                                  </th>
                                  <th>
                                    <small style={{ color: "darkgray" }}>
                                      Frequency
                                    </small>
                                  </th>
                                  <th>
                                    <small style={{ color: "darkgray" }}>
                                      Status
                                    </small>
                                  </th>

                                  <th>
                                    <small style={{ color: "darkgray" }}>
                                      Billing
                                    </small>
                                  </th>
                                  <th>
                                    <small style={{ color: "darkgray" }}>
                                      Slab Rate
                                    </small>
                                  </th>
                                  <th>
                                    <small style={{ color: "darkgray" }}>
                                      Remarks
                                    </small>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {map(p.billingOptions, (b, index) => (
                                  <>
                                    <tr key={index}>
                                      <td>
                                        <small></small>
                                      </td>

                                      <td>
                                        <small>{b.unitMeasure}</small>
                                      </td>

                                      <td>
                                        <small>{b.price}</small>
                                      </td>
                                      <td>
                                        <small>{b.discountedPrice}</small>
                                      </td>
                                      <td>
                                        <small>{b.currency}</small>
                                      </td>
                                      <td>
                                        <small>{b.frequency}</small>
                                      </td>
                                      <td>
                                        <small>
                                          {b.isWaived === true
                                            ? "Waived"
                                            : "Billed"}
                                        </small>
                                      </td>
                                      <td>
                                        <small>
                                          {b.isDiscounted === true
                                            ? "Discounted"
                                            : "Regular"}
                                        </small>
                                      </td>

                                      <td>
                                        <small>
                                          {b.IsPricedBySlab === true
                                            ? "Priced by Slab"
                                            : "N/A"}
                                        </small>
                                      </td>
                                      <td>
                                        <small>{b.unitMeasureRemarks}</small>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </>
                    )
                  )}
                </Col>
              </Row> */}

              {/* OTHER CHARGES */}
              <br></br>
              <Row>
                {" "}
                <span style={{ color: "blue" }}>Other Charges</span>
              </Row>
              <br></br>
              <Row>
                <Col lg={12}>
                  <Table>
                    <thead>
                      <tr>
                        <th>
                          <small>Name</small>
                        </th>

                        <th>
                          <small>Unit Measure</small>
                        </th>
                        <th>
                          <small>Price</small>
                        </th>
                        <th>
                          <small>DiscountedPrice</small>
                        </th>
                        <th>
                          <small>Currency</small>
                        </th>
                        <th>
                          <small>Frequency</small>
                        </th>
                        <th>
                          <small>Status</small>
                        </th>
                        <th>
                          <small>Remarks</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(agreementDetails.otherCharges, (p, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <span>{p.name}</span>
                            </td>

                            <td>
                              <span>{p.unitMeasure}</span>
                            </td>
                            <td>
                              <span>{p.price}</span>
                            </td>
                            <td>
                              <span>{p.discountedPrice}</span>
                            </td>
                            <td>
                              <span>{p.currency}</span>
                            </td>
                            <td>
                              <span>{p.frequency}</span>
                            </td>
                            <td>
                              <span>
                                {p.isWaived === true ? "Waived" : "Billed"}
                              </span>
                            </td>
                            <td>
                              <span>{p.remarks}</span>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <br></br>
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="secondary" onClick={toggle}>
                Close
              </Button>
            </ModalFooter>
          </>
        )}
      </div>
    </Modal>
  )
}

AgreementModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AgreementModal
