import React, {useEffect} from "react"
import { Button, Col, Row, Card, CardBody, Table } from "reactstrap"

const ConfirmDetails_3 = props => {
  const {
    otherCharges,
    customerConfigDetails,
    insertCustomerConfiguration,
    toggleTab,
    activeTab,
  } = props


  useEffect(() => {
    

  }, [customerConfigDetails])
  

  return (
    <React.Fragment>
      <Table>
        <tbody>
          <tr>
            <td>
              <div className="d-flex justify-content-start align-self-center">
                <ul
                  className="pager wizard twitter-bs-wizard-pager-link"
                  style={{ listStyleType: "none" }}
                >
                  <li
                    className={
                      activeTab === 1
                        ? "previous disabled mr-4"
                        : "previous mr-4"
                    }
                    style={{ display: "inline-block" }}
                  >
                    <Button
                      color="primary"
                      onClick={() => {
                        toggleTab(activeTab - 1)
                      }}
                    >
                      Edit
                    </Button>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <div className="d-flex justify-content-end align-self-center">
                <ul
                  className="pager wizard twitter-bs-wizard-pager-link"
                  style={{ listStyleType: "none" }}
                >
                  <li
                    className={activeTab === 3 ? "next disabled" : "next"}
                    style={{ display: "inline-block" }}
                  >
                    {activeTab === 3 ? (
                      <Button
                        onClick={() => insertCustomerConfiguration()}
                        color="success"
                        id="my-form_submit_1"
                      >
                        {" "}
                        <i className="bx bx-comment-check mr-2 fa-lg" /> SAVE{" "}
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        color="primary"
                        id="my-form_submit_1"
                      >
                        {" "}
                        Next{" "}
                      </Button>
                    )}
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <b>CUSTOMER DETAILS & AGREEMENTS</b>
              </p>
              <pre>{JSON.stringify(customerConfigDetails, null, 2)}</pre>
            </td>
            <td>
              <p>
                <b>OTHER CHARGES INFO </b>
              </p>
              <pre>{JSON.stringify(otherCharges, null, 2)}</pre>
            </td>
          </tr>
        </tbody>
      </Table>
    </React.Fragment>
  )
}

export default ConfirmDetails_3
