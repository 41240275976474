import { call, put, takeEvery } from "redux-saga/effects"

import { GET_CUSTOMERS,
   GET_CUSTOMER_DETAIL,
   GET_CUSTOMER_OFFICES,
  
  } from "./actionTypes"
import {
  getCustomersSuccess,
  getCustomersFail,
  getCustomerDetailSuccess,
  getCustomerDetailFail,
  getCustomerOfficesSuccess,
  getCustomerOfficesFail
} from "./actions"

//Include Both Helper File with needed methods
import { getCustomers, getCustomerOffices, getCustomerDetail } from "helpers/fakebackend_helper"

function* _getCustomers() {
  try {
    const response = yield call(getCustomers)
    yield put(getCustomersSuccess(response))
  } catch (error) {
    yield put(getCustomersFail(error))
  }
}


function* _getCustomerDetail({ id }) {
  try {
    
    const response = yield call(getCustomerDetail, id)
    yield put(getCustomerDetailSuccess(response))
  } catch (error) {
    yield put(getCustomerDetailFail(error))
  }
}


function* _getCustomerOffices({ id }) {
  try {
    console.log(id)
    const response = yield call(getCustomerOffices, id)
    yield put(getCustomerOfficesSuccess(response))
  } catch (error) {
    yield put(getCustomerOfficesFail(error))
  }
}

function* customersSaga() {
  yield takeEvery(GET_CUSTOMERS, _getCustomers)
  yield takeEvery(GET_CUSTOMER_DETAIL, _getCustomerDetail)
  yield takeEvery(GET_CUSTOMER_OFFICES,_getCustomerOffices)
}

export default customersSaga
