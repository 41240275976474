import {
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  productList: [],
  product: {},
  error: {},
  loading: true,
}

const ProductList = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productList: action.payload,
      }

    case GET_PRODUCT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default ProductList
