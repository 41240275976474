import React, {useState, useEffect} from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import OtherCharges_Monthly from "./OtherCharges_Monthly"
import { v4 as uuidv4 } from "uuid"

const OtherCharges = props => {
  const {
    customerId,
    agreementIndex,
    index,
    key,
    agreementId,
    charge,
    handleOtherChargesChange,
    handleOtherChargesDelete,
   
  } = props

  //TODO: adding charges on both agreement id has bugs
  const inputAgreementId = "AgreementId" + index + agreementIndex
  const inputChargeName = "ChargeName" + index + agreementIndex
  const inputBilllingOption = "BillingOption" + index + agreementIndex
  const inputAmount = "Ammount" + index + agreementIndex
  const inputDiscountedAmount = "DiscountedAmount" + index + agreementIndex
  const inputCurrency = "Currency" + index + agreementIndex
  const inputIsWaived = "IsWaived" + index + agreementIndex
  const inputRemarks = "Remarks" + index + agreementIndex


  const [annualChargeAmount, setAnnualChargeAmount] = useState()
  const [annualChargeDiscountedAmount, setAnnualChargeDiscountedAmount] = useState()

  useEffect(() => {
    
    var annualCharge = {
      Date: new Date().toISOString(),
      Amount: annualChargeAmount,
      DiscountedAmount:annualChargeDiscountedAmount,
      Currency: "QAR",
    }
    
    handleChange({ AnnualCharge: annualCharge})

  }, [annualChargeAmount, annualChargeDiscountedAmount])
  
useEffect(() => {
  handleChange({ AgreementId: agreementId })
}, [agreementId])

useEffect(() => {
  handleChange({ CustomerId: customerId })
}, [customerId])

  function handleChange(changes) {
    handleOtherChargesChange(charge.id, { ...charge, ...changes })
  }

  function handleMonthlyChargeChange(id, monthlycharge) {
    const newMonthlyCharges = [...charge.MonthlyCharge]
    const index = newMonthlyCharges.findIndex(i => i.id === id)
    newMonthlyCharges[index] = monthlycharge
    handleChange({ MonthlyCharge: newMonthlyCharges })
  }

  function handleMonthlyChargeAdd() {
    const newMonthlyCharge = {
      id: uuidv4(),
      Date: new Date().toISOString(),
      Amount: 0.0,
      Currency: "QAR",
    }

    handleChange({
      MonthlyCharge: [...charge.MonthlyCharge, newMonthlyCharge],
    })
  }

  function handleMonthlyChargeDelete(id) {
    handleChange({
      MonthlyCharge: charge.MonthlyCharge.filter(i => i.id !== id)
    })
  }

  function handleBillingOption(value){

    if(value === "monthly"){

      handleChange({ AnnualCharge: null , BillingOption: value })

   
    }
    
    if(value === "annual"){

      var annual = {
        Date: new Date().toISOString(),
        Amount: 0.0,
        Currency: "QAR",
      }

      handleChange({ MonthlyCharge: [] , BillingOption: value, AnnualCharge: annual })

    
    }


    
 
  }

  return (
    <React.Fragment>
      <Card style={{ backgroundColor: "#ebf3fa" }}>
        <CardBody>
          <div> 
            <Row>
              <Col>
                <div class="d-flex justify-content-end">
                  <Link
                    to="#"
                    onClick={() => handleOtherChargesDelete(charge.id)}
                  >
                    <i
                      className="fas fa-window-close fa-2x"
                      style={{ color: "red" }}
                    />
                  </Link>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
              <FormGroup>
                  <Label>AgreementId</Label>
                  <input
                    type="text"
                    value={agreementId}
                 //   onChange={e => handleChange({ AgreementId: e.target.value })}
                    name="agreementId"
                    className="form-control"
                    id="agreementId"
                    readOnly
                  />
                 
                </FormGroup>

                <FormGroup>
                  <Label>CustomerId</Label>
                  <input
                    type="text"
                    value={customerId}
                //    onChange={e => handleChange({ CustomerId: e.target.value })}
                    name="customerId"
                    className="form-control"
                    id=""
                    readOnly
                  />
                 
                </FormGroup>
                
                <FormGroup>
                  <Label>Name</Label>
                  <input
                    type="text"
                    value={charge.ChargeName}
                    onChange={e => handleChange({ ChargeName: e.target.value })}
                    name={inputChargeName}
                    className="form-control"
                    id="name"
                    // ref={register({
                    //   required: {
                    //     value: true,
                    //     message: "This is required",
                    //   },
                    // })}
                  />
                  {/* <small className="color-danger">
                    {errors[inputChargeName] && errors[inputChargeName].message}
                  </small> */}
                </FormGroup>
              </Col>

              <Col lg="3">
                <FormGroup>
                  <Label>Billing Option</Label>
                  <select
                    className="custom-select"
                    name={inputBilllingOption}
                    value={charge.BillingOption}
                    onChange={e =>
                      handleBillingOption(e.target.value)
                    }
                    // ref={register({
                    //   required: {
                    //     value: true,
                    //     message: "This is required",
                    //   },
                    // })}
                  >
                    <option value="" selected="selected" hidden="hidden">
                      Choose here
                    </option>

                    <option value="monthly">Monthly</option>
                    <option value="annual">Annual</option>
                  </select>
                  {/* <small className="color-danger">
                    {errors[inputBilllingOption] &&
                      errors[inputBilllingOption].message}
                  </small> */}
                </FormGroup>
              </Col>

              <Col lg="2">
                <FormGroup>
                  <Label>Is Waived</Label>
                  <select
                    className="custom-select"
                    name={inputIsWaived}
                    value={charge.IsWaived}
                    onChange={e =>
                      handleChange({
                        IsWaived: e.target.value === "true" ? true : false,
                      })
                    }
                    // ref={register({
                    //   required: {
                    //     value: true,
                    //     message: "This is required",
                    //   },
                    // })}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                  {/* <small className="color-danger">
                    {errors[inputIsWaived] && errors[inputIsWaived].message}
                  </small> */}
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label>Remarks</Label>
                  <input
                    type="text"
                    value={charge.Remarks}
                    onChange={e => handleChange({ Remarks: e.target.value })}
                    name={inputRemarks}
                    className="form-control"
                    id="remarks"
                    // ref={register({
                    //   required: {
                    //     value: true,
                    //     message: "This is required",
                    //   },
                    // })}
                  />
                  {/* <small className="color-danger">
                    {errors[inputRemarks] && errors[inputRemarks].message}
                  </small> */}
                </FormGroup>
              </Col>
            </Row>
            {charge.BillingOption === "monthly" ? (
              <>
                <Row>
                  <Col lg={3}>
                    <p>Date</p>
                  </Col>
                  <Col lg={3}>
                    <p>Amount</p>
                  </Col>
                  <Col lg={3}>
                    <p>Curency</p>
                  </Col>
                 
                  <Col>
                    <FormGroup>
                      <Button
                        onClick={() => handleMonthlyChargeAdd()}
                        color="success"
                        className="btn-block inner"
                        style={{ width: "100%" }}
                      >
                        + Add Monthly Charges
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
                {charge.MonthlyCharge?.map((monthlycharge, index) => (
                  <OtherCharges_Monthly
                    index={index}
                    key={monthlycharge.id}
                    monthlycharge={monthlycharge}
                    handleMonthlyChargeChange={handleMonthlyChargeChange}
                    handleMonthlyChargeDelete={handleMonthlyChargeDelete}
                    // handleSubmit={handleSubmit}
                    // onSubmit={onSubmit}
                    //register={register}
                    // errors={errors}
                    
                  />
                ))}
              </>
            ) : (
              <>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Price</Label>
                      <input
                        type="number"
                        min="0"
                        step="0.5"
                        value={charge.AnnualCharge.Amount}
                        onChange={e =>
                          setAnnualChargeAmount(parseFloat(e.target.value))
                        }
                        name={inputAmount}
                        className="form-control"
                        id="price"
                        // ref={register({
                        //   required: {
                        //     value: true,
                        //     message: "This is required",
                        //   },
                        // })}
                      />
                      {/* <small className="color-danger">
                        {errors[inputAmount] && errors[inputAmount].message}
                      </small> */}
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label>Discounted</Label>
                      <input
                        type="number"
                        min="0"
                        step="0.5"
                        value={charge.AnnualCharge.DiscountedAmount}
                        onChange={e =>
                          setAnnualChargeDiscountedAmount(parseFloat(e.target.value))
            
                        }
                        name={inputDiscountedAmount}
                        className="form-control"
                        id="discountedprice"
                        // ref={register({
                        //   required: {
                        //     value: true,
                        //     message: "This is required",
                        //   },
                        // })}
                      />
                      {/* <small className="color-danger">
                        {errors[inputDiscountedAmount] &&
                          errors[inputDiscountedAmount].message}
                      </small> */}
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label>Currency</Label>
                      <select
                        className="custom-select"
                        name={inputCurrency}
                        value={charge.AnnualCharge.Currency}
                        onChange={e =>
                          handleChange({ "AnnualCharge.Currency": e.target.value })
                        }
                        // ref={register({
                        //   required: {
                        //     value: true,
                        //     message: "This is required",
                        //   },
                        // })}
                      >
                        <option value="QAR">QAR</option>
                      </select>
                      {/* <small className="color-danger">
                        {errors[inputCurrency] && errors[inputCurrency].message}
                      </small> */}
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}

            <Row></Row>

            <Row></Row>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default OtherCharges
