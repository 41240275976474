import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Col, Form, FormGroup, Label, Row, Button } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Offices } from "../CustomerDetails/Offices"
import { v4 as uuidv4 } from "uuid"
import { isEmpty, join } from "lodash"
import { useForm } from "react-hook-form"

export const CustomerDetails_1 = props => {
  const {
    toggleTab,
    activeTab,
    customerList,
    customerConfigDetails,
    handleCustomerDetailsChange,
    onGetCustomerOffices,
    customerOfficesList,
    setSelectedBusinessFromDropdown
  } = props

  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [selectedCustomerName, setSelectedCustomerName] = useState({})
  const { register, handleSubmit, errors, reset, watch } = useForm()
  const [joinedDate, setJoinedDate]=useState()

  useEffect(() => {
 
    handleChange({ DateJoined: joinedDate })
  }, [joinedDate])
  
  useEffect(() => {
    var customer = customerList?.filter(
      i => i.customerName === selectedCustomerName
    )[0]
    customerConfigDetails.BusinessName = customer?.customerName
    customerConfigDetails.CustomerId = customer?.customerID
    customerConfigDetails.Segment = customer?.segment
    customerConfigDetails.Category = customer?.category

    if (customer?.customerID !== "undefined" || !isEmpty(customer)) {
      onGetCustomerOffices(customer?.customerID)
    }

    setSelectedCustomer(customer)
  }, [selectedCustomerName])

  useEffect(() => {
    if(selectedCustomer)
    setSelectedBusinessFromDropdown("selected")
    else{setSelectedBusinessFromDropdown("unselected")}
  }, [selectedCustomer])

  function handleChange(changes) {
    handleCustomerDetailsChange({ ...customerConfigDetails, ...changes })
  }

  function handleOfficeChange(id, office) {
    const newOffices = [...customerConfigDetails.Offices]
    const index = newOffices.findIndex(i => i.id === id)
    newOffices[index] = office
    handleChange({ Offices: newOffices })
  }

  function handleOfficeDelete(id) {
    handleChange({
      Offices: customerConfigDetails.Offices.filter(i => i.id !== id),
    })
  }

  function handleOfficeAdd() {
    const newOffice = {
      id: uuidv4(),
      OfficeId: "",
      OfficeName: "",
      Date: new Date().toISOString(),
      Users: [
        {
          id: uuidv4(),
          SignIn: "",
          Username: "",
          DateCreated: new Date().toISOString(),
          IsActive: false,
        },
      ],
    }

    handleChange({ Offices: [...customerConfigDetails.Offices, newOffice] })
  }

  const onSubmitCustomerDetails = data => handleCheckNoErrors()

  function handleCheckNoErrors() {
    if (isEmpty(errors)) {
      toggleTab(activeTab + 1)
    }
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit(onSubmitCustomerDetails)} id="my-form">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Select Customer</Label>
              <select
                className="custom-select"
                name="selectedCustomer"
                //  value={selectedCustomer.customerId}
                onChange={e => setSelectedCustomerName(e.target.value)}
              >
                <option value="" hidden="hidden">
                  Choose here
                </option>

                {customerList?.map((customer, index) => (
                  <option key={index} value={customer.customerId}>
                    {customer.customerName}
                  </option>
                ))}
              </select>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="4">
            <FormGroup>
              <Label>Business Name</Label>
              <input
                type="text"
                value={customerConfigDetails.BusinessName}
                onChange={e => handleChange({ BusinessName: e.target.value })}
                name="BusinessName"
                className="form-control"
                id="businessName"
                readOnly
                // ref={register({ required: true })}

                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />

              <small className="color-danger">
                {errors["BusinessName"] && errors["BusinessName"].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="4">
            <FormGroup>
              <Label>Customer Id</Label>
              <input
                type="text"
                value={customerConfigDetails.CustomerId}
                onChange={e => handleChange({ CustomerId: e.target.value })}
                name="CustomerId"
                className="form-control"
                id="customerId"
                readOnly
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />

              <small className="color-danger">
                {errors["CustomerId"] && errors["CustomerId"].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="4">
            <FormGroup>
              <Label>Date Joined on Amadeus</Label>

              <Flatpickr
                className="form-control d-block"
                placeholder="Select Date"
                value={Date.parse(customerConfigDetails.DateJoined)}
                onChange={e => setJoinedDate(e[0])}
                name="DateJoined"
                id="dateJoined"
                options={{
                  altInput: true,
                  altFormat: "F j, Y",
                  dateFormat: "Y-m-d",
                }}
                // ref={register({
                //   required: {
                //     value: true,
                //     message: "This is required",
                //   }
                // })}
              />

              {/* {errors.DateJoined && errors.DateJoined.type === "required" && (
                <small className="color-danger">This is required</small>
              )} */}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="4">
            <FormGroup>
              <Label>Registered Address</Label>
              <input
                type="text"
                value={customerConfigDetails.RegisteredAddress}
                onChange={e =>
                  handleChange({ RegisteredAddress: e.target.value })
                }
                name="RegisteredAddress"
                className="form-control"
                id="registeredAddress"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />

              <small className="color-danger">
                {errors["RegisteredAddress"] &&
                  errors["RegisteredAddress"].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="4">
            <FormGroup>
              <Label>Telephone</Label>
              <input
                type="text"
                value={customerConfigDetails.Telephone}
                onChange={e => handleChange({ Telephone: e.target.value })}
                name="Telephone"
                id="telephone"
                className="form-control"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />
              <small className="color-danger">
                {errors["Telephone"] && errors["Telephone"].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="4">
            <FormGroup>
              <Label>Email</Label>
              <input
                type="text"
                value={customerConfigDetails.Email}
                onChange={e => handleChange({ Email: e.target.value })}
                name="Email"
                id="email"
                className="form-control"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
              />
              <small className="color-danger">
                {errors["Email"] && errors["Email"].message}
              </small>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="4">
            <FormGroup>
              <Label>Business Trading Name</Label>
              <input
                type="text"
                value={customerConfigDetails.BusinessTradingName}
                onChange={e =>
                  handleChange({ BusinessTradingName: e.target.value })
                }
                name="BusinessTradingName"
                id="businessTradingName"
                className="form-control"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />
              <small className="color-danger">
                {errors["BusinessTradingName"] &&
                  errors["BusinessTradingName"].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="4">
            <FormGroup>
              <Label>Segment</Label>
              <input
                type="text"
                className="form-control"
                name="Segment"
                id="segment"
                value={customerConfigDetails.Segment}
                onChange={e => handleChange({ Segment: e.target.value })}
                readOnly
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />

              <small className="color-danger">
                {errors["Segment"] && errors["Segment"].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="4">
            <FormGroup>
              <Label>Category</Label>
              <input
                type="text"
                className="form-control"
                name="Category"
                id="category"
                value={customerConfigDetails.Category}
                onChange={e => handleChange({ Category: e.target.value })}
                readOnly
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />

              <small className="color-danger">
                {errors["Category"] && errors["Category"].message}
              </small>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="4">
            <FormGroup>
              <Label>Company</Label>
              <input
                type="text"
                value={customerConfigDetails.Company}
                onChange={e => handleChange({ Company: e.target.value })}
                name="Company"
                className="form-control"
                id="company"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />
              <small className="color-danger">
                {errors["Company"] && errors["Company"].message}
              </small>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Label>Key Account Manager</Label>
              <select
                className="custom-select"
                name="KeyAccountManager"
                id="keyAccountManager"
                value={customerConfigDetails.KeyAccountManager}
                onChange={e =>
                  handleChange({ KeyAccountManager: e.target.value })
                }
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              >
                <option value="" hidden="hidden">
                  Choose here
                </option>
                <option value="Adham Maarouf">Adham Maarouf </option>
                <option value="Balaji Sundaram">Balaji Sundaram</option>
                <option value="Fadi Al Talla">Fadi Al Talla</option>
                <option value="Myriam Tabet">Myriam Tabet</option>
                <option value="Navin Mathew">Navin Mathew</option>
              </select>

              <small className="color-danger">
                {errors["KeyAccountManager"] &&
                  errors["KeyAccountManager"].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="4">
            <FormGroup>
              <Label>Status</Label>
              <select
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
                className="custom-select"
                name="Status"
                value={customerConfigDetails.IsActive}
                onChange={e =>
                  handleChange({
                    IsActive: e.target.value === "true" ? true : false,
                  })
                }
              >
                <option value="" hidden="hidden">
                  Choose here
                </option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>

              <small className="color-danger">
                {errors["Status"] && errors["Status"].message}
              </small>
            </FormGroup>
          </Col>
        </Row>

        <hr></hr>
        <p>
          <b>Add Offices</b>
        </p>
        {customerConfigDetails.Offices.map((office, index) => (
          <Offices
            index={index}
            key={office.id}
            office={office}
            customerOfficesList={customerOfficesList}
            handleOfficeChange={handleOfficeChange}
            handleOfficeDelete={handleOfficeDelete}
            handleSubmit={handleSubmit}
            onSubmit={onSubmitCustomerDetails}
            register={register}
            errors={errors}
          />
        ))}

        <Button
          onClick={() => handleOfficeAdd()}
          color="success"
          className="mt-1"
        >
          + Add New Office
        </Button>

        <div className="d-flex justify-content-end">
          <ul
            className="pager wizard twitter-bs-wizard-pager-link"
            style={{ listStyleType: "none" }}
          >
            <li
              className={
                activeTab === 1 ? "previous disabled mr-4" : "previous mr-4"
              }
              style={{ display: "inline-block" }}
            >
              <Button
                color="primary"
                onClick={() => {
                  toggleTab(activeTab - 1)
                }}
              >
                Previous
              </Button>
            </li>
            <li
              className={activeTab === 3 ? "next disabled" : "next"}
              style={{ display: "inline-block" }}
            >
              <Button type="submit" color="primary" id="customerDetailsSubmit">
                {" "}
                Next{" "}
              </Button>
            </li>
          </ul>
        </div>
      </Form>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails_1)
