import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Col, FormGroup, Input, Row, Button, Form } from "reactstrap"
import OfficeUserModal from "./OfficeUserModal"
import { v4 as uuidv4 } from "uuid"

export const Offices = props => {
  const {
    index,
    office,
    handleOfficeChange,
    handleOfficeDelete,
    customerOfficesList,
    handleSubmit,
    onSubmit,
    register,
    errors,
  } = props
  const [modal, setModal] = useState(false)
  const [selectedOffice, setSelectedOffice] = useState()

  const inputOfficeId = "OfficeId" + index
  const inputOfficeName = "OfficeName" + index

  useEffect(() => {
    if (selectedOffice) {
      var officeDetail = customerOfficesList?.filter(
        o => o.officeID === selectedOffice
      )[0]

      handleChange({ OfficeName: officeDetail?.officeName })

     // setSelectedOffice(officeDetail)
    }
  }, [selectedOffice])

  const toggleModal = () => {
    setModal(!modal)
  }

  function handleChange(changes) {
    handleOfficeChange(office.id, { ...office, ...changes })
  }

  function handleUserChange(id, user) {
    const newUser = [...office.Users]
    const index = newUser.findIndex(i => i.id === id)
    newUser[index] = user
    handleChange({ Users: newUser })
  }
  function handleUserAdd() {
    const newUser = {
      id: uuidv4(),
      SignIn: "",
      Username: "",
      DateCreated: new Date().toISOString(),
      IsActive: false,
    }
    handleChange({ Users: [...office.Users, newUser] })
  }
  function handleUserDelete(id) {
    handleChange({
      Users: office.Users.filter(i => i.id !== id),
    })
  }

  function handleOfficeName(value) {
    handleChange({ OfficeId: value })
  
    setSelectedOffice(value)
  }
  return (
    <React.Fragment>
      <OfficeUserModal
        isOpen={modal}
        toggle={toggleModal}
        office={office}
        handleUserChange={handleUserChange}
        handleUserDelete={handleUserDelete}
        handleUserAdd={handleUserAdd}
      />
      {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
        <div>
        <Row>
          <Col lg="3">
            <FormGroup>
              <select
                value={office.OfficeId}
                type="text"
                name={inputOfficeId}
                id="officeid"
                onChange={e => handleOfficeName(e.target.value)}
                className="custom-select"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              >
                <option value="" hidden="hidden">
                  Choose here
                </option>

                {customerOfficesList?.map((office, index) => (
                  <option key={index} value={office.officeID}>
                    {office.officeID}
                  </option>
                ))}
              </select>

              <small className="color-danger">
                {errors[inputOfficeId] && errors[inputOfficeId].message}
              </small>
            </FormGroup>
            <Input
              hidden
              disabled
              value=""
              name="Date"
              type="text"
              className="form-control"
              placeholder={" "}
            />
          </Col>

          <Col lg="3">
            <FormGroup>
              <input
                disabled
                value={office.OfficeName}
                type="text"
                name={inputOfficeName}
                onChange={e => handleChange({ OfficeName: e.target.value })}
                id="officename"
                className="form-control"
                placeholder="Enter Office Name"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />
              <small className="color-danger">
                {errors[inputOfficeName] && errors[inputOfficeName].message}
              </small>
            </FormGroup>
          </Col>
          <Col lg="3">
            <Button
              onClick={() => toggleModal()}
              color="success"
              className="btn-block inner"
              style={{ width: "100%" }}
            >
              Add Office Users
            </Button>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Button
                onClick={e => handleOfficeDelete(office.id)}
                color="danger"
                className="btn-block inner"
                style={{ width: "100%" }}
              >
                {" "}
                Delete Office{" "}
              </Button>
            </FormGroup>
          </Col>
        </Row>
        </div>
      {/* </Form> */}

    </React.Fragment>
  )
}

export default Offices
