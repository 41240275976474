import React, { useState, useEffect } from "react"
import {
  Col,
  Row,
  Button,
  InputGroup,
  InputGroupAddon,
  Label,
  Form,
} from "reactstrap"
import { connect } from "react-redux"
import FileUpload from "./FileUpload"
import AuthUser from "components/AuthUser/AuthUser"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { useForm } from "react-hook-form"
import {
  updateSettlement,
  fileUpload,
  setSaveSettlement,
  getCustomerConfigurations,
} from "store/actions"
import Select from "react-select"
function Update(props) {
  const [
    {
      amount,
      externalReference,
      remarks,
      file,
      customerId,
      customerName,
      agreementId,
      currency,
      status,
      co,
    },
    setSettlementDetail,
  ] = useState({
    amount: props.selectedSettlement.amount,
    externalReference: props.selectedSettlement.externalReference,
    remarks: props.selectedSettlement.remarks,
    file: props.selectedSettlement.file,
    customerId: props.selectedSettlement.customerId,
    customerName: props.selectedSettlement.customerName,
    agreementId: props.selectedSettlement.agreementId,
    currency: props.selectedSettlement.currency,
    co: props.selectedSettlement.co,
    status: props.selectedSettlement.status,
  })
  const { register, handleSubmit, errors } = useForm()
  // console.log(props.selectedSettlement)
  // console.log(props.selectedSettlement)
  const [modal_large, setModal_Large] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])

  const [cusAgreementId, setCusAgreementId] = useState("")
  const [cusAgreementIds, setCusAgreementIds] = useState([])
  const [cusAgreementError, setCustAgreementError] = useState(false)
  const [cusId, setCustId] = useState("")
  const [cusName, setCusName] = useState("")
  const [selectCus, setSelectCus] = useState({})
  const [user, setUser] = useState(AuthUser())

  const [cusNameError, setCustNameError] = useState(false)
  const [cusIdError, setCustIdError] = useState(false)
  const [selectedDateRangeFrom, setSelectedDateRangeFrom] = useState(
    props.selectedSettlement.periodCoverageFrom
  )
  const [selectedDateRangeTo, setSelectedDateRangeTo] = useState(
    props.selectedSettlement.periodCoverageTo
  )

  const {
    onUpdateSettlement,
    onGetCustomerConfigurationsList,
    customerList,
    onFileUpload,
    fileUpload,
    fileNameBack,
    storedSettlement,
    onSetSaveSettlement,
  } = props
  let optionsCustomerId = customerList.map((cus, i) => {
    return { value: cus.customerId, label: cus.customerId }
  })

  let optionsCustomerName = customerList.map((cus, i) => {
    return { value: cus.businessName, label: cus.businessName }
  })

  let optionsCustomerAgreements = cusAgreementIds?.map((ag, i) => {
    return { value: ag.agreementId, label: ag.agreementId }
  })
  useEffect(() => {
    onGetCustomerConfigurationsList()
  }, [])

  useEffect(() => {}, [customerList])
  useEffect(() => {}, [cusAgreementIds])
  useEffect(() => {}, [cusAgreementId])
  useEffect(() => {
    setCustId([
      {
        value: props.selectedSettlement.customerId,
        label: props.selectedSettlement.customerId,
      },
    ])
  }, [props.selectedSettlement.customerId])
  // useEffect(() => {
  //   setCusAgreementId([
  //     {
  //       value: props.selectedSettlement.agreementId,
  //       label: props.selectedSettlement.agreementId,
  //     },
  //   ])
  // }, [props.selectedSettlement.agreementId])
  useEffect(() => {
    setCusName([
      {
        value: props.selectedSettlement.customerName,
        label: props.selectedSettlement.customerName,
      },
    ])
  }, [props.selectedSettlement.customerName])

  useEffect(() => {
    if (customerId !== "") {
      hadnleGetCustomerDetails("id", customerId)
      console.log(customerId)
      customerList.map((cus, i) => {
        if (customerId === cus.customerId) {
          console.log(cus)
          setSelectCus(cus)
        }
      })
    }
  }, [customerId])

  useEffect(() => {
    if (customerName !== "") {
      hadnleGetCustomerDetails("name", customerName)
      customerList.map((cus, i) => {
        if (customerName === cus.businessName) {
          setCustId({ label: cus.customerId, value: cus.customerId })
          setSelectCus(cus)
          setSettlementDetail(settlement => ({
            ...settlement,
            customerId: cus.customerId,
          }))
        }
      })
    }
  }, [customerName])
  useEffect(() => {
    if (selectedFiles.name !== undefined) {
      setSettlementDetail(settlement => ({
        ...settlement,
        file: selectedFiles.name,
      }))
    }
  }, [selectedFiles])
  useEffect(() => {
    if (selectCus !== undefined) {
      setCusAgreementIds(selectCus.agreements)
      setCusAgreementId("")
      setSettlementDetail(settlement => ({
        ...settlement,
        agreementId: "",
      }))
    }
  }, [selectCus])

  useEffect(() => {}, [storedSettlement])

  useEffect(() => {
    if (fileNameBack) {
      handleSettlementUpload()
    }
  }, [fileNameBack])

  const storeDataOnForm = event => {
    const { name, value } = event.target
    setSettlementDetail(settlement => ({ ...settlement, [name]: value }))
  }

  function handleFileUpload() {
    // console.log(selectedFiles)
    if (selectedFiles !== undefined) {
      const formData = new FormData()

      formData.append("file", selectedFiles)
      formData.append("FileName", selectedFiles.name)

      if (formData) {
        // console.log(formData)
        onFileUpload(formData)
      }
    }
  }
  const customerIDEvent = event => {
    setCustId(event)
    setSettlementDetail(settlement => ({
      ...settlement,
      customerName: "",
      customerId: event.value,
    }))
  }
  const customerNameEvent = event => {
    setCusName(event)
    setSettlementDetail(settlement => ({
      ...settlement,
      customerId: "",
      customerName: event.value,
    }))
  }
  const customerAgreementEvent = event => {
    setCusAgreementId(event)
    setSettlementDetail(settlement => ({
      ...settlement,
      agreementId: event.value,
    }))
  }

  const hadnleGetCustomerDetails = (type, data) => {
    if (type === "id" && customerName === "") {
      customerList.map((cus, i) => {
        if (data === cus.customerId) {
          setCusName({ label: cus.businessName, value: cus.businessName })
          setSelectCus(cus)
          setSettlementDetail(settlement => ({
            ...settlement,
            customerName: cus.businessName,
          }))
        }
      })
    }
    if (type === "name" && customerId === "") {
      customerList.map((cus, i) => {
        if (data === cus.businessName) {
          setCustId({ label: cus.customerId, value: cus.customerId })
          setSelectCus(cus)
          setSettlementDetail(settlement => ({
            ...settlement,
            customerId: cus.customerId,
          }))
        }
      })
    }
  }
  const handleUpdateSettlement = () => {
    // handleFileUpload()
    let dateFrom = ""
    let dateTo = ""
    if (selectedDateRangeFrom[0] === undefined) {
      dateFrom = new Date(Date.now())
    }
    if (selectedDateRangeTo[0] === undefined) {
      dateTo = new Date(Date.now() + 3600 * 1000 * 24)
    }

    if (selectedDateRangeFrom[0] !== undefined) {
      dateFrom = selectedDateRangeFrom[0]
    }
    if (selectedDateRangeTo[0] !== undefined) {
      dateTo = selectedDateRangeTo[0]
    }
    const settlementDetail = {
      _id: props.selectedSettlement._id,
      dateCreated: props.selectedSettlement.dateCreated,
      settlementId: props.selectedSettlement.settlementId,
      agreementId: props.selectedSettlement.agreementId,
      amount: amount,
      currency: currency,
      externalReference: externalReference,
      remarks: remarks,
      status: status,
      cO: co,
      periodCoverageFrom: props.selectedSettlement.periodCoverageFrom,
      periodCoverageTo: props.selectedSettlement.periodCoverageTo,
      file: props.selectedSettlement.file,
      customerId: customerId,
      customerName: customerName,
      userId: user._id,
      addedBy: user.email,
      isActive: props.selectedSettlement.isActive,
    }
    // console.log(settlementDetail)
    onSetSaveSettlement(settlementDetail)
    // console.log(selectedFiles)
    if (selectedFiles.name !== undefined) {
      handleFileUpload()
    } else {
      onUpdateSettlement(settlementDetail)
      props.onChange(false)
      //window.location.reload
    }
  }

  const handleSettlementUpload = () => {
    let date = ""
    if (selectedDateRange[0] === "2") {
      date = props.selectedSettlement.dateofSettlement
    } else {
      date = selectedDateRange[0]
    }
    const settlementDetail = {
      _id: props.selectedSettlement._id,
      currency: storedSettlement.currency,
      dateCreated: props.selectedSettlement.dateCreated,
      dateofSettlement: date,
      agreementId: props.selectedSettlement.agreementId,
      settlementId: props.selectedSettlement.settlementId,
      amount: storedSettlement.amount,
      externalReference: storedSettlement.externalReference,
      remarks: storedSettlement.remarks,
      file: fileUpload.toString(),
      customerId: storedSettlement.customerId,
      customerName: storedSettlement.customerName,
      userId: user._id,
      addedBy: user.email,
      isActive: props.selectedSettlement.isActive,
    }
    // console.log(settlementDetail)
    onUpdateSettlement(settlementDetail)
    props.onChange(false)
    //window.location.reload
  }
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Form onSubmit={handleSubmit(handleUpdateSettlement)}>
            <Row className="form-group mx-3 my-3">
              <Col md={6}>
                <Label className=" input-text-size">Amount</Label>
                <input
                  id="amount"
                  name="amount"
                  value={amount}
                  min="1"
                  step=".01"
                  type="number"
                  ref={register({ required: true })}
                  className="form-control form-control-lg input-text-size"
                  onChange={event => storeDataOnForm(event)}
                />
                {errors.amount && errors.amount.type === "required" && (
                  <p className="color-danger">This is required</p>
                )}
              </Col>
              <Col md={6}>
                <Label className=" input-text-size">Currency</Label>
                <input
                  id="currency"
                  name="currency"
                  value={currency}
                  type="text"
                  ref={register({ required: true })}
                  className="form-control form-control-lg input-text-size"
                  onChange={event => storeDataOnForm(event)}
                />
                {errors.outstanding &&
                  errors.outstanding.type === "required" && (
                    <p className="color-danger">This is required</p>
                  )}
              </Col>
            </Row>
            <Row className="form-group mx-3 my-3">
              <Col md={6}>
                <Label className=" input-text-size">Customer Name</Label>
                {/* <div>
                  {" "}
                  <Label> {customerName}</Label>
                </div> */}
                <Select
                  id="cusName"
                  value={cusName}
                  name="cusName"
                  defaultValue={{
                    value: props.selectedSettlement.customerName,
                    label: props.selectedSettlement.customerName,
                  }}
                  Ref={register({ required: true })}
                  onChange={event => customerNameEvent(event)}
                  options={optionsCustomerName}
                  className=" input-text-size"
                  classNamePrefix="select2-selection"
                />
                {/* {errors.cusName && errors.cusName.type === "required" && (
                  <p className="color-danger">This is required</p>
                )} */}
                {cusNameError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
              <Col md={6}>
                <Label className=" input-text-size">Customer Id</Label>
                {/* <div>
                  {" "}
                  <Label> {customerId}</Label>
                </div> */}
                <Select
                  value={cusId}
                  id="cusId"
                  name="cusId"
                  // innerRef={register({ required: true })}
                  onChange={event => customerIDEvent(event)}
                  options={optionsCustomerId}
                  className=" input-text-size "
                  classNamePrefix="select2-selection"
                />
                {/* {errors.cusId && errors.cusId.type === "required" && (
                  <p className="color-danger">This is required</p>
                )} */}
                {cusIdError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row className="form-group mx-3 my-3">
              <Col md={6}>
                <Label className=" input-text-size">Agreement Id</Label>
                {/* <div>
                  {" "}
                  <Label> {agreementId}</Label>
                </div> */}
                <Select
                  id="cusAgreementId"
                  value={cusAgreementId}
                  name="cusAgreementId"
                  //innerRef={register({ required: true })}
                  onChange={event => customerAgreementEvent(event)}
                  options={optionsCustomerAgreements}
                  className=" input-text-size"
                  classNamePrefix="select2-selection"
                />
                {/* {errors.cusName && errors.cusName.type === "required" && (
                  <p className="color-danger">This is required</p>
                )} */}
                {cusAgreementError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
              <Col md={6}>
                <Label className=" input-text-size">External Reference</Label>
                <input
                  id="externalReference"
                  name="externalReference"
                  value={externalReference}
                  type="text"
                  ref={register({ required: true })}
                  className="form-control form-control-lg input-text-size"
                  onChange={event => storeDataOnForm(event)}
                />
                {errors.outstanding &&
                  errors.outstanding.type === "required" && (
                    <p className="color-danger">This is required</p>
                  )}
              </Col>
            </Row>
            <Row className="form-group mx-3 my-3">
              <Col md={6}>
                <Label>Period Coverage From</Label>
                <Flatpickr
                  name="selectedDates"
                  className="form-control w-100 my-1  "
                  placeholder="Select Date Range"
                  //innerRef={register({ required: true })}
                  value={selectedDateRangeFrom}
                  options={{
                    altFormat: "Y-m-d",
                    dateFormat: "F j, Y",
                  }}
                  onChange={selectedDates =>
                    setSelectedDateRangeFrom(selectedDates)
                  }
                />
              </Col>
              <Col md={6}>
                <Label>Period Coverage To</Label>
                <Flatpickr
                  name="selectedDates"
                  className="form-control w-100 my-1  "
                  placeholder="Select Date Range"
                  //innerRef={register({ required: true })}
                  value={selectedDateRangeTo}
                  options={{
                    altFormat: "Y-m-d",
                    dateFormat: "F j, Y",
                  }}
                  onChange={selectedDates =>
                    setSelectedDateRangeTo(selectedDates)
                  }
                />
              </Col>
            </Row>
            <Row className="form-group mx-3 my-3">
              <Col md={6}>
                <Label>REMARKS</Label>
                <input
                  id="remarks"
                  name="remarks"
                  value={remarks}
                  // innerRef={register({ required: false })}
                  className="form-control form-control-lg input-text-size"
                  onChange={event => storeDataOnForm(event)}
                />
              </Col>
              <Col md={6}>
                <Label>CO</Label>
                <input
                  id="co"
                  name="co"
                  value={co}
                  // innerRef={register({ required: false })}
                  className="form-control form-control-lg input-text-size"
                  onChange={event => storeDataOnForm(event)}
                />
              </Col>
            </Row>
            <Row className="form-group mx-3 my-3">
              <Col md={6}>
                <Label>Status</Label>
                <input
                  name="status"
                  value={status}
                  // innerRef={register({ required: false })}
                  className="form-control form-control-lg input-text-size"
                  onChange={event => storeDataOnForm(event)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row>
                  <Col className="mx-5">
                    <Label className=" input-text-size item-center">FILE</Label>
                    {file !== undefined ? (
                      <Label className="color-primary">
                        {file.substring(14)}
                      </Label>
                    ) : (
                      file
                    )}
                  </Col>
                </Row>

                <FileUpload onChange={value => setselectedFiles(value)} />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="item-center mb-3">
                <Button
                  color="primary"
                  type="submit"
                  className=" waves-effect waves-light mt-3 btn-lg"
                  //onClick={() => handleUpdateSettlement()}
                >
                  <i className="mdi mdi-pencil mr-1" />
                  Update Settlement
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    fileUpload: state.Settlement.file,
    customerList: state.customerconfigurations.customerConfigurations,
    fileNameBack: state.Settlement.fileName,
    storedSettlement: state.Settlement.storedSettlement,
    settlement: state.Settlement.settlement,
  }
}

const mapDispatchToProps = dispatch => ({
  onSetSaveSettlement: data => dispatch(setSaveSettlement(data)),
  onGetCustomerConfigurationsList: () => dispatch(getCustomerConfigurations()),
  onUpdateSettlement: settlement => dispatch(updateSettlement(settlement)),
  onFileUpload: data => dispatch(fileUpload(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Update)
