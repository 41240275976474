import {
  GET_SETTLEMENT_LIST,
  GET_SETTLEMENT_LIST_SUCCESS,
  GET_SETTLEMENT_LIST_FAIL,
  GET_SETTLEMENT_BY_CUSTOMERID,
  GET_SETTLEMENT_BY_CUSTOMERID_SUCCESS,
  GET_SETTLEMENT_BY_CUSTOMERID_FAIL,
  GET_SETTLEMENT_ID,
  GET_SETTLEMENT_ID_SUCCESS,
  GET_SETTLEMENT_ID_FAIL,
  SET_SETTLEMENT,
  SET_SETTLEMENT_SUCCESS,
  SET_SETTLEMENT_FAIL,
  UPDATE_SETTLEMENT,
  UPDATE_SETTLEMENT_SUCCESS,
  UPDATE_SETTLEMENT_FAIL,
  DELETE_SETTLEMENT,
  DELETE_SETTLEMENT_SUCCESS,
  DELETE_SETTLEMENT_FAIL,
  FILE_UPLOAD,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAIL,
  RETURN_STATE,
  SET_SAVE_SETTLEMENT,
  SET_SETTLEMENT_EXCEL,
  SET_SETTLEMENT_EXCEL_SUCCESS,
  SET_SETTLEMENT_EXCEL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  error: {},
  loading: false,
  settlementCreate: undefined,
  settlementUpdate: undefined,
  settlementDelete: undefined,
  fileName: undefined,
}

const settlement = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SETTLEMENT_LIST:
      return {
        ...state,
        settlementList: action.payload,
        loading: true,
      }

    case GET_SETTLEMENT_LIST_SUCCESS:
      return {
        ...state,
        settlementList: action.payload,
        loading: false,
      }

    case GET_SETTLEMENT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_SETTLEMENT_BY_CUSTOMERID:
      return {
        ...state,
        settlementListByCustomerId: action.payload,
        loading: true,
      }

    case GET_SETTLEMENT_BY_CUSTOMERID_SUCCESS:
      return {
        ...state,
        settlementListByCustomerId: action.payload,
        loading: false,
      }

    case GET_SETTLEMENT_BY_CUSTOMERID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_SETTLEMENT_ID:
      return {
        ...state,
        settlement: action.payload,
        loading: true,
      }
    case GET_SETTLEMENT_ID_SUCCESS:
      return {
        ...state,
        settlement: action.payload,
        loading: false,
      }
    case GET_SETTLEMENT_ID_FAIL:
      return {
        ...state,
        eroor: action.payload,
        loading: false,
      }
    case SET_SETTLEMENT_EXCEL:
      return {
        ...state,
        settlementExcel: action.payload,
        loading: true,
      }
    case SET_SETTLEMENT_EXCEL_SUCCESS:
      return {
        ...state,
        settlementExcel: action.payload,
        loading: false,
        settlementExcelCreate: true,
      }
    case SET_SETTLEMENT_EXCEL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        settlementExcelCreate: false,
      }
    case SET_SETTLEMENT:
      return {
        ...state,
        settlement: action.payload,
        loading: true,
      }
    case SET_SETTLEMENT_SUCCESS:
      return {
        ...state,
        settlement: action.payload,
        settlementCreate: true,
        loading: false,
      }
    case SET_SETTLEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        settlementCreate: false,
        loading: false,
      }
    case UPDATE_SETTLEMENT:
      return {
        ...state,
        settlement: action.payload,
        loading: true,
      }
    case UPDATE_SETTLEMENT_SUCCESS:
      return {
        ...state,
        settlement: action.payload,
        settlementUpdate: true,
        loading: false,
      }
    case UPDATE_SETTLEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        settlementUpdate: false,
        loading: false,
      }
    case DELETE_SETTLEMENT:
      return {
        ...state,
        settlement: action.payload,
        loading: true,
      }
    case DELETE_SETTLEMENT_SUCCESS:
      return {
        ...state,
        settlement: action.payload,
        settlementDelete: true,
        loading: false,
      }
    case DELETE_SETTLEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        settlementDelete: false,
        loading: false,
      }
    case FILE_UPLOAD:
      return {
        ...state,
        file: action.payload,
        loading: true,
      }
    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        file: action.payload,
        fileName: true,
        loading: false,
      }
    case FILE_UPLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        fileName: false,
        loading: false,
      }
    case SET_SAVE_SETTLEMENT:
      return {
        ...state,
        storedSettlement: action.payload,
      }
    case RETURN_STATE:
      return {
        error: {},
        loading: false,
        settlementCreate: undefined,
        settlementUpdate: undefined,
        settlementDelete: undefined,
        fileName: undefined,
        settlementExcelCreate: undefined,
      }

    default:
      return state
  }
}

export default settlement
