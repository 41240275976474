
export const productCodes = {

    //Front Office Solutions

    AmadeusAirPreferences: "AIRPREFERENCE",
    AllFares: "ALLFARES",
    DailySalesReport: "DSR",
    AmadeusOffers: "OFFERS",
    AmadeusSellingPlatformConnect: "SELCO",
    AmadeusSports365: "SPORTS365",
    PNRRecall: "RPP",
    ExtendedAirChoice: "EAC",
    LSM: "LSM",
    MarginManager: "MARGINMANAGER",
    MiniRules: "MINIRULES",

    //Air File
    IMR: "IMR",
    SecondaryAIR: "SECONDARYAIR",


    //AMADEUS TICKET CHANGER
    AtcRefund: "ATCREFUND",
    AtcReissue: "ATCREISSUE",

    //PRODUCTIVITY SUITE
    QualityMonitor: "QM",
    FileFinishing: "FF",
    SmartFlow: "SF",
    SmartTrigger: "ST",

    //Master Pricer
    MasterPricerExpert: "MPE",
    MasterPricerTravelBoard: "MPTB",


    //ECommerce
    AmadeusQuickConnect: "AQC",
    ePowerStarter: "EPOWER",
    mPower: "MPOWER",
    AmadeusWebServices: "WEBSERVICES",
    AmadeusMeetingFinder: "MEETINGFINDER",
    MobileTraveller : "AMT",
    AmadeusMobileMessenger: "AMM",
    AmadeusTravelAlertsNotifier: "ATAN",
    B2BWallet: "B2BWALLET",

    //Corporate
    AmadeusOnlineCorporateTraveller: "AOCT",

    //ITCS
    AgencyInsight: "AIPT",
    AmadeusDynamicTravelDocument: "ADTD",
    RTS: "RTS",
    AmadeusCreditLimitControl: "CLC",
    WebUETTR: "WEBUETTR",
    VisaPass: "VISAPASS",

    //Touchless
    AmadeusTouchlessHX: "AUTOHXCLEANER",
    FareOptimizer: "FAREOPT",


    //Non-Air
    AmadeusCarsPlus: "CARS",
    AmadeusGDSHotels: "HOTELS",
    AmadeusValueHotels: "AVH",
    AmadeusInsurance: "INSURANCE",
    AmadeusTransfers: "TRANSFERS",
    AmadeusTravelIntelligence: "TRAVELINTELLIGENCE",


    //Local DEV
    ADTDtoWhatsAPP: "ADTDTOWHATSAPP",
    Morningstar: "MORNINGSTAR",
    PayAllLink: "PAYALLLINK",
    MessageBar: "MESSAGEBAR",
    SecoDashboard: "SECODASHBOARD",



}
