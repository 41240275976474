import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  Col,
  Label,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  Card,
  CardBody,
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { v4 as uuidv4 } from "uuid"
import { useForm } from "react-hook-form"
import { connect } from "react-redux"
import Select from "react-select"
import {
  getCustomerConfigurations,
  getCustomerConfigurationDetailByCustomerId,
  setOtherCharges,
} from "store/actions"
import { dateFormatDDMMMYYYY } from "../../../helpers/commonhelpers/dateFormat"
import Charge from "./Charge"

const initialStateOtherCharges = {
  CustomerId: "",
  CustomerName: "",
  AgreementId: "",
  IsActive: true,
  ExternalReference: "",
  ChargeName: "ADSL",
  BillingOption: "Monthly",
  PostingDate: new Date().toISOString(),
  Amount: 0.0,
  Currency: "QAR",
  Remarks: "",
}

function Add(props) {
  const [otherChargeDetails, setOtherChargesDetail] = useState(
    initialStateOtherCharges
  )
  const [cusId, setCustId] = useState("")
  const [cusAgreementId, setCusAgreementId] = useState("")
  const [customerName, setCustomerName] = useState("")
  const [cusAgreementIds, setCusAgreementIds] = useState([])
  const [selectBillingOption, setSelectBillingOption] = useState()
  const { register, handleSubmit, errors } = useForm()
  const [cusAgreementError, setCustAgreementError] = useState(false)
  const [cusIdError, setCustIdError] = useState(false)
  const [chargeNameError, setChargeNameError] = useState(false)
  const [selectCus, setSelectCus] = useState({})
  // const [selectFiscalYears, setSelectFiscalYears] = useState([])
  // const [selectFiscalYear, setSelectFiscalYear] = useState("")
  // const [selectFiscalYearFrom, setSelectFiscalYearFrom] = useState("")
  // const [selectFiscalYearTo, setSelectFiscalYearTo] = useState("")
  const [selectChargeName, setSelectChargeName] = useState("")
  const [selectExternalReference, setSelectExternalReference] = useState("")
  const { onGetCustomerConfigurationsList, customerList, onSetOtherCharges } =
    props
  let optionsCustomerId = customerList.map((cus, i) => {
    return { value: cus.customerId, label: cus.customerId }
  })
  let optionsCustomerName = customerList.map((cus, i) => {
    return { value: cus.businessName, label: cus.businessName }
  })

  let optionsCustomerAgreements = cusAgreementIds?.map((ag, i) => {
    return { value: ag.agreementId, label: ag.agreementId }
  })

  let optionsBillingOptions = [
    { label: "Monthly", value: "Monthly" },
    { label: "Annually", value: "Annually" },
  ]

  let optionsChargeDetails = [
    { label: "ADSL", value: "ADSL" },
    { label: "HARDWARE", value: "HARDWARE" },
  ]

  // let optionsCustomerAgreementsFiscalYear = selectFiscalYears?.map((fy, i) => {
  //   return {
  //     value: fy.from + " to " + fy.to,
  //     label:
  //       dateFormatDDMMMYYYY(fy.from).props.children[0] +
  //       " to " +
  //       dateFormatDDMMMYYYY(fy.to).props.children[0],
  //   }
  // })
  ///////////////////////////////////////////////////////////////////////////
  useEffect(() => {}, [otherChargeDetails])

  useEffect(() => {
    onGetCustomerConfigurationsList()
  }, [])
  useEffect(() => {}, [customerList])
  useEffect(() => {}, [cusAgreementIds])
  useEffect(() => {
    // if (cusAgreementId) {
    //   cusAgreementIds.map((ag, i) => {
    //     if (ag.agreementId == cusAgreementId.value) {
    //       setSelectFiscalYears(ag.fiscalYearsDates)
    //     }
    //   })
    // }
  }, [cusAgreementId])
  useEffect(() => {
    if (cusId !== "") {
      hadnleGetCustomerDetails("id", cusId)
    }
  }, [cusId])

  useEffect(() => {
    if (customerName !== "") {
      hadnleGetCustomerDetails("name", customerName)
    }
  }, [customerName])
  useEffect(() => {}, [chargeNameError])
  useEffect(() => {}, [cusAgreementError])
  useEffect(() => {}, [cusIdError])
  useEffect(() => {}, [selectChargeName])
  useEffect(() => {}, [selectBillingOption])
  // useEffect(() => {
  //   if (selectFiscalYear.value) {
  //     var dates = selectFiscalYear.value.split(" to ")
  //     setSelectFiscalYearFrom(dates[0])
  //     setSelectFiscalYearTo(dates[1])
  //   }
  // }, [selectFiscalYear])
  // useEffect(() => {}, [selectFiscalYears])
  // useEffect(() => {
  //   //console.log(selectFiscalYearFrom, selectFiscalYearTo)
  //   if (selectFiscalYearFrom && selectFiscalYearTo) {
  //     setOtherChargesDetail(otherCharge => ({
  //       ...otherCharge,
  //       FiscalYear: {
  //         From: new Date(selectFiscalYearFrom).toISOString(),
  //         To: new Date(selectFiscalYearTo).toISOString(),
  //       },
  //     }))
  //   }
  // }, [selectFiscalYearFrom, selectFiscalYearTo])

  useEffect(() => {
    if (selectCus !== undefined) {
      setCusAgreementIds(selectCus.agreements)
      setCusAgreementId("")
      // setSelectFiscalYear("")
      setOtherChargesDetail(otherCharge => ({
        ...otherCharge,
      }))
    }
  }, [selectCus])

  useEffect(() => {
    if (otherChargeDetails.CustomerId !== "") {
      //hadnleGetCustomerDetails("id", otherChargeDetails.CustomerId)
    }
  }, [otherChargeDetails.CustomerId])

  useEffect(() => {
    if (otherChargeDetails.CustomerName !== "") {
      setCustIdError(false)
    }
  }, [otherChargeDetails.CustomerName])

  useEffect(() => {
    if (otherChargeDetails.AgreementId !== "") {
      setCustAgreementError(false)
    }
  }, [otherChargeDetails.AgreementId])
  //////////////////////////////////////////////////////////////
  const handleCreateSettlement = () => {
    //data.preventDefault()
    console.log(otherChargeDetails)
    if (otherChargeDetails.CustomerId === "") {
      setCustIdError(true)
    } else {
      setCustIdError(false)
    }
    if (otherChargeDetails.AgreementId === "") {
      setCustAgreementError(true)
    } else {
      setCustAgreementError(false)
    }
    if (otherChargeDetails.ChargeName === "") {
      setChargeNameError(true)
    } else {
      setChargeNameError(false)
    }
    if (
      otherChargeDetails.CustomerId !== "" &&
      otherChargeDetails.AgreementId !== ""
    ) {
      onSetOtherCharges(otherChargeDetails)
      props.onChange(false)
    }
  }
  const handleSaveOtherCharges = () => {
    //console.log(settlementDetail)

    props.onChange(false)
    //window.location.reload()
  }
  const storeDataOnForm = event => {
    const { name, value } = event.target
    setOtherChargesDetail(otherCharge => ({ ...otherCharge, [name]: value }))
  }

  const handlePostingDate = event => {
    //setSelectExternalReference(event.target.value)
    console.log(event)
    setOtherChargesDetail(otherCharge => ({
      ...otherCharge,
      PostingDate: event,
    }))
  }

  const customerIDEvent = event => {
    setCustId(event)
    setCustomerName("")
    setOtherChargesDetail(otherCharge => ({
      ...otherCharge,
      CustomerName: "",
      CustomerId: event.value,
    }))
  }

  const customerNameEvent = event => {
    setCustomerName(event)
    setCustId("")
    setOtherChargesDetail(otherCharge => ({
      ...otherCharge,
      CustomerId: "",
      CustomerName: event.value,
    }))
  }

  const customerAgreementEvent = event => {
    setCusAgreementId(event)
    setOtherChargesDetail(otherCharge => ({
      ...otherCharge,
      AgreementId: event.value,
    }))
  }
  const customerBillingOptionEvent = event => {
    setSelectBillingOption(event)
    setOtherChargesDetail(otherCharge => ({
      ...otherCharge,
      BillingOption: event.value,
    }))
  }
  const handleChargeNameEvent = event => {
    setSelectChargeName(event)
    setOtherChargesDetail(otherCharge => ({
      ...otherCharge,
      ChargeName: event.value,
    }))
  }
  // const customerAgreementFiscalYearEvent = event => {
  //   setSelectFiscalYear(event)
  // }

  const hadnleGetCustomerDetails = (type, data) => {
    if (type === "id" && customerName === "") {
      customerList.map((cus, i) => {
        if (data.value === cus.customerId) {
          setCustomerName({ label: cus.businessName, value: cus.businessName })
          setSelectCus(cus)
          setOtherChargesDetail(otherCharge => ({
            ...otherCharge,
            CustomerName: cus.businessName,
          }))
        }
      })
    }
    if (type === "name" && cusId === "") {
      customerList.map((cus, i) => {
        if (data.value === cus.businessName) {
          setCustId({ label: cus.customerId, value: cus.customerId })
          setSelectCus(cus)
          setOtherChargesDetail(otherCharge => ({
            ...otherCharge,
            CustomerId: cus.customerId,
          }))
        }
      })
    }
  }

  // function handleAddMonths() {
  //   const newMonth = {
  //     id: uuidv4(),
  //     Date: "",
  //     Amount: 0,
  //     Currency: "QAR",
  //   }
  //   handleChangeMonths({
  //     Months: [...otherChargeDetails.Charges, newMonth],
  //   })
  // }
  // function handleChangeMonths(changes) {
  //   handleMonthsChange(changes)
  // }
  // function handleMonthsChange(changes) {
  //   setOtherChargesDetail(otherCharges => ({
  //     ...otherCharges,
  //     Charges: changes.Months,
  //   }))
  // }
  // function handleChangeSingleMonth(id, month) {
  //   const newMonth = [...otherChargeDetails.Charges]
  //   const index = newMonth.findIndex(i => i.id === id)
  //   newMonth[index] = month
  //   handleChangeMonths({ Months: newMonth })
  // }

  // function handleDeleteMonth(id) {
  //   handleChangeMonths({
  //     Months: otherChargeDetails.Charges.filter(i => i.id !== id),
  //   })
  // }

  // function handleMonthAnnualChange(changes) {
  //   setOtherChargesDetail(otherCharges => ({
  //     ...otherCharges,
  //     AnnualCharge: changes.Months,
  //   }))
  // }
  // function handleChangeSingleMonthAnnual(id, month) {
  //   // const newMonth = otherChargeDetails.AnnualCharge
  //   // const index = newMonth.findIndex(i => i.id === id)
  //   // newMonth[index] = month
  //   handleMonthAnnualChange({ Months: month })
  // }

  // function handleDeleteMonthAnnual(id) {
  //   handleMonthAnnualChange({
  //     Months: otherChargeDetails.AnnualCharge.filter(i => i.id !== id),
  //   })
  // }
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Form>
            <Row className=" mx-3">
              <Col md={4}>
                <Label className=" input-text-size">Customer Id</Label>
                <Select
                  value={cusId}
                  id="cusId"
                  name="cusId"
                  // innerRef={register({ required: true })}
                  onChange={event => customerIDEvent(event)}
                  options={optionsCustomerId}
                  classNamePrefix="select2-selection"
                />
                {/* {errors.cusId && errors.cusId.type === "required" && (
                  <p className="color-danger">This is required</p>
                )} */}
                {cusIdError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
              <Col md={4}>
                <Label className=" input-text-size">Customer Name</Label>
                <Select
                  value={customerName}
                  id="customerName"
                  name="customerName"
                  // innerRef={register({ required: true })}
                  onChange={event => customerNameEvent(event)}
                  options={optionsCustomerName}
                  classNamePrefix="select2-selection"
                />
                {/* {errors.cusId && errors.cusId.type === "required" && (
                  <p className="color-danger">This is required</p>
                )} */}
                {cusIdError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
              <Col md={4}>
                <Label className=" input-text-size">Agreement Id</Label>
                <Select
                  id="cusAgreementId"
                  value={cusAgreementId}
                  name="cusAgreementId"
                  //innerRef={register({ required: true })}
                  onChange={event => customerAgreementEvent(event)}
                  options={optionsCustomerAgreements}
                  classNamePrefix="select2-selection"
                />
                {cusAgreementError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row className="form-group mx-3  my-2">
              <Col md={4}>
                <Label className=" input-text-size">Billing Option</Label>
                <Select
                  id="selectBillingOption"
                  value={selectBillingOption}
                  name="selectBillingOption"
                  defaultValue={{
                    label: "Monthly",
                    value: "Monthly",
                  }}
                  //innerRef={register({ required: true })}
                  onChange={event => customerBillingOptionEvent(event)}
                  options={optionsBillingOptions}
                  classNamePrefix="select2-selection"
                />
                {cusAgreementError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
              <Col md={4}>
                <Label className=" input-text-size">Charge Name</Label>
                <Select
                  id="selectChargeName"
                  value={selectChargeName}
                  name="selectChargeName"
                  defaultValue={{
                    label: "ADSL",
                    value: "ADSL",
                  }}
                  //innerRef={register({ required: true })}
                  onChange={event => handleChargeNameEvent(event)}
                  options={optionsChargeDetails}
                  classNamePrefix="select2-selection"
                />
                {/* {errors.cusId && errors.cusId.type === "required" && (
                  <p className="color-danger">This is required</p>
                )} */}
                {chargeNameError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
              <Col md={4}>
                <Label className=" input-text-size">External Reference</Label>
                <input
                  id="ExternalReference"
                  name="ExternalReference"
                  value={otherChargeDetails.ExternalReference}
                  type="text"
                  ref={register({ required: true })}
                  className="form-control "
                  onChange={event => storeDataOnForm(event)}
                />
                {/* {errors.cusId && errors.cusId.type === "required" && (
                  <p className="color-danger">This is required</p>
                )} */}
                {chargeNameError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row className="form-group mx-3  my-2">
              <Col md={4}>
                <Label className=" input-text-size">Date</Label>

                <Flatpickr
                  name={"Posting"}
                  className="form-control w-100 my-1  "
                  placeholder="Select Date Range"
                  //innerRef={register({ required: true })}
                  value={otherChargeDetails.PostingDate}
                  options={{
                    altFormat: "Y-m-d",
                    dateFormat: "F j, Y",
                  }}
                  onChange={e => handlePostingDate(new Date(e).toISOString())}
                />
                {cusAgreementError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
              <Col md={4}>
                <Label className=" input-text-size">Amount</Label>
                <input
                  id="Amount"
                  name="Amount"
                  value={otherChargeDetails.Amount}
                  type="number"
                  min="1"
                  step=".01"
                  ref={register({ required: true })}
                  className="form-control "
                  onChange={event => storeDataOnForm(event)}
                />
                {/* {errors.cusId && errors.cusId.type === "required" && (
                  <p className="color-danger">This is required</p>
                )} */}
                {chargeNameError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
              <Col md={4}>
                <Label className=" input-text-size">Currency</Label>
                <input
                  id="Currency"
                  name="Currency"
                  value={otherChargeDetails.Currency}
                  type="text"
                  ref={register({ required: true })}
                  className="form-control "
                  onChange={event => storeDataOnForm(event)}
                />

                {chargeNameError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row className="form-group mx-3  my-2">
              <Col md={8}>
                <Label className=" input-text-size">Remarks</Label>
                <input
                  id="Remarks"
                  name="Remarks"
                  value={otherChargeDetails.Remarks}
                  type="text"
                  ref={register({ required: true })}
                  className="form-control "
                  onChange={event => storeDataOnForm(event)}
                />
                {/* {errors.cusId && errors.cusId.type === "required" && (
                  <p className="color-danger">This is required</p>
                )} */}
                {chargeNameError ? (
                  <div>
                    <p className="color-danger">This is required</p>
                  </div>
                ) : null}
              </Col>
            </Row>
            {/* <p
              style={{ color: "#5c8FEC" }}
              className="item-center input-text-size"
            >
              <small>
                <b>
                  {selectFiscalYear ? (
                    <div>
                      {dateFormatDDMMMYYYY(selectFiscalYearFrom)} to{" "}
                      {dateFormatDDMMMYYYY(selectFiscalYearTo)}
                    </div>
                  ) : (
                    ""
                  )}
                </b>
              </small>
            </p> */}

            {/* <Row
              style={{
                color: "white",
                background: "#5c8FEC",
                padding: "3px",
                marginLeft: "3px",
                marginRight: "3px",
              }}
            >
              <Col lg="5">
                <small>Date</small>
              </Col>
              <Col lg="2">
                <small>Amount</small>
              </Col>
              <Col lg="3">
                <small>Currency</small>
              </Col>
              <Col lg="2"></Col>
            </Row>
            <br></br> */}

            {/* {otherChargeDetails.Charges.map((item, index) => {
              return (
                <Charge
                  key={index}
                  index={index}
                  item={item}
                  handleChangeSingleMonths={handleChangeSingleMonth}
                  handleDeleteMonth={handleDeleteMonth}
                  handleSubmit={handleSubmit}
                  //onSubmit={onSubmit}
                  register={register}
                  errors={errors}
                />
              )
            })}
            <Button
              onClick={() => handleAddMonths()}
              color="success"
              className="mt-1"
            >
              Add Charges
            </Button> */}

            <Row>
              <Col md={12} className="item-center mb-3">
                <Button
                  color="success"
                  //type="submit"
                  className=" waves-effect waves-light mt-3 btn-lg"
                  onClick={() => handleCreateSettlement()}
                >
                  <i className="fas fa-plus mr-1" />
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </React.Fragment>
  )
}
const mapStateToProps = state => {
  return {
    customerList: state.customerconfigurations.customerConfigurations,
    customerConfigurationDetailByCustomerId:
      state.customerconfigurations.customerConfigurationDetailByCustomerId,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetCustomerConfigurationsList: () => dispatch(getCustomerConfigurations()),
  onGetCustomerConfigurationDetailByCustomerID: id =>
    dispatch(getCustomerConfigurationDetailByCustomerId(id)),
  onSetOtherCharges: data => dispatch(setOtherCharges(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Add)
