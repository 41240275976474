import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import { isEmpty } from "lodash"

const multi = {
  filtered: [],
  select2: undefined,
}

export const Content = props => {
  const {
    agreementIndex,
    index,
    content,
    airlinesOptions,
    handleContentChange,
    handleContentDelete,
    handleSubmit,
    onSubmit,
    register,
    errors,
  } = props

  //TODO: AIRLINE OPTIOS BUGGY
  const inputContentType = "ContentType" + index + agreementIndex
  const inputIsExcluded = "IsExcluded" + index + agreementIndex
  const inputAirlinesList = "AirlinesList" + index + agreementIndex

  const [selectedMulti, setselectedMulti] = useState(null)

  useEffect(() => {
    var values = selectedMulti?.map(x => x.value)
    handleChange({ Airlines: values })
  }, [selectedMulti])

  function handleChange(changes) {
    handleContentChange(content.id, { ...content, ...changes })
  }

  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti)
  }

  return (
    <React.Fragment>
      <Card style={{ backgroundColor: "#ebf3fa" }}>
        <CardBody>
          <Row>
            <Col>
              <div className="d-flex justify-content-end">
                <Link to="#" onClick={() => handleContentDelete(content.id)}>
                  <i
                    className="fas fa-window-close fa-2x"
                    style={{ color: "red" }}
                  />
                </Link>
              </div>
            </Col>
          </Row>

          {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
          <div>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label>Type of Content</Label>
                  <select
                    className="custom-select"
                    name={inputContentType}
                    value={content.ContentType}
                    onChange={e =>
                      handleChange({ ContentType: e.target.value })
                    }
                    ref={register({
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    })}
                  >
                    <option value="" hidden="hidden">
                      Choose here
                    </option>
                    <option value="Unpaid Airlines">Unpaid Airlines</option>
                  </select>

                  <small className="color-danger">
                    {errors[inputContentType] &&
                      errors[inputContentType].message}
                  </small>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label>Exclude from calculation?</Label>
                  <select
                    className="custom-select"
                    name={inputIsExcluded}
                    value={content.IsExcluded}
                    onChange={e =>
                      handleChange({
                        IsExcluded: e.target.value === "true" ? true : false,
                      })
                    }
                    ref={register({
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    })}
                  >
                    <option value="true">
                      Yes, A reduction from incentive calculation
                    </option>
                    {/* <option value="false">
                      No, Include in the calculation
                    </option> */}
                  </select>

                  <small className="color-danger">
                    {errors[inputIsExcluded] && errors[inputIsExcluded].message}
                  </small>
                </FormGroup>
              </Col>
            </Row>
            {content.ContentType === "Unpaid Airlines" && (
              <>
                <Row>
                  <Col>
                    <p>
                      <b>Selected Unpaid Airlines : </b>
                      {content.Airlines?.join(" ")}
                    </p>

                    <input
                      hidden
                      type="text"
                      value={content.Airlines}
                      name={inputAirlinesList}
                      className="form-control"
                      id="airlines"
                      readOnly
                      ref={register({
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="mb-3">
                      <label className="control-label">
                        Select Unpaid Airlines
                      </label>
                      <Select
                        value={selectedMulti}
                        name="SelectionOfAirlines"
                        isMulti={true}
                        onChange={e => {
                          handleMulti(e)
                        }}
                        // ref={register({
                        //   required: {
                        //     message: "This is required",
                        //   },
                        // })}
                        options={airlinesOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                    <small className="color-danger">
                      {errors[inputAirlinesList] &&
                        errors[inputAirlinesList].message}
                    </small>
                  </Col>
                </Row>
              </>
            )}
            <Row></Row>
            </div>
          {/* </Form> */}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Content)
